


import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { Category, CompanyProductsMapping, CustomResponse, MasterCode, Product, Item, PurchaseItem, PurchaseOrder, Vendor, Warehouse, Asset } from '../../../types';
import Sidebar from '../../../components/Sidebar';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import CustomPanel from '../../../components/CustomPanel';
import { DeleteCall, GetCall, GetPdfCall, PostCall, PutCall } from '../../../api/ApiKit';
import { filter, find, get, map, set, sumBy } from 'lodash';
import { constant } from '../../../utils/constant';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import moment, { isMoment } from 'moment-timezone';
import { InputTextarea } from 'primereact/inputtextarea';
import { Card } from 'primereact/card';
import { TreeNode } from 'primereact/treenode';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { buildQueryParams, formatBytes, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-blue/theme.css'; // Or your preferred theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Menu } from 'primereact/menu';
import MemoizedFileItem from '../../../components/MemoizedFileItem';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import FileView from '../../../components/FileView';
import UploadFile from '../../../components/UploadFile';
import { IconField } from 'primereact/iconfield';
import DiscrepancyView from '../../../components/Views/DiscrepancyView';
import { useSelector } from 'react-redux';
import { ReduxStoreState } from '../../../redux/redux-store';
import AppPage from '../../../layout/AppPage';
import PurchaseOrderView from '../../../components/Views/PurchaseOrderView';
import PurchaseOrderEditor from '../../../components/Editors/PurchaseOrderEditor';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete'
};

const defaultLineItem: PurchaseItem = {
    poId: null,
    poItemId: null,
    companyId: null,
    categoryId: null,
    productId: null,
    gradeId: null,
    isCrossDock: false,
    quantity: 0,
    price: null,
    rowId: `${Date.now()}_${Math.random()}`,
    skuId: null,
    internalGrade: null,
    batteryHealthIds: null,
};
const defaultPurchaseItems: PurchaseOrder = {
    poId: null,
    poNumber: '',
    companyId: null,
    locationId: null,
    vendorId: null,
    categoryIds: null,
    poDate: null,
    approxDeliveryDate: null,
    trackingTypeId: null,
    trackingId: null,
    statusId: null,
    note: null,
    quantity: 0,
    totalPrice: 0,
    paid: 0,
    items: []
}

const PurchaseOrderPage = () => {
    const { companyId } = useParams();
    const initializedRef = useRef(false);
    const createMenuRef = useRef<Menu>(null);
    const [searchParams] = useSearchParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const containerRef = useRef(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [action, setAction] = useState<any>(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [pos, setPOs] = useState<PurchaseOrder[]>([]);
    const [selectedPO, setSelectedPO] = useState<PurchaseOrder | null>(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState<number | null>(null);
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({ ...defaultPurchaseItems });
    const [allVendors, setAllVendors] = useState<Vendor[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
    const [visible, setVisible] = useState(false);
    const [discripancyItem, setDescripancy] = useState<any>(null);
    const toast = useRef<Toast>(null);
    const fileUploadRef = useRef<any>(null);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const [selectedPOToDelete, setSelectedPOToDelete] = useState<PurchaseOrder | null>(null);
    const [dialogvisible, setDialogVisible] = useState(false);
    const [newItem, setNewItem] = useState('');
    const [poId, setPoId] = useState<string | null>(null); // Step 1: Add state for poId
    const [trackingData, setTrackingData] = useState<Item[]>([]);
    const [assetFile, setAssetFile] = useState<Asset | null>(null);
    const [isShowImage, setShowImage] = useState<boolean>(false);
    const [selectedTrackingId, setselectedTrackingId] = useState(null);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const queryPoId = searchParams.get('poId');
    const statuses = useSelector((state: ReduxStoreState) => state.poStatus.poStatus);
    const locations = useSelector((state: ReduxStoreState) => state.locations.locations);
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const check = async () => {
            if (!initializedRef.current && queryPoId) {
                initializedRef.current = true;
                let samplePO = { ...defaultPurchaseItems };
                samplePO.poId = +queryPoId;
                setSelectedPO(samplePO);
                setAction(ACTIONS.VIEW);
                handleClick(samplePO);
            }
        };
        check();
    }, [queryPoId]);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        params.include = 'location,vendor,items';
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/purchase-orders-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setPOs(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
            if (response.data.length > 0) {
                setPoId(response.data[0].poId);
            }
        } else {
            setPOs([]);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchAllVendors = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors?limit=500`);
        if (response.code == 'SUCCESS') {
            setAllVendors(response.data);
        } else {
            setAllVendors([]);
        }
        setLoading(false);
    };

    // Function to fetch tracking data based on poId
    const fetchTrackingData = async (poId: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        try {
            const response = await GetCall(`/company/${companyId}/purchase-orders/${poId}/trackings`);

            if (response.code === 'SUCCESS' && Array.isArray(response.data)) {
                setTrackingData(response.data); // Update tracking data state
            } else {
                setAlert('error', response.message);
            }
        } catch (error) {
            setAlert('error', 'Failed to fetch tracking data.');
        } finally {
            setLoading(false);
        }
    };

    const confirmDelete = async () => {
        if (!selectedPOToDelete) return;
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/purchase-orders/${selectedPOToDelete.poId}`);
        setLoading(false);
        if (response.code === 'SUCCESS') {
            setIsDeleteDialogVisible(false);
            fetchData();
            setAlert('success', 'Successfully Deleted');
        } else {
            setAlert('error', response.message);
        }
    };
    const deleteTrackingData = async (poId: string, poTrackId: number): Promise<void> => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);

        try {
            const response = await DeleteCall(`/company/${companyId}/purchase-orders/${poId}/trackings/${poTrackId}`);

            if (response.code === 'SUCCESS') {
                // Filter out the deleted tracking item from the trackingData array
                setTrackingData((prevData: Item[]) => prevData.filter((item) => item.poTrackId !== poTrackId));
                setAlert('success', 'Tracking entry deleted successfully.');
            } else {
                setAlert('error', response.message);
            }
        } catch (error) {
            setAlert('error', 'Failed to delete tracking entry.');
        } finally {
            setLoading(false);
        }
    };

    const postTrackingData = async (poId: string, newTrackingData: { trackingNumber: string }): Promise<boolean> => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);

        try {
            if (!newTrackingData.trackingNumber) {
                setAlert('error', 'Tracking number is required.');
                return false;
            }

            // Wrap the single payload in an array as required by the API
            const payload = [{ trackingNumber: newTrackingData.trackingNumber }];

            const response = await PostCall(`/company/${companyId}/purchase-orders/${poId}/trackings`, payload);

            if (response && response.code === 'SUCCESS') {
                // Call fetchTrackingData to refresh the tracking data state
                await fetchTrackingData(poId);
                setAlert('success', 'Tracking entry added successfully.');
                return true; // Indicate success
            } else {
                setAlert('error', 'Duplicate record found');
                return false; // Indicate failure
            }
        } catch (error) {
            setAlert('error', 'Failed to add tracking entry.');
            return false; // Indicate failure
        } finally {
            setLoading(false);
        }
    };

    const closeDeleteDialog = () => {
        setIsDeleteDialogVisible(false);
        setSelectedPOToDelete(null);
    };

    const closeIcon = () => {
        setAction(null);
        setIsShowSplit(false);
        setSelectedPO(null);
        handleClick();
        setPurchaseOrder({ ...defaultPurchaseItems })
        setselectedTrackingId(null)
    };

    const openDeleteDialog = (perm: PurchaseOrder) => {
        setSelectedPOToDelete(perm);
        setIsDeleteDialogVisible(true);
    };

    const onRowSelect = async (perm: PurchaseOrder, action = ACTIONS.VIEW) => {
        setAction(action);
        // setIsShowSplit(true);
        await setSelectedPO(perm);
        if (action === ACTIONS.DELETE) {
            openDeleteDialog(perm);
        }

        if (action === ACTIONS.VIEW) {
            setPurchaseOrder(perm);
            setIsShowSplit(true);
            handleClick(perm)
        }

        if (action === ACTIONS.EDIT) {
            setIsShowSplit(true);
        }
    };

    const showAddNew = () => {
        setIsShowSplit(true);
        setSubmitted(false);
        setAction(ACTIONS.ADD);
        setSelectedPO(null);
    };

    const renderPOTotal = (option: PurchaseOrder) => {
        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $4,000.00)
        }).format(option.totalPrice);

        return <>{formattedPrice}</>;
    };


    const renderPOBalace = (option: PurchaseOrder) => {
        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $4,000.00)
        }).format(option.totalPrice - option.paid);
        return <>{formattedPrice}</>;
    };

    const closeDialog = () => {
        setVisible(false);
    };

    const setDescripanycItem = (item: PurchaseOrder) => {
        console.log('item', item)
        setDescripancy(item);
    };

    const onTemplateUpload = (event: any) => {
        if (toast.current) {
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Files uploaded successfully!' });
        }
    };

    const onTemplateSelect = (event: any) => { };

    const onTemplateClear = () => {
        if (toast.current) {
            toast.current?.show({ severity: 'info', summary: 'Info', detail: 'Upload cleared' });
        }
    };

    const addDialogItem = async () => {
        if (!newItem.trim()) {
            setAlert('error', 'Please enter a valid tracking number.');
            return;
        }

        if (!poId) {
            setAlert('error', 'Invalid purchase order ID.');
            return;
        }

        const newTrackingData = { trackingNumber: newItem.trim() };

        // Await postTrackingData and check the returned boolean
        const success = await postTrackingData(poId, newTrackingData);

        // Only clear input if the tracking data was successfully added
        if (success) {
            setNewItem(''); // Clear the input only after successful addition
        }
    };

    const DeletePOTrack = (poTrackId: number) => {
        if (poId) {
            // Check if poId is not null
            deleteTrackingData(poId, poTrackId); // Call delete function with valid poId and poTrackId
        }
    };

    const printDoc = async (poId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const attacheDocs = async () => {
        setVisible(true)
    }

    const uploadDocs = async (assets: any) => {
        let poId = purchaseOrder.poId;
        if (!poId && selectedPO) {
            poId = selectedPO.poId;
        }
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/docs`, assets);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Upload Successfully');
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const handleClick = (_purchaseOrder?: PurchaseOrder) => {
        if (_purchaseOrder) {
            const newUrl = `/purchase-orders?poId=${_purchaseOrder.poId}`;
            navigate(newUrl, { replace: true });
            setIsShowSplit(true);
        }
        else {
            navigate('/purchase-orders', { replace: true });
        }
    };

    // Function to handle dropdown change
    const handleStatusChange = (masterCodeId: React.SetStateAction<number | null>, rowData: any) => {
        setSelectedStatus(masterCodeId);

        // Clear any existing timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout to call the API after 2 seconds
        const id = setTimeout(() => {
            callStatusUpdateAPI(masterCodeId, rowData);
        }, 2000);

        setTimeoutId(id);
    };

    // Function to call the API with the masterCodeId and row data
    const callStatusUpdateAPI = async (masterCodeId: React.SetStateAction<number | null>, rowData: { poId: any; }) => {
        setLoading(true);
        const response: CustomResponse = await PutCall(`/company/${user?.company?.companyId}/purchase-orders/${rowData.poId}/status/${masterCodeId}`);
        if (response.code == 'SUCCESS') {
            fetchData();
            setAlert('success', 'Status Updated');
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const dialogPopup = (
        <Dialog header={<span>{selectedTrackingId} </span>} style={{ width: '30vw' }} visible={dialogvisible} onHide={() => setDialogVisible(false)}>
            <div className="p-3">
                <div className="p-inputgroup mb-3">
                    <span className="p-input-icon-left" style={{ width: '100%' }}>
                        <i className="pi pi-search" />
                        <InputText placeholder="Search" className="" style={{ width: '100%' }} />
                    </span>
                </div>

                <div className="p-inputgroup mb-3">
                    <InputText value={newItem} onChange={(e) => setNewItem(e.target.value)} placeholder="Enter tracking number" />
                    <Button icon="pi pi-plus" onClick={addDialogItem} />
                </div>

                {isLoading ? (
                    <div className="p-d-flex p-ai-center p-jc-center" style={{ height: '100%' }}>
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
                        <span className="ml-2">Loading tracking items...</span>
                    </div>
                ) : trackingData.length > 0 ? (
                    trackingData.map((item: Item, index: number) => (
                        <div key={item.poTrackId || index} className="p-inputgroup mb-2">
                            <InputText value={item.trackingNumber} readOnly style={{ width: '100%' }} />
                            <Button icon="pi pi-trash" className="p-button-danger" onClick={() => DeletePOTrack(item.poTrackId)} />
                        </div>
                    ))
                ) : (
                    <p>No tracking items added.</p>
                )}
            </div>
        </Dialog>
    );

    const popupmodal = (
        <Dialog header="Upload Files" style={{ width: '600px' }} onHide={closeDialog}>
            <Toast ref={toast} />

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip>
                <Button
                    label="Upload"
                    icon="pi pi-upload"
                    className="custom-upload-btn"
                    onClick={() => {
                        fileUploadRef.current.upload();
                        closeDialog();
                    }}
                />
            </Tooltip>
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

            <FileUpload
                ref={fileUploadRef}
                name="demo[]"
                url="/api/upload"
                mode="advanced"
                multiple
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                // emptyTemplate={emptyTemplate}
                chooseOptions={{ icon: 'pi pi-fw pi-plus', label: 'Choose' }}
                uploadOptions={{ icon: 'pi pi-fw pi-check', label: 'Upload' }}
                cancelOptions={{ icon: 'pi pi-fw pi-times', label: 'Clear' }}
            />
        </Dialog>
    );

    const renderVendor = (item: any) => get(item, 'vendor.name');
    const renderWarehouse = (item: any) => get(item, 'location.name');
    const renderStatus = (rowData: { statusId: any; }) => {
        return (
            <Dropdown
                value={rowData.statusId || null}
                options={statuses}
                optionLabel="code"
                optionValue="masterCodeId"
                onChange={(e) => handleStatusChange(e.value, rowData)}
                className="dropdown-small w-full" checkmark={true}
            />
        );
    };

    const vendorDropdown = (options: any) => (
        <Dropdown
            filter
            value={options.value || null}
            options={allVendors}
            optionLabel="name"
            optionValue="vendorId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select vendor"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );
    const locationDropdown = (options: any) => (
        <Dropdown
            filter
            value={options.value || null}
            options={locations}
            optionLabel="name"
            optionValue="locationId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select location"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );
    const statusDropdown = (options: any) => (
        <Dropdown
            filter
            value={options.value || null}
            options={statuses}
            optionLabel="code"
            optionValue="masterCodeId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select status"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Purchase Orders'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                // isView={true}
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                isView={true}
                                headerButtons={[
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                extraButtons={[
                                    {
                                        icon: 'pi pi-cloud-upload',
                                        onClick: (item) => {
                                            setSelectedPO(item)
                                            setVisible(true)
                                        }
                                    },
                                    {
                                        icon: 'pi pi-file-pdf',
                                        onClick: (item) => {
                                            printDoc(item.poId)
                                        }
                                    },
                                    {
                                        icon: 'pi pi-truck',
                                        onClick: async (item) => {
                                            setDialogVisible(true);
                                            setPoId(item.poId); // Set the poId from the clicked item
                                            setselectedTrackingId(item.poNumber)
                                            await fetchTrackingData(item.poId);
                                        }
                                    }
                                ]}
                                data={pos}
                                columns={[
                                    {
                                        header: 'PO Number',
                                        field: 'poNumber',
                                        filter: true,
                                        sortable: true,
                                        body: (item) => <div><Tooltip target={`.${item.poNumber}`} /><span onClick={() => onRowSelect(item, 'view')} className='text-blue cursor-pointer'>{item.poNumber}</span>{item.isDiscrepancy ? <IconField className={`pi pi-exclamation-triangle text-red ml-2 cursor-pointer ${item.poNumber}`} data-pr-tooltip="Discrepancy found" data-pr-position="right" onClick={() => setDescripanycItem(item)} /> : <></>}</div>,
                                        bodyStyle: { minWidth: 150, maxWidth: 150 },
                                        filterPlaceholder: 'PO Number'
                                    },
                                    {
                                        header: 'Vendor',
                                        field: 'customer',
                                        filter: true,
                                        filterPlaceholder: 'Search vendor'
                                    },
                                    {
                                        header: 'PO Date',
                                        field: 'poDate',
                                        sortable: true,
                                        style: { minWidth: 120, maxWidth: 120 }
                                    },
                                    {
                                        header: 'Location',
                                        field: 'locationId',
                                        body: (item) => <div>{get(item, 'location', 'N/A')}</div>,
                                        filter: true,
                                        filterElement: locationDropdown,
                                        filterPlaceholder: 'Search location'
                                    },
                                    {
                                        header: 'Status',
                                        field: 'statusId',
                                        body: renderStatus,
                                        filter: true,
                                        filterElement: statusDropdown,
                                    },
                                    {
                                        header: 'Total',
                                        field: 'total',
                                        body: renderPOTotal
                                    },
                                    {
                                        header: 'Balance',
                                        field: 'balancePrice'
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onView={(item: any) => onRowSelect(item, 'view')}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onDelete={(item: any) => onRowSelect(item, 'delete')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {popupmodal}
            {dialogPopup}
            {/* Purchase Order View */}
            {
                action === ACTIONS.VIEW && selectedPO?.poId && <PurchaseOrderView
                    isVisible={action === ACTIONS.VIEW && selectedPO != null}
                    poId={selectedPO?.poId}
                    po={selectedPO}
                    onClose={(isEdit) => {
                        if (isEdit && selectedPO) {
                            onRowSelect(selectedPO, ACTIONS.EDIT)
                        }
                        else {
                            closeIcon();
                        }
                    }}
                />
            }

            {/* Purchase Order Editor */}
            {
                [ACTIONS.EDIT, ACTIONS.ADD].includes(action) && <PurchaseOrderEditor
                    isVisible={true}
                    poId={selectedPO?.poId}
                    isEdit={selectedPO?.poId ? true : false}
                    onClose={(isLoad) => {
                        closeIcon();
                        if (isLoad) {
                            fetchData();
                        }
                    }}
                />
            }

            <UploadFile
                isVisible={visible}
                onSelect={(option: any) => {
                    setVisible(false);
                    if (option && option.length > 0) {
                        let assets = option.map((item: any) => ({
                            assetId: item.assetId,
                            comment: item.comment || ''
                        }))
                        uploadDocs(assets);
                    }
                }}
            />
            <FileView
                isVisible={isShowImage}
                assetFile={assetFile}
                onClose={() => setShowImage(false)}
            />

            <Dialog
                header="Delete confirmation"
                visible={isDeleteDialogVisible}
                style={{ width: layoutState.isMobile ? '90vw' : '50vw' }}
                className="delete-dialog"
                headerStyle={{ backgroundColor: '#ffdddb', color: '#8c1d18' }}
                footer={
                    <div className="flex justify-content-end p-2">
                        <Button label="Cancel" severity="secondary" text onClick={closeDeleteDialog} />
                        <Button label="Delete" severity="danger" onClick={confirmDelete} />
                    </div>
                }
                onHide={closeDeleteDialog}
            >
                {isLoading && (
                    <div className="center-pos">
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                    </div>
                )}
                <div className="flex flex-column w-full surface-border p-3">
                    <div className="flex align-items-center">
                        <i className="pi pi-info-circle text-6xl red" style={{ marginRight: 10 }}></i>
                        <span>
                            This will permanently delete the selected purchase order.
                            <br />
                            Do you still want to delete it? This action cannot be undone.
                        </span>
                    </div>
                </div>
            </Dialog>

            {
                discripancyItem && <DiscrepancyView
                    isVisible={discripancyItem != null}
                    id={discripancyItem.poId}
                    type={'PO'}
                    title={discripancyItem.poNumber}
                    onClose={(isLoad: boolean) => {
                        setDescripancy(null)
                    }}
                />
            }
        </>
    );
};
export default PurchaseOrderPage;
