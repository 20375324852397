import { MultiSelect } from "primereact/multiselect";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { CustomResponse, Parts } from "../types";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { buildQueryParams } from "../utils/uitl";
import { get } from "lodash";
import { GetCall } from "../api/ApiKit";
import { useAppContext } from "../layout/AppWrapper";
import { Dropdown } from "primereact/dropdown";

interface OptionProps {
    url: string,
    multiple?: boolean,
    filters?: any,
    searchName?: string,
    placeholder?: string,
    include?: string,
    selectedItems: any;
    autoClear?: boolean,
    onSelect: (value: any) => void;
    setSelectedItems: (value: any) => void;
}

const ERPMultiSelect = memo(({ url, searchName = 'name', include, selectedItems, setSelectedItems, filters = {}, ...props }: OptionProps) => {
    const { user } = useAppContext();
    const [isLoading, setLoading] = useState<any>('');
    const [soSearch, setSOSearch] = useState<any>('');
    const [data, setData] = useState<any[]>([]);
    const multiSelectRef = useRef<MultiSelect>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleFilter(soSearch);
        }, 100);

        return () => clearTimeout(timer);
    }, [soSearch]);

    const fetchData = async (search = '') => {
        let params: any = {
            filters: filters ? filters : {},
            limit: 500,
            page: 0,
            include: include
        };

        if (search) {
            params.filters[searchName] = search.toUpperCase();
        }
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`${url}?${queryString}`);
        if (response.code == 'SUCCESS') {
            setData(response.data);
        } else {
            setData([]);
        }
        setLoading(false);
    }


    const handleFilter = (soSearch: any) => {
        const searchTerm = soSearch.toLowerCase();
        const localMatches = data.filter((option: Parts) => option?.name && option?.name.toLowerCase().includes(searchTerm.toLowerCase()));
        if (localMatches.length > 0 && soSearch.length !== 0) {
            setData(localMatches); // Use local matches if found
        } else {
            fetchData(searchTerm); // Fallback to server if no local match
        }
    };

    const closeMultiSelect = (isOk?: boolean) => {
        if (typeof isOk == 'boolean' && isOk) {
            if (props.onSelect) {
                props.onSelect(selectedItems)
            }
            setSelectedItems([]);
        }
        else {
            setSelectedItems([])
        }
        if (multiSelectRef.current) {
            multiSelectRef.current.hide();
        }
    };

    const multiSelectFooter = () => {
        return (
            <div className="flex justify-content-end p-2 footer-panel">
                <Button label="Cancel" severity="secondary" text onClick={closeMultiSelect.bind(this, false)} />
                <Button label="Ok" text onClick={closeMultiSelect.bind(this, true)} />
            </div>
        );
    }

    const customContent = (
        <span className="p-input-icon-right w-full">
            <IconField>
                <InputIcon className={`pi ${isLoading ? 'pi-spin pi-spinner' : 'pi-search'}`}> </InputIcon>
                <InputText value={soSearch}
                    onChange={(e) => setSOSearch(e.target.value)}
                    placeholder={`Search ${searchName.toLowerCase()}`}
                    style={{ width: "100%" }}
                    autoFocus />
            </IconField>
        </span>
    );

    const handleChange = useCallback((e: any) => {
        setSelectedItems(e.value);
        if (!props.multiple && props.onSelect) {
            props.onSelect(e.value);
            if (props.autoClear) {
                setSelectedItems([])
            }
        }
    }, [setSelectedItems]);

    if (props.multiple) {
        return (
            <MultiSelect
                ref={multiSelectRef}
                value={selectedItems}
                options={data}
                filter
                filterBy={searchName}
                optionLabel={searchName}
                className="w-full"
                placeholder={props.placeholder || 'Select'}
                virtualScrollerOptions={{ itemSize: 50 }}
                style={{ maxWidth: 300 }}
                onChange={handleChange}
                maxSelectedLabels={1}
                filterTemplate={customContent}
                panelFooterTemplate={multiSelectFooter}
            />
        )
    }
    return <Dropdown
        value={selectedItems}
        options={data}
        filter
        clearIcon
        filterBy={searchName}
        optionLabel={searchName}
        className="w-full"
        placeholder={props.placeholder || 'Select'}
        virtualScrollerOptions={{ itemSize: 50 }}
        style={{ maxWidth: 300 }}
        onChange={handleChange}
    />
})

// Memoize to avoid unnecessary re-renders
export default ERPMultiSelect;
