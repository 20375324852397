import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../layout/AppWrapper";
import MemoizedFileItem from "../MemoizedFileItem";
import { Asset, CustomResponse, Packages, PurchaseOrder, SalesOrder, SalesOrderItem, SalesReturns } from "../../types";
import { Panel } from "primereact/panel";
import { Menu } from "primereact/menu";
import { DeleteCall, GetCall, GetPdfCall, PostCall } from "../../api/ApiKit";
import UploadFile from "../UploadFile";
import { find, get, map, sumBy } from "lodash";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Badge } from "primereact/badge";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { Button } from "primereact/button";
import moment, { isMoment } from "moment";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { buildQueryParams, formatBytes, formatWithWordsNumber } from "../../utils/uitl";
import { useNavigate } from "react-router-dom";
import { ACTIONS } from "../../utils/constant";
import Sidebar from "../Sidebar";
import FileView from "../FileView";
import PackageEditor from "../Editors/PackageEditor";
import BackOrderEditor from "../Editors/BackOrderEditor";
import SalesReturnsEditor from "../Editors/SalesReturnEditor";

interface ViewOptions {
    soId: any,
    isVisible: boolean,
    onClose: (isLoad: boolean) => void
}

export default function SalesOrderView({ soId = null, isVisible = false, onClose = (isLoad) => { } }: ViewOptions) {
    const navigate = useNavigate();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const createMenuRef = useRef<Menu>(null);
    const [uploadVisible, setUploadVisible] = useState<boolean>(false);
    const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(null);
    const [docs, setDocs] = useState<any[]>([]);
    const [isShowImage, setShowImage] = useState<boolean>(false);
    const [assetFile, setAssetFile] = useState<Asset | null>(null);
    const [isPackage, setShowPackage] = useState<boolean>(false)
    const [isBackOrder, setIsBackOrder] = useState<boolean>(false);
    const [isBackOrderAlert, setBackOrderAlert] = useState<boolean>(false);
    const panelRef = useRef<Panel>(null);
    const [packages, setPackages] = useState<Packages[]>([]);
    const [backOrders, setBackOrders] = useState<PurchaseOrder[]>([]);
    const [saleReturns, setSalesReturns] = useState<SalesReturns[]>([]);
    const [isSalesReturn, setIsSalesReturn] = useState<boolean>(false);
    const [createMenuItems, setMenuItems] = useState<any[]>([
        {
            label: 'Create Package',
            command: () => setShowPackage(true)
        }
    ]);
    const [displayTabIndex, setDisplayTabIndex] = useState<any>(0)

    useEffect(() => {
        if (soId) {
            setDialogVisible(true);
            fetchSoDetails(soId)
        }
        else {
            setDialogVisible(false)
        }
    }, [soId]);

    useEffect(() => {
        if (isVisible && soId) {
            setDialogVisible(true);
            fetchSoDetails(soId)
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);


    // on sales order detail updated
    useEffect(() => {
        if (salesOrder && salesOrder.items && salesOrder.items.length > 0) {
            const isAnyItemStockInsufficient = salesOrder.items.some((item: any) => item.stockAvail + Math.max(item.backOrdered, 0) - (item.quantity - item.packed) < 0);
            if (isAnyItemStockInsufficient) {
                setBackOrderAlert(true);
                return;
            }
        }
        setBackOrderAlert(false);

        // check for sales return
        if (salesOrder?.statusId) {
            const packedCounts = sumBy(salesOrder.items, 'packed');
            if (packedCounts > 0) {
                const findSReturn = find(createMenuItems, { label: 'Sales Return' });
                if (!findSReturn) {
                    let _old = [...createMenuItems];
                    _old.push({
                        label: 'Sales Return',
                        command: () => setIsSalesReturn(true)
                    })
                    setMenuItems(_old)
                }
            }
            else {
                setMenuItems([
                    {
                        label: 'Create Package',
                        command: () => setShowPackage(true)
                    }
                ])
            }
        }
    }, [salesOrder]);

    const fetchSoDetails = async (soId: any) => {
        if (salesOrder && salesOrder.soId != soId) {
            setDocs([]);
            setPackages([]);
            setBackOrders([]);
            setSalesReturns([]);
            setBackOrderAlert(false);
            setIsBackOrder(false)
        }
        let params: any = {
            filters: {
                soId: soId
            },
            include: 'customer,status,items'
        };
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-orders?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesOrder(response.data[0]);
            fetchPackages(soId);
            fetchPurchaseOrders(soId)
            getDocs(soId);
            fetchSalesReturns(soId);
        } else {
            setSalesOrder(null);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchPackages = async (soId: any) => {
        if (!user?.company?.companyId) {
            return;
        }
        let params: any = {
            filters: {
                soId: soId
            }
        };
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/packages-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setPackages(response.data);
        } else {
            setPackages([]);
        }
    };

    const fetchSalesReturns = async (soId: any) => {
        if (!user?.company?.companyId) {
            return;
        }
        let params: any = {
            filters: {
                soId: soId
            }
        };
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/sales-returns-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesReturns(response.data);
        } else {
            setSalesReturns([]);
        }
    };

    const fetchPurchaseOrders = async (soId: any) => {
        if (!user?.company?.companyId) {
            return;
        }
        let params: any = {
            filters: {
                soId: soId
            }
        };
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/purchase-orders-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setBackOrders(response.data);
        } else {
            setBackOrders([]);
        }
    };

    const printDoc = async (soId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const attacheDocs = async () => {
        setUploadVisible(true)
    }

    const uploadDocs = async (assets: any) => {
        let soId = salesOrder?.soId;
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/docs`, assets);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Upload Successfully');
            getDocs(soId);
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const getDocs = async (soId: any) => {
        setLoading(true);
        setDocs([])
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/docs?include=asset,user`);
        if (response.code == 'SUCCESS') {
            setDocs(response.data)
        } else {
            setDocs([])
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const viewImage = (file: Asset) => {
        setShowImage(true);
        setAssetFile(file)
    }

    const deleteDoc = async (file: any) => {
        const sodIds = map([file], 'id')
        console.log('sodIds', sodIds)
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/sales-orders/${salesOrder?.soId}/docs`, { sodIds: sodIds });
        if (response.code == 'SUCCESS') {
            getDocs(salesOrder?.soId)
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const printPackageDoc = async (packageId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/packages/${packageId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const printSOPickTicketDoc = async (soId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/print-pick-ticket`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const printPickTicketDoc = async (packageId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/packages/${packageId}/print-pick-ticket`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const printPODoc = async (poId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const panelHeaderTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className} onClick={() => {
                if (backOrders.length > 0) {
                    setDisplayTabIndex(0)
                }
                else if (packages.length > 0) {
                    setDisplayTabIndex(1)
                }
                else {
                    setDisplayTabIndex(2)
                }
                panelRef.current?.toggle(undefined)
            }}>
                <div className='flex gap-4'>
                    {
                        backOrders.length > 0 && <div className="flex align-items-center cursor-pointer gap-2" onClick={(e) => {
                            e.stopPropagation();
                            panelRef.current?.expand(e);
                            setDisplayTabIndex(0);
                        }}>
                            <span className="font-bold">Backorders <Badge value={backOrders.length}></Badge></span>
                        </div>
                    }
                    {
                        packages.length > 0 && <div className="flex align-items-center cursor-pointer gap-2" onClick={(e) => {
                            e.stopPropagation();
                            panelRef.current?.expand(e);
                            setDisplayTabIndex(1);
                        }}>
                            <span className="font-bold">Packages <Badge value={packages.length}></Badge></span>
                        </div>
                    }
                    {
                        saleReturns.length > 0 && <div className="flex align-items-center cursor-pointer gap-2" onClick={(e) => {
                            e.stopPropagation();
                            panelRef.current?.expand(e);
                            setDisplayTabIndex(2);
                        }}>
                            <span className="font-bold">Returns & Cancellations <Badge value={saleReturns.length}></Badge></span>
                        </div>
                    }
                </div>
                <div>
                    {options.togglerElement}
                </div>
            </div>
        );
    };

    const renderProduct = (item: SalesOrderItem | null, option: ColumnBodyOptions) => {
        return <div>
            <label className='text-900'>{get(item, 'product.name')}</label><br />
            {/* <span className='text-sm'>SKU: {get(item, 'product.skuId')}</span> */}
        </div>
    };

    const renderShipmentStatus = (item: SalesOrderItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <div style={{ width: 100 }}></div>;
        }
        return (
            <div style={{ width: 100, textAlign: 'right' }}>
                <p>{item.packed || 0} Packed</p>
                {
                    item.backOrdered > 0 && <p>{item.backOrdered || 0} Backordered</p>
                }
                <p>{item.shipped || 0} Shipped</p>
            </div>
        );
    };

    const getDiscountAmount = () => {
        let sum = sumBy(get(salesOrder, 'items', []), (item) => item.quantity * item.price);
        let discountAmount = salesOrder?.discountAmount ? get(salesOrder, 'discountAmount', 0) : 0 || 0;
        if (salesOrder?.discountType == 'PERCENTAGE') {
            return sum * (discountAmount / 100);
        }
        return discountAmount;
    }

    const calculateBalance = () => {
        let sum = sumBy(get(salesOrder, 'items', []), (item) => item.quantity * item.price);
        return sum + (sum * (get(salesOrder, 'vat', 0) / 100)) - getDiscountAmount() + get(salesOrder, 'shippingPrice', 0) - get(salesOrder, 'paid', 0)
    }

    const renderTotal = (item: SalesOrderItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return item.price * item.quantity;
    };

    if (!soId) {
        return <></>
    }

    return <>
        <Sidebar
            isVisible={dialogVisible}
            action={ACTIONS.VIEW}
            width={'60vw'}
            footerTemplate={<></>}
            title={salesOrder?.soNumber ? salesOrder.soNumber : ''}
            closeIcon={() => {
                setDialogVisible(false)
                onClose(false);
            }}
            content={<>
                <div className='flex w-full absolute bg-ligthgrey br-top br-bottom z-2' style={{ top: '4rem', left: 0 }}>
                    <div className='page-menu-item p-3 pl-5 br-right cursor-pointer' onClick={() => onClose(true)}><i className="pi pi-pencil"></i> Edit</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={(event) => printDoc(salesOrder?.soId)}><i className="pi pi-file-pdf"></i> Pdf/Print</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={attacheDocs}><i className="pi pi-paperclip"></i> Attach Files</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={(event) => { if (createMenuRef.current) { createMenuRef.current.toggle(event) } }}><i className="pi pi-ellipsis-v"></i></div>
                    <Menu model={createMenuItems} popup ref={createMenuRef} id="popup_menu_left" />
                </div>
                <div className='pt-7 pr-2'>
                    {
                        (packages.length > 0 || backOrders.length > 0 || saleReturns.length > 0) && <Panel ref={panelRef} collapsed={true} headerTemplate={panelHeaderTemplate} className='mb-4 bg-white ship-pkg-items' collapseIcon={<IconField className='pi pi-angle-down' />} expandIcon={<IconField className='pi pi-angle-right' />} toggleable>
                            <div className="m-0 p-2">
                                <div className="w-full">
                                    {
                                        backOrders.length > 0 && displayTabIndex == 0 && <DataTable
                                            value={backOrders || []}
                                            style={{ height: '80%' }}
                                        >
                                            <Column header="Backordered PO #" field='poNumber' body={(option) => <span className='text-blue cursor-pointer' onClick={() => navigate(`/purchase-orders?poId=${option.poId}`)}>{option.poNumber}</span>} />
                                            <Column header="Date On" body={(option) => moment(option.poDate).format('MM/DD/YYYY')} />
                                            <Column header="Status" field="status" />
                                            <Column header="Expected Deliery Date" body={(option) => moment(option.poDate).format('MM/DD/YYYY')} />
                                            <Column header="" style={{ padding: 0 }} body={(option) => <>
                                                <Button type="button" icon={'pi pi-file-pdf'} className="p-button-sm p-button-text" title='Print PO' onClick={() => printPODoc(option.poId)} />
                                            </>} />
                                        </DataTable>
                                    }
                                    {
                                        packages.length > 0 && displayTabIndex == 1 && <DataTable
                                            value={packages || []}
                                            style={{ height: '80%' }}
                                        >
                                            <Column header="Package #" field='pkgNumber' body={(option) => <span className='text-blue cursor-pointer' onClick={() => navigate(`/packages?packageId=${option.packageId}`)}>{option.pkgNumber}</span>} />
                                            <Column header="Pack On" field='createdAt' body={(option) => moment(option.createdAt).format('MM/DD/YYYY')} />
                                            <Column header="" style={{ padding: 0 }} body={(option) => <>
                                                <Button type="button" icon={'pi pi-clipboard'} className="p-button-sm p-button-text" title='Print Pick Picket' onClick={() => printPickTicketDoc(option.packageId)} />
                                                <Button type="button" icon={'pi pi-receipt'} className="p-button-sm p-button-text" title='Print Package Slip' onClick={() => printPackageDoc(option.packageId)} />
                                            </>} />
                                        </DataTable>
                                    }

                                    {
                                        saleReturns.length > 0 && displayTabIndex == 2 && <DataTable
                                            value={saleReturns || []}
                                            style={{ height: '80%' }}
                                        >
                                            <Column header="RMA#" field='rmaNumber' body={(option) => <span className='text-blue cursor-pointer' onClick={() => navigate(`/sales-returns?rmaId=${option.rmaId}`)}>{option.rmaNumber}</span>} />
                                            <Column header="Date" field='rmaDate' body={(option) => moment(option.rmaDate).format('MM/DD/YYYY')} />
                                            <Column header="Status" field='status' />
                                        </DataTable>
                                    }
                                </div>
                            </div>
                        </Panel>
                    }
                    <div className='grid justify-content-between p-2'>
                        <div>
                            <h4>Sales Order</h4>
                            <p>Sales Order# <strong>{salesOrder?.soNumber}</strong></p>
                            <p>Order Date <strong>{isMoment(moment(salesOrder?.soDate)) ? moment(salesOrder?.soDate).format('MM/DD/YYYY') : ''}</strong></p>
                        </div>
                        <div>
                            <p>Billing Address</p>
                            <p className='text-blue cursor-pointer'><strong>{get(salesOrder, 'customer.name', '')}</strong></p>
                        </div>
                    </div>
                    <div>
                        <p>Status <strong>{get(salesOrder, 'status.value')}</strong></p>
                    </div>
                    {
                        isBackOrderAlert && <Message
                            style={{
                                color: '#696cff'
                            }}
                            className="w-full justify-content-start mt-4"
                            severity="warn"
                            content={<div className="flex align-items-center">
                                <i className='pi pi-exclamation-triangle text-warn' />
                                <div className="text-warn">Some items need backordering. <span className='text-blue cursor-pointer' onClick={() => setIsBackOrder(true)}>Backorder now</span></div>
                            </div>}
                        />
                    }
                    <div className="mt-4">
                        <h5>Line Items</h5>
                        <DataTable
                            scrollable
                            showGridlines
                            value={get(salesOrder, 'items', [])}
                            selectionMode="single"
                            dataKey="productId"
                            className='table-line-item'
                            // onSelectionChange={(row: any) => onRowSelect(row.value, 'view')}
                            scrollHeight="70%"
                            style={{ height: '80%', zIndex: 0 }}
                        >
                            <Column field="soDate" header="Product & SKU" body={(data, options: ColumnBodyOptions) => renderProduct(data, options)}></Column>
                            <Column field="quantity" header="Quantity" style={{ width: 108, textAlign: 'right' }} body={(item) => <div className='flex flex-column'>{item.quantity}<br /><small>Stock in hand:</small><small>{formatWithWordsNumber(item.stockAvail) || 0}</small></div>} ></Column>
                            <Column field="soDate" header="Status" body={renderShipmentStatus} style={{ width: 80, textAlign: 'right' }}></Column>
                            <Column field="price" header="Rate" style={{ width: 120, textAlign: 'right' }}></Column>
                            <Column field="poNumber" header="Total" body={renderTotal} style={{ width: 150, textAlign: 'right' }}></Column>
                        </DataTable>
                        <div className="grid mt-3">
                            <div className="col-5 col-offset-7">
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Sub Total</p>
                                    <p className="font-bold">${sumBy(get(salesOrder, 'items', []), (item: any) => item.quantity * item.price)}</p>
                                </div>
                                {
                                    get(salesOrder, 'vat', 0) > 0 && <div className="flex justify-content-between align-items-baseline">
                                        <p className="font-semibold">VAT {get(salesOrder, 'vat', 0)}%</p>
                                        <p className="font-bold">${sumBy(get(salesOrder, 'items', []), (item: any) => item.quantity * item.price) * (get(salesOrder, 'vat', 0) / 100)}</p>
                                    </div>
                                }

                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Shipping Charges</p>
                                    <p className="font-semibold">{salesOrder?.shippingPrice}</p>
                                </div>

                                <div className="flex justify-content-between align-items-center">
                                    <p className="font-semibold">Discount {salesOrder?.discountAmount} {salesOrder?.discountType == 'FIXED' ? ' Flat' : '%'}</p>
                                    <p className="font-semibold">-{getDiscountAmount()}</p>
                                </div>
                                <hr className=" mx-3 border-top-1 border-none surface-border" />
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Total</p>
                                    <p className="font-bold">${salesOrder?.totalPrice || 0}</p>
                                </div>
                                {
                                    salesOrder?.paid > 0 && <div className="flex justify-content-between align-items-baseline">
                                        <p className="font-semibold">Paid</p>
                                        <p className="font-semibold">-${salesOrder?.paid || 0}</p>
                                    </div>
                                }
                                <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Balance</p>
                                    <p className="font-bold">${calculateBalance()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h5>Remarks</h5>
                            <p>{salesOrder?.note || 'N/A'}</p>
                        </div>
                        {
                            docs != undefined && docs.length > 0 && <div className="mt-3">
                                <h5>Attachments</h5>
                                <div className='grid mt-2'>
                                    {
                                        docs.map((file: any) => (
                                            <div key={`file_${file.id}_${get(file, 'asset.name')}`} className='col-12 flex'>
                                                <div className="flex gap-3 flex-column p-2 shadow-2 border-round align-items-center text-center border-round-sm mr-4" style={{ maxWidth: 250 }}>
                                                    <MemoizedFileItem edit={true} key={`file_image_${get(file, 'asset.assetId')}_${get(file, 'asset.name')}`} file={file.asset} onView={() => viewImage(file.asset)} onDelete={() => deleteDoc(file)} />
                                                </div>
                                                <div className="file-info flex flex-column  mb-2">
                                                    <p className='m-0  sub-desc text-xl font-bold text-overflow-ellipsis' style={{ overflow: 'hidden' }}>{get(file, 'asset.name')}</p>
                                                    <p className='m-0 mt-2 sub-desc'>{get(file, 'asset.type', '').toUpperCase()} • {formatBytes(get(file, 'asset.sizeInBytes'))}</p>
                                                    <p className='m-0 mt-2 sub-desc text-overflow-ellipsis'>{get(file, 'comment', '')}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>}
        />

        <ConfirmDialog />
        <FileView isVisible={isShowImage} assetFile={assetFile} onClose={() => setShowImage(false)} />
        {
            isPackage && salesOrder?.soId && <PackageEditor
                isVisible={isPackage}
                salesOrder={salesOrder}
                onClose={(isLoad) => {
                    setShowPackage(false);
                    if (isLoad) {
                        fetchSoDetails(salesOrder?.soId);
                    }
                }}
            />
        }
        {/* BackOrder */}
        {
            isBackOrder && salesOrder?.soId && <BackOrderEditor
                isVisible={isBackOrder}
                soId={salesOrder.soId}
                items={salesOrder.items}
                onClose={(isLoad) => {
                    setIsBackOrder(false);
                    if (isLoad) {
                        fetchSoDetails(salesOrder?.soId);
                    }
                }}
            />
        }
        {/* Sales Return */}
        {
            isSalesReturn && salesOrder?.soId && <SalesReturnsEditor
                isVisible={isSalesReturn}
                soId={salesOrder.soId}
                onClose={(isLoad) => {
                    setIsSalesReturn(false);
                    if (isLoad) {
                        fetchSoDetails(salesOrder?.soId);
                    }
                }}
            />
        }
        <UploadFile isVisible={uploadVisible}
            onSelect={(option: any) => {
                setUploadVisible(false);
                if (salesOrder?.soId && option && option.length > 0) {
                    let assets = option.map((item: any) => ({
                        assetId: item.assetId,
                        comment: item.comment || ''
                    }))
                    uploadDocs(assets);
                }
            }}
        />
    </>
}