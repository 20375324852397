

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, filterArray, formatString, getRowLimitWithScreenHeight, validateName, validateMaxNumberOfRacks, validateMinNumberOfRacks, validateSubdomain, validateZipNumber, validateNumber } from '../../../utils/uitl';
import { COMPANIES_MENU, COMPANY_MENU, CompanyModule, CompanyWrite, DashboardModule } from '../../../config/permissions';
import { InputSwitch } from 'primereact/inputswitch';
import { TreeTable, TreeTableFilterMeta } from 'primereact/treetable';
import { TreeNode } from 'primereact/treenode';
import { Dropdown } from "primereact/dropdown";
import { constant } from '../../../utils/constant'
import { EmptyBin } from '../../../types/forms';
import { useAppContext } from '../../../layout/AppWrapper';
import { CompanyBin, CustomResponse } from '../../../types';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { DeleteCall, GetCall, PostCall, PostPdfCall, PutCall } from '../../../api/ApiKit';
import DownloadBarcodeButton from '../../../components/DownloadBarcodeButton';
import RightSidePanel from '../../../components/RightSidePanel';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
}

const defaultForm: EmptyBin = {
    rowId: null,
    rackTypeId: null,
    capacityId: null,
    rackId: null,
    noOfBins: null,
    locationId: null

}

const BinPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const companyId = get(user, 'company.companyId');
    const { layoutState } = useContext(LayoutContext);
    const multiSelectRef = useRef<MultiSelect>(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanyBin[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<CompanyBin | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<TreeTableFilterMeta>({});
    const [selectedLocationId, setSelectedLocationId] = useState<any>(null);
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<EmptyBin>(defaultForm);
    const [confirmTextValue, setConfirmValue] = useState<any>('');

    const [companyAllLocation, setcomapnyAllLocation] = useState<any>(null);
    const [companyRackId, setcompanyRackId] = useState<any>(null);
    const [selectedBinTypeId, setSelectedBinTypeId] = useState<any>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null)
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    useEffect(() => {
        setScroll(false);
        fetchDetails()
        fetchPermissions()
        fetchData();
        return () => {
            setScroll(true);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsButtonDisabled(false);
        }, 3000); // 3 seconds delay
        return () => clearTimeout(timer);
    }, []);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page }
        }
        const companyId = get(user, 'company.companyId')
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/bins?${queryString}`);// get all the Racks
        setLoading(false)
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            setSelectedCompany(null)
            if (response.total) {
                setTotalRecords(response?.total)
            }
        }
        else {
            setCompanies([]);
        }
    }

    const fetchPermissions = async () => {
        const companyId = get(user, 'company.companyId')
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/locations?limit=500`);// get company all roles
        if (response.code == 'SUCCESS') {
            setcomapnyAllLocation(response.data);
        }
        else {
            setcomapnyAllLocation([]);
        }
        setLoading(false)
    }

    const fetchDetails = async () => {
        const companyId = get(user, 'company.companyId')
        const type = constant.SYSTEM_MSTR_CODE.binCapacity
        setIsDetailLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes-by-types/${type}`);
        setIsDetailLoading(false)
        if (response.code == 'SUCCESS') {
            setSelectedBinTypeId(response.data.codes)
        }
    }
    const treeData: TreeNode[] = companies.map((company) => ({
        key: company.binId?.toString(),
        data: {
            binId: company.binId,
            rackId: company.rackId,
            name: company.skuId,
            rowId: company.rowId,
            value: company.binCapacity?.value,
            capacityId: company.capacityId,
            location: company.location?.location,
            locationId: company.locationId,
        }
    }));

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false)
        setForm(defaultForm)
        setAction(null)
    }

    const showAddNew = () => {
        // fetchPermissions();
        setIsShowSplit(true);
        setAction('add');
        setSelectedCompany(null);
        setForm(defaultForm);
    }

    const onSave = () => {
        if (action == ACTIONS.ADD) {
            onNewAdd({ ...form })
            return;
        }

        if (action == ACTIONS.EDIT) {
            onUpdate(form);
        }
    }

    const onNewAdd = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId')

        if (!validateNumber(companyForm.locationId)) {
            setAlert('error', "Please provide valid location");
            return;
        }
        if (!validateNumber(companyForm.capacityId)) {
            setAlert('error', "Please provide valid Bin Capacity");
            return;
        }

        if (!validateMaxNumberOfRacks(+companyForm.noOfBins)) {
            setAlert('error', "The bins can't be more than 50")
            return;
        }
        if (!validateMinNumberOfRacks(+companyForm.noOfBins)) {
            setAlert('error', "The bins can't be 0")
            return;
        }
        setIsDetailLoading(true);
        const response: CustomResponse = await PostCall(`/company/${companyId}/bins`, companyForm);
        setIsDetailLoading(false)
        if (response.code == 'SUCCESS') {
            setIsShowSplit(false)
            setSelectedCompany(response.data)
            setAlert('success ', 'Successfully Added')
            dataTableRef.current?.updatePagination(1);
        }
        else {
            setAlert('error', response.message)
        }
    }


    const onUpdate = async (companyForm: any) => {
        if (!validateMaxNumberOfRacks(companyForm.noOfBins)) {
            setAlert('error', "The bins can't be more than 50")
            return;
        }
        const companyId = get(user, 'company.companyId')
        setIsDetailLoading(true);
        const response: CustomResponse = await PutCall(`/company/${companyId}/bins/${companyRackId.binId}`, companyForm);
        setIsDetailLoading(false)
        if (response.code == 'SUCCESS') {
            setIsShowSplit(false)
            setSelectedCompany(selectedCompany)
            setAlert('success', 'Successfully Updated')
            dataTableRef.current?.refreshData()
        }
        else {
            setAlert('error ', response.message)
        }
    }

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: "Do you really want to delete this?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            }
        });
    }

    const onDelete = async (item: any) => {
        const companyId = get(user, 'company.companyId')
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/bins/${item.binId}`);
        setLoading(false)
        if (response.code == 'SUCCESS') {
            setAction('')
            setSelectedCompany(null)
            setAlert('success ', 'Successfully Deleted')
            fetchData();
        }
        else {
            setAlert('error', response.message)
        }
        setIsDeleteDialogVisible(false)
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(companies);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Bins');
        });
    };
    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const openDeleteDialog = (perm: CompanyBin) => {
        setSelectedCompany(perm);
        setIsDeleteDialogVisible(true);
    };

    const onRowSelect = async (company: CompanyBin, action: any) => {
        await setSelectedCompany(company);
        setcompanyRackId(company)
        setSelectedLocationId(company)
        setAction(action);

        if (action == ACTIONS.DELETE) {
            openDeleteDialog(company);
        }

        if (action == ACTIONS.EDIT) {
            setForm({ ...company });
            setIsShowSplit(true);
        }
    }

    const downloadBarcode = async (item: any) => {
        setLoading(true);
        try {
            const result = await PostPdfCall(`/company/${get(user, 'company.companyId')}/download-barcodes`, { type: 'BIN', ids: [item.binId] });
            if (result && result.code === 'FAILED') {
                setAlert('error', result.message);
            }
        } catch (error: any) {
            setAlert('error', error.message || 'Something went wrong!');
        } finally {
            setLoading(false);
        }
    }

    const onInputChange = (name: string | { [key: string]: any }, val?: any) => {
        setForm((prevForm: any) => {
            let updatedForm = { ...prevForm };

            if (typeof name === 'string') {
                // Single field update
                updatedForm[name] = val;
            } else {
                // Multiple fields update (assuming name is an object containing key-value pairs)
                updatedForm = { ...updatedForm, ...name };
            }

            return updatedForm;
        });
    };
    const headerTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <div className="ellipsis-container font-bold" style={{ marginLeft: 10, maxWidth: '22vw' }}>{action == ACTIONS.ADD ? 'Add Bin' : selectedCompany?.skuId}</div>
                </div>
            </div>
        );
    };
    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                {
                    action == ACTIONS.VIEW_PERMISSIONS ? <Button label="Back" severity="secondary" text onClick={() => setAction(ACTIONS.VIEW)} /> : <div></div>
                }
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={(isLoading || isDetailLoading)} onClick={onSave} />}
                </div>
            </div>
        );
    }

    const handleLocationChange = (e: any) => {
        const selectedLocation = e.value; // e.value contains the selected location object
        onInputChange({
            locationId: selectedLocation.locationId,
            location: selectedLocation.location
        }); // Store the subLocationId in the form
    };
    const handleBinTypeChange = (e: any) => {
        const selectedRackType = selectedBinTypeId.find(
            (rack: any) => rack.masterCodeId === e.value
        );

        if (selectedRackType) {
            onInputChange({
                capacityId: selectedRackType.masterCodeId,
                value: selectedRackType.value
            });
        }
    };

    const renderWarehouse = (item: any) => get(item, 'location.name', '');
    const renderCapacity = (item: any) => get(item, 'binCapacity.code', '');
    const capacityDropdown = (options: any) => <Dropdown filter value={options.value || null} options={selectedBinTypeId} optionLabel='code' optionValue='masterCodeId' onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select capacity" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />;
    const locationDropdown = (options: any) => <Dropdown filter value={options.value || null} options={companyAllLocation} optionLabel='name' optionValue='locationId' onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select location" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />;

    return (
        <AppPage>
            <ConfirmDialog/>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Bins'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                data={companies}
                                headerButtons={[
                                    {
                                        icon: 'pi pi-file-excel',
                                        onClick: () => exportExcel()
                                    },
                                    {
                                        content: <DownloadBarcodeButton
                                            url={`/company/${get(user, 'company.companyId')}/download-barcodes`}
                                            type={'BIN'}
                                            ids={companies}
                                            optionLabel='binNumber'
                                            optionValue='binId'
                                        />
                                    },
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                extraButtons={[
                                    {
                                        icon: 'pi pi-qrcode',
                                        onClick: (item) => downloadBarcode(item)
                                    }
                                ]}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'binId',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { width: 100 },
                                        filterPlaceholder: 'Bin Id'
                                    },
                                    {
                                        header: 'Name',
                                        field: 'binNumber',
                                        filter: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'Location',
                                        field: 'locationId',
                                        body: renderWarehouse,
                                        filter: true,
                                        filterElement: locationDropdown,
                                        filterPlaceholder: 'Search location'
                                    },
                                    {
                                        header: 'Capacity',
                                        field: 'capacityId',
                                        body: renderCapacity,
                                        filter: true,
                                        filterElement: capacityDropdown
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <RightSidePanel
                            isVisible={isShowSplit}
                            headerTemplate={headerTemplate}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={<>
                                {
                                    isDetailLoading && <div className='center-pos'>
                                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                    </div>
                                }

                                {/* Edit Permissions */}
                                {
                                    (action == ACTIONS.ADD || action == ACTIONS.EDIT) && <div className="p-fluid">
                                        <div className="field">
                                            <label htmlFor="location">
                                                Location <span className="red">*</span>
                                            </label>
                                            <Dropdown
                                                value=
                                                {companyAllLocation.find((loc: any) => loc.locationId === get(form, 'locationId')) || null}
                                                onChange={handleLocationChange}
                                                options={companyAllLocation}
                                                optionLabel="name" // Display location name in dropdown
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="field">
                                            <label htmlFor="location">
                                                Bin Capacity <span className="red">*</span>
                                            </label>
                                            <Dropdown
                                                value={get(form, 'capacityId')}
                                                onChange={handleBinTypeChange}
                                                options={selectedBinTypeId}
                                                optionValue='masterCodeId'
                                                optionLabel="code"
                                                placeholder="Select"
                                            />
                                        </div>
                                        {action === ACTIONS.ADD && (
                                            <div className="field">
                                                <label htmlFor="noOfBins">
                                                    No. of Bins <span className="red">*</span>
                                                </label>
                                                <InputText
                                                    id="noOfBins"
                                                    value={String(get(form, 'noOfBins') ?? '')}
                                                    onChange={(e) => onInputChange('noOfBins', e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                }
                            </>}
                        />
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default BinPage;




