


import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import AppPage from '../../../layout/AppPage';
import { Button } from 'primereact/button';
import ProductListPage from './Products';

const CataloguePage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const initializedRef = useRef(false);
    const productRef = useRef<any>(null);
    const partRef = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState<any>(0);
    useEffect(() => {
        setScroll(false);

        return () => {
            setScroll(true);
        }
    }, []);

    const showNewAdd = () => {
        if (activeIndex == 0) {
            if (productRef && productRef.current) {
                productRef.current?.showAddNew();
            }
        }
        else if (activeIndex == 1) {
            if (partRef && partRef.current) {
                partRef.current?.showAddNew();
            }
        }
    }

    const showBulkAdd = () => {
        if (activeIndex == 1) {
            if (partRef && partRef.current) {
                partRef.current?.showBulkNew();
            }
        }
    }

    return (
        <AppPage>
            {/* <div className='w-full'>
                <div className="flex flex-wrap align-items-center justify-content-between gap-2 mb-3">
                    <div>
                        <p className='text-xl font-bold'>Catalogues</p>
                    </div>
                    <div className='flex align-items-center justify-content-end'>
                        {
                            activeIndex == 1 && <Button className='mr-2' label='Bulk' icon="pi pi-file-excel" size='small' onClick={() => showBulkAdd()}></Button>
                        }
                        <Button label='New' icon="pi pi-plus" size='small' onClick={() => showNewAdd()}></Button>
                    </div>
                </div>
            </div> */}
            <ProductListPage ref={productRef} />
        </AppPage>
    );
};
export default CataloguePage;
