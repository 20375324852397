


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { CompanyRack, CustomResponse, Permissions } from '../../../types';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PostPdfCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { EmptyRack } from '../../../types/forms';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, filterArray, formatString, getRowLimitWithScreenHeight, validateName, validateMaxNumberOfRacks, validateMinNumberOfRacks, validateNumberOfRackRow, validateZipNumber, validateNumber } from '../../../utils/uitl';
import { COMPANIES_MENU, COMPANY_MENU, CompanyModule, CompanyWrite, DashboardModule } from '../../../config/permissions';
import { InputSwitch } from 'primereact/inputswitch';
import DefaultLogo from '../../../components/DefaultLogo';
import RightSidePanel from '../../../components/RightSidePanel';
import { TreeTable, TreeTableFilterMeta } from 'primereact/treetable';
import { TreeNode } from 'primereact/treenode';
import { Dropdown } from 'primereact/dropdown';
import { constant } from '../../../utils/constant';
import DownloadBarcodeButton from '../../../components/DownloadBarcodeButton';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import AppPage from '../../../layout/AppPage';
import { useSelector } from 'react-redux';
import { ReduxStoreState } from '../../../redux/redux-store';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptyRack = {
    locationId: null,
    subLocationId: null,
    name: '',
    desc: '',
    rackTypeId: null,
    noOfRows: 0,
    value: '',
    location: '',
    noOfRacks: 0
};

const RackPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanyRack[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<CompanyRack | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [details, setDetails] = useState<any>(null);
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<EmptyRack>(defaultForm);

    const [permissions, setPermissions] = useState<any[]>([]);
    const [selectedrackTypeId, setSelectedRackTypeId] = useState<any>(null);
    const [companyRackId, setcompanyRackId] = useState<any>(null);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null)
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const companyAllLocation = useSelector((state: ReduxStoreState) => state.locations.locations);

    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchPermissions();

        return () => {
            setScroll(true);
        };
    }, []);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page }
        }
        const companyId = get(user, 'company.companyId')
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/racks?${queryString}`); // get all the Racks
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            setSelectedCompany(null);
            if (response.total) {
                setTotalRecords(response?.total)
            }
        } else {
            setCompanies([]);
        }
    };

    const fetchPermissions = async () => {
        const companyId = get(user, 'company.companyId');
        const type = constant.SYSTEM_MSTR_CODE.rackType;
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes-by-types/${type}?limit=500`); // get company all roles
        if (response.code == 'SUCCESS') {
            const codes = get(response.data, 'codes', []);
            setPermissions(codes);
            setSelectedRackTypeId(response.data.codes);
        } else {
            setPermissions([]);
        }
        setLoading(false);
    };

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false);
        setForm(defaultForm);
        setAction(null);
    };

    const showAddNew = () => {
        setIsShowSplit(true);
        setAction('add');
        setSelectedCompany(null);
        setForm(defaultForm);
    };

    const onSave = () => {
        if (action == ACTIONS.ADD) {
            onNewAdd({ ...form });
            return;
        }

        if (action == ACTIONS.EDIT) {
            onUpdate(form);
        }
    };

    const onNewAdd = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId');
        if (!companyForm.locationId) {
            setAlert('error', "Please provide valid location");
            return;
        }
        if (!companyForm.rackTypeId) {
            setAlert('error', "Please provide valid Rack Type");
            return;
        }
        if (+companyForm.noOfRacks > 50) {
            setAlert('error', "The racks can't be more than 50");
            return;
        }

        setIsDetailLoading(true);
        const response: CustomResponse = await PostCall(`/company/${companyId}/racks`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setIsShowSplit(false);
            setSelectedCompany(response.data);
            setAlert('success ', 'Successfully Added');
            fetchData();
        } else {
            setAlert('error', response.message);
        }
    };

    const onUpdate = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId');
        setIsDetailLoading(true);
        const response: CustomResponse = await PutCall(`/company/${companyId}/racks/${companyRackId.rackId}`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setIsShowSplit(false);
            setSelectedCompany(selectedCompany);
            setAlert('success ', 'Successfully Updated');
            dataTableRef.current?.refreshData()
        } else {
            setAlert('error ', response.message);
        }
    };

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: "Do you really want to delete this?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            }
        });
    }

    const onDelete = async (item: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/racks/${item.rackId}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setAction('');
            setSelectedCompany(null);
            setAlert('success ', 'Successfully Deleted ');
            fetchData()
        } else {
            setAlert('error', response.message);
        }
        setIsDeleteDialogVisible(false)
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(companies);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'Racks');
        });
    };

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const openDeleteDialog = (perm: CompanyRack) => {
        setSelectedCompany(perm);
        setIsDeleteDialogVisible(true);
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogVisible(false);
        setSelectedCompany(null);
    };

    const onRowSelect = async (company: CompanyRack, action: any) => {
        await setSelectedCompany(company);
        setcompanyRackId(company);
        setAction(action);

        if (action == ACTIONS.DELETE) {
            openDeleteDialog(company);
        }

        setDetails(null);

        if (action == ACTIONS.EDIT) {
            setForm({ ...company });
            setIsShowSplit(true);
        }
    };

    const downloadBarcode = async (item: any) => {
        setLoading(true);
        try {
            const result = await PostPdfCall(`/company/${get(user, 'company.companyId')}/download-barcodes`, { type: 'RACK', ids: [item.rackId] });
            if (result && result.code === 'FAILED') {
                setAlert('error', result.message);
            }
        } catch (error: any) {
            setAlert('error', error.message || 'Something went wrong!');
        } finally {
            setLoading(false);
        }
    }

    const onInputChange = (name: string | { [key: string]: any }, val?: any) => {
        setForm((prevForm: any) => {
            let updatedForm = { ...prevForm };

            if (typeof name === 'string') {
                updatedForm[name] = val;
            } else {
                updatedForm = { ...updatedForm, ...name };
            }

            return updatedForm;
        });
    };

    const headerTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <div className="ellipsis-container font-bold" style={{ marginLeft: 10, maxWidth: '22vw' }}>
                        {action == ACTIONS.ADD ? 'Add Rack' : selectedCompany?.skuId}
                    </div>
                </div>
            </div>
        );
    };
    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                {action == ACTIONS.VIEW_PERMISSIONS ? <Button label="Back" severity="secondary" text onClick={() => setAction(ACTIONS.VIEW)} /> : <div></div>}
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={isLoading || isDetailLoading} onClick={onSave} />}
                </div>
            </div>
        );
    };

    const handleLocationChange = (e: any) => {
        const selectedLocation = e.value; // e.value contains the selected location object
        onInputChange({
            locationId: selectedLocation.locationId,
            location: selectedLocation.location
        }); // Store the subLocationId in the form
    };
    const handleRackTypeChange = (e: any) => {
        const selectedRackType = selectedrackTypeId.find((rack: any) => rack.masterCodeId === e.target.value);
        if (selectedRackType) {
            onInputChange({
                rackTypeId: selectedRackType.masterCodeId,
                value: selectedRackType.value
            });
        }
    };

    const renderRackType = (item: any) => get(item, 'rackType.code');
    const renderWarehouse = (item: any) => get(item, 'location.name');
    const renderRows = (item: any) => get(item, 'noOfRows', 0);
    const rackTypeDropdown = (options: any) => <Dropdown filter value={options.value || null} options={permissions} optionLabel='code' optionValue='masterCodeId' onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select rack type" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />;
    const locationDropdown = (options: any) => <Dropdown filter value={options.value || null} options={details} optionLabel='name' optionValue='locationId' onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select location" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />;

    return (
        <AppPage>
            <ConfirmDialog/>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Racks'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                data={companies}
                                headerButtons={[
                                    {
                                        icon: 'pi pi-file-excel',
                                        onClick: () => exportExcel()
                                    },
                                    {
                                        content: <DownloadBarcodeButton
                                            url={`/company/${get(user, 'company.companyId')}/download-barcodes`}
                                            type={'RACK'} ids={companies}
                                            optionLabel='skuId'
                                            optionValue='rackId'
                                        />
                                    },
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                extraButtons={[
                                    {
                                        icon: 'pi pi-qrcode',
                                        onClick: (item) => downloadBarcode(item)
                                    }
                                ]}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'rackId',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { width: 100 },
                                        filterPlaceholder: 'Rack Id'
                                    },
                                    {
                                        header: 'Name',
                                        field: 'skuId',
                                        filter: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'Rack Type',
                                        field: 'rackTypeId',
                                        body: renderRackType,
                                        filter: true,
                                        filterElement: rackTypeDropdown,
                                        filterPlaceholder: 'Search rack type'
                                    },
                                    {
                                        header: 'Warehouse',
                                        field: 'locationId',
                                        body: renderWarehouse,
                                        filter: true,
                                        filterElement: locationDropdown,
                                        filterPlaceholder: 'Search location'
                                    },
                                    {
                                        header: '# Rows',
                                        field: 'noOfRows',
                                        body: renderRows,
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <RightSidePanel
                            isVisible={isShowSplit}
                            headerTemplate={headerTemplate}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={
                                <>
                                    {isDetailLoading && (
                                        <div className="center-pos">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    )}

                                    {/* Edit Permissions */}
                                    {(action == ACTIONS.ADD || action == ACTIONS.EDIT) && (
                                        <div className="p-fluid">
                                            <div className="field">
                                                <label htmlFor="location">
                                                    Location <span className="red">*</span>
                                                </label>
                                                <Dropdown
                                                    value={companyAllLocation.find((loc: any) => loc.locationId === get(form, 'locationId')) || null}
                                                    onChange={handleLocationChange}
                                                    options={companyAllLocation}
                                                    optionLabel="name" // Display location name in dropdown
                                                    placeholder="Select"
                                                />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="desc">
                                                    Description
                                                </label>
                                                <InputText id="desc" value={get(form, 'desc')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('desc', e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="value">
                                                    Rack Type <span className="red">*</span>
                                                </label>
                                                <Dropdown
                                                    value={get(form, 'rackTypeId')}
                                                    onChange={(e) => handleRackTypeChange(e)}
                                                    options={selectedrackTypeId}
                                                    optionLabel="code"
                                                    optionValue="masterCodeId"
                                                    placeholder="Select"
                                                    dataKey="value"
                                                />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="noOfRows">
                                                    {' '}
                                                    No.Of Rows <span className="red">*</span>
                                                </label>
                                                <InputNumber id="noOfRows" value={form.noOfRows} onValueChange={(e) => onInputChange('noOfRows', e.value)} min={1} max={100} />
                                            </div>
                                            {action === ACTIONS.ADD && (
                                                <div className="field">
                                                    <label htmlFor="noOfRacks">
                                                        No. of Racks <span className="red">*</span>
                                                    </label>
                                                    <InputNumber id="noOfRacks" value={form.noOfRacks} onValueChange={(e) => onInputChange('noOfRacks', e.value)} min={1} max={50} />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default RackPage;
