import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { LayoutContext } from '../layout/context/layoutcontext';
import { useAppContext } from '../layout/AppWrapper';
import { CustomResponse } from '../types';
import { PostCall } from '../api/ApiKit';
import { get } from 'lodash';
import { Button } from 'primereact/button';
import { hasAllKeys } from '../utils/uitl';
interface OptionProps {
    isVisible: boolean,
    closeIcon: any,
    url: string,
    fileInputColumns: string[];
    formContent?: any;
    formData?: any;
    requiredFormData?: any[]
}

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete'
};


const BulkUploader = ({ isVisible, closeIcon, url, fileInputColumns, formContent, formData, requiredFormData }: OptionProps) => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const fileInputRef = useRef<any>(null);

    useEffect(() => {
        if (isVisible) {
            setIsFileUpload(true)
        }
        else {
            setIsFileUpload(false)
        }
    }, [isVisible]);

    const handleDownload = () => {
        const data = fileInputColumns.join(',');
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "sample.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const handleFileSelect = (event: any) => {
        const filesArray = Array.from(event.target.files || []);
        if (filesArray.length > 0) {
            setSelectedFile(filesArray[0]);
        }
        else {
            setSelectedFile(null);
        }
        setTimeout(() => {
            event.target.value = "";
        }, 100);
    }

    const uploadFile = async () => {
        if (isLoading) return;
        if (!selectedFile) {
            setAlert('error', 'Please upload data file');
            return;
        }
        if (requiredFormData && requiredFormData?.length > 0) {
            let isValid = hasAllKeys(formData, requiredFormData);
            if (!isValid) {
                setAlert('error', 'Please provide all mandatory fields');
                return;
            }
        }
        const _formData = new FormData();
        _formData.append('file', selectedFile);
        Object.entries(formData).forEach(([key, value]: any) => {
            _formData.append(key, value);
        });

        setLoading(true);
        const response: CustomResponse = await PostCall(url, _formData);
        if (response.code == 'SUCCESS') {
            closeUploader(true);
        } else {
            setAlert('error', response.message)
        }
        setLoading(false);
    }

    const closeUploader = (isLoad = false) => {
        setIsFileUpload(false)
        setSelectedFile(null);
        closeIcon(isLoad)
    }

    return (
        <>
            <Dialog
                visible={isFileUpload}
                header={`Bulk Upload`}
                position={'top'}
                draggable={false}
                style={{ width: '50vw' }}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                onHide={() => setIsFileUpload(false)}
                showHeader={false}
                footer={<div className="flex p-2 justify-content-between">
                    <Button icon="pi pi-download" text label="Download Sample" onClick={handleDownload}></Button>
                    <div className="flex justify-content-between">
                        <div>
                            <Button text label="Cancel" size={'small'} onClick={() => closeUploader()}></Button>
                        </div>
                        <div>
                            <Button icon={isLoading ? 'pi pi-spinner pi-spin' : undefined} label="Upload" size={'small'} onClick={uploadFile}></Button>
                        </div>
                    </div>
                </div>}
                contentClassName="p-0 m-0 border-round-top"
            >
                <div className="flex justify-content-center bg-blue-500 p-3">
                    <p className="text-white font-bold">Bulk upload</p>
                </div>
                <div className="p-3">
                    {/* <div className="flex absolute w-full h-20rem justify-content-center align-items-center">
                                <div>
                                    <i className="pi pi-spinner pi-spin" style={{ fontSize: '2rem' }}></i>
                                </div>
                            </div> */}
                    <div className="surface-100 py-2 border-round">
                        <ul>
                            <li className="mb-2">Download the sample file and fill it with proper data</li>
                            <li>Once you have downloaded & filled the data, upload it</li>
                        </ul>
                    </div>
                    {formContent}
                    <input ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} accept=".xls,.xlsx,.xlsm,.csv" type="file" id="files" name="files" multiple />
                    <div className="drop-field flex align-items-center flex-column mt-3 border-1 p-5 surface-border border-round">
                        <div className='align-items-center text-center'>
                            <Button outlined size="small" onClick={() => {
                                fileInputRef.current?.click();
                            }}>Upload file</Button>
                            <br />
                            <p>{selectedFile?.name}</p>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default BulkUploader;
