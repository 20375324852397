import { get } from 'lodash';
import { Toast } from 'primereact/toast';
import React, { useCallback } from 'react';
import { createContext, Suspense, useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter as navigate, Route, Routes, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { AppContextType, Company, CustomResponse } from '../types';
import { getAuthToken, getUserDetails, isTokenValid, removeAuthData, setUserDetails } from '../utils/cookies';
import eventEmitter from '../api/event';
import { GetCall } from '../api/ApiKit';
import { useDispatch } from 'react-redux';
import { setLocations } from '../redux/slices/location-slice';
import { setCategories } from '../redux/slices/category-slice';
import { constant } from '../utils/constant';
import { setPaymentTerms } from '../redux/slices/payment-terms-slice';
import { setPoStatus } from '../redux/slices/po-status.slice';
import { setGrades } from '../redux/slices/grades-slice';
import { setCarriers } from '../redux/slices/carriers-slice';
import { setBusinessTypes } from '../redux/slices/business-slice';
import { setIndustryTypes } from '../redux/slices/industry-slice';
import { setCarrierCountries } from '../redux/slices/carriers-country';

let axiosRef: string | null = null;

const defaultContext: AppContextType = {
    displayName: '',
    setDisplayName: () => { },
    user: null,
    setUser: () => { },
    company: null,
    setCompany: () => { },
    isLoading: true,
    setLoading: () => { },
    signOut: (force: any) => { },
    setAlert: () => { },
    authToken: null,
    setAuthToken: () => { },
    isScroll: true,
    setScroll: () => { },
    locationId: null,
    setWarehouse: () => { },
    selectedSubLocation: null,
    setSelectedSubLocation: () => { }
};
const AppContext = createContext(defaultContext);

const authRoutes = ['/login', '/reset-password', '/forgot-password'];

export const AppWrapper = React.memo(({ children }: any) => {
    const dispatch = useDispatch();
    const divContentRef = useRef<any>(null);
    const location = useLocation()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const [displayName, setDisplayName] = useState('');
    const [authToken, setAuthToken] = useState(getAuthToken());
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [sideMenu, setSideMenu] = useState([]);
    const [isScroll, setScroll] = useState(true);
    const [selectedSubLocation, setSelectedSubLocation] = useState<any>(null);
    const [locationId, setWarehouse] = useState<any>(0);

    const toastRef = useRef<any>(null);

    useEffect(() => {
        if (!isScroll) {
            scrollToTop();
        }
    }, [isScroll])

    useEffect(() => {
        if (company) {
            fetchExtraData(company);
        }
        if (locationId > 0) {
            localStorage.setItem('locationId', locationId)
        }
    }, [company, locationId])

    useEffect(() => {
        const isValid = isTokenValid(authToken);
        if (!isValid) {
            if (authRoutes.includes(location.pathname)) {
                return;
            }
            const returnUrl = `${location.pathname}?${searchParams.toString()}`
            navigate(`/login?returnUrl=${returnUrl}`);
        }
        else if (authToken && isValid && authRoutes.includes(location.pathname)) {
            const returnUrl = searchParams.get('returnUrl') || '/';
            navigate(returnUrl);
        }
    }, [authToken])

    useEffect(() => {
        setLoading(true);
        const userToken: string = getAuthToken();
        if (userToken) {
            setLoading(false);
            if (!isTokenValid(userToken)) {
                signOut()
                return;
            }
        }
        else {
            setLoading(false);
        }

        const userData = getUserDetails();
        if (userData) {
            try {
                setUser(userData)
            } catch (error) {

            }

            if (userData && userData.company) {
                try {
                    setCompany(userData.company)
                } catch (error) {

                }
            }
        }
        fetchData()

        eventEmitter.on('signOut', (data: any) => {
            console.log('Event received:');
            removeAuthData();
            signOut();
            setAlert('info', 'Session expired')
        });

    }, [])

    const fetchData = useCallback(async () => {
        const token = getAuthToken();
        const isValid = isTokenValid(token);
        if (!axiosRef && isValid) {
            axiosRef = 'ref';
            const result: CustomResponse = await GetCall('/auth/profile');
            axiosRef = null
            if (result.code == 'SUCCESS') {
                setUser(result.data);
                setCompany(result.data.company)
                setUserDetails(result.data);
                let _user = result.data;
                if (!get(_user, 'isSuperAdmin', false) && !get(_user, 'isAdmin', false)) {
                    if (get(_user, 'locations', []).length > 0) {
                        setWarehouse(get(_user, 'locations.0.locationId', 0));
                    }
                    else {
                        setWarehouse(0);
                    }
                }
            }
            else if (result.code == 'AUTH_FAILED') {
                setUser(null)
                if (token) {
                    setAlert('error', 'Session expired')
                }
            }
            else {
                if (token) {
                    setAlert('error', result.message)
                }
            }
        }
        else if (!isValid) {
            if (token) {
                removeAuthData();
                setAlert('error', 'Session expired')
            }
        }
    }, []);

    const fetchExtraData = async (company: Company) => {
        if (company && get(company, 'companyId')) {
            fetchWarehouse(company)
            fetchCategories(company)
            fetchPaymentTerms(company)
            fetchCarriers(company)
            fetchPOStatus(company)
            fetchGrades(company)
            fetchAllCarriers(company);
            fetchBusinessType(company);
            fetchIndustryType(company);
            fetchCountryCarriers(company);
        }
    }

    const fetchWarehouse = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/locations`);
            if (response.code == 'SUCCESS') {
                dispatch(setLocations(response.data));
            } else {
                dispatch(setLocations([]));
            }
            setLoading(false);
        }
    }

    const fetchCategories = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/categories`);
            if (response.code == 'SUCCESS') {
                dispatch(setCategories(response.data));
            } else {
                dispatch(setCategories([]))
            }
            setLoading(false);
        }
    }

    const fetchPaymentTerms = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.PaymentTerms}`);
            if (response.code == 'SUCCESS') {
                dispatch(setPaymentTerms(response.data));
            } else {
                dispatch(setPaymentTerms([]))
            }
            setLoading(false);
        }
    }

    const fetchCarriers = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.shippingCarrier}`);
            if (response.code == 'SUCCESS') {
                dispatch(setCarriers(response.data));
            } else {
                dispatch(setCarriers([]))
            }
            setLoading(false);
        }
    };

    const fetchPOStatus = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.poStatus}`);
            if (response.code == 'SUCCESS') {
                dispatch(setPoStatus(response.data));
            } else {
                dispatch(setPoStatus([]))
            }
            setLoading(false);
        }
    };

    const fetchGrades = async (company: Company) => {
        if (company) {
            setLoading(true);
            const companyId = get(company, 'companyId');
            const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.grades}`);
            if (response.code == 'SUCCESS') {
                dispatch(setGrades(response.data));
            } else {
                dispatch(setGrades([]))
            }
            setLoading(false);
        }
    };

    const fetchCountryCarriers = async (company: Company) => {
        setLoading(true);
        const companyId = get(company, 'companyId');
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.CarrierCountry}`);
        if (response.code == 'SUCCESS') {
            dispatch(setCarrierCountries(response.data));
        } else {
            dispatch(setCarrierCountries([]))
        }
        setLoading(false);
    };

    const fetchAllCarriers = async (company: Company) => {
      
    };
    const fetchBusinessType = async (company: Company) => {
        setLoading(true);
        const companyId = get(company, 'companyId');
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.businessType}`);
        if (response.code == 'SUCCESS') {
            dispatch(setBusinessTypes(response.data));
        } else {
            dispatch(setBusinessTypes([]))
        }
        setLoading(false);
    };
    const fetchIndustryType = async (company: Company) => {
        setLoading(true);
        const companyId = get(company, 'companyId');
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.industryType}`);
        if (response.code == 'SUCCESS') {
            dispatch(setIndustryTypes(response.data));
        } else {
            dispatch(setIndustryTypes([]))
        }
        setLoading(false);
    };


    const signOut = async (force = false) => {
        await removeAuthData();
        setUser(null)
        const returnUrl = `${location.pathname}?${searchParams.toString()}`
        if (force) {
            navigate(`/login`);
        }
        else {
            navigate(`/login?returnUrl=${returnUrl}`, undefined);
        }
    }

    const setAlert = (type: string, message: string) => {
        if (toastRef.current) {
            toastRef.current.clear(); // Clear existing toast
        }

        toastRef.current.show({ severity: type, summary: type.toUpperCase(), detail: message, life: 3000 });
    }

    const scrollToTop = () => {
        if (divContentRef.current) {
            divContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <AppContext.Provider value={{
            displayName,
            setDisplayName,
            user, setUser,
            company, setCompany,
            authToken, setAuthToken,
            isLoading, setLoading,
            signOut,
            setAlert,
            isScroll,
            setScroll,
            locationId, setWarehouse,
            selectedSubLocation, setSelectedSubLocation
        }}>
            <Toast className='erp-alert' ref={toastRef} />
            {isLoading && <div className='running-border'></div>}
            <div ref={divContentRef} style={{ overflow: isScroll ? 'auto' : 'hidden', maxHeight: '100vh', backgroundColor: '#eff3f8' }}>
                {children}
            </div>
        </AppContext.Provider>
    );
})

export function useAppContext() {
    return useContext(AppContext);
}