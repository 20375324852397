import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanySubLocation } from "../../types";

interface SublocationState {
    sublocations: CompanySubLocation[];
}

const initialState: SublocationState = {
    sublocations: [],
};

const sublocationSlice = createSlice({
    name: "sublocations",
    initialState,
    reducers: {
        setSublocations: (state, action: PayloadAction<CompanySubLocation[]>) => {
            state.sublocations = action.payload;
        },
        addSublocation: (state, action: PayloadAction<CompanySubLocation>) => {
            state.sublocations.push(action.payload);
        },
    },
});

export const { setSublocations, addSublocation } = sublocationSlice.actions;
export default sublocationSlice.reducer;
