
import React, { useContext } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { useAppContext } from '../layout/AppWrapper';
import { LayoutContext } from '../layout/context/layoutcontext';
import { get } from 'lodash';
import Preloader from '../components/Preloader';

interface AppPageProps {
    title?: string,
    isAppBar?: any,
    isLogo?: any,
    content?: any,
    children?: any,
    containerClass?: string,
    full?: any;
}

export default function AppPage({ title = '', full = false, isAppBar = true, isLogo = false, content, containerClass = 'p-4', children = <></> }: AppPageProps) {
    const { layoutState } = useContext(LayoutContext);
    const { user, isLoading, locationId } = useAppContext();

    if (!user) {
        return <Preloader/>
    }

    if (!get(user, 'isSuperAdmin', false) && !get(user, 'isAdmin', false) && locationId == 0) {
        return <Preloader/>
    }

    return <>
        {children}
    </>
}
