import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface PaymentTermsState {
    paymentTerms: MasterCode[];
}

const initialState: PaymentTermsState = {
    paymentTerms: [],
};

const paymentTermsSlice = createSlice({
    name: "paymentTerms",
    initialState,
    reducers: {
        setPaymentTerms: (state, action: PayloadAction<MasterCode[]>) => {
            state.paymentTerms = action.payload;
        }
    },
});

export const { setPaymentTerms } = paymentTermsSlice.actions;
export default paymentTermsSlice.reducer;
