


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { CustomResponse, TreeNode } from '../../../types';
import { GetCall, PostCall } from '../../../api/ApiKit';
import { debounce, flatMapDeep, get, mapKeys } from 'lodash';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';

const ObjectAllocationPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [source, setSource] = useState<string>('');
    const [destination, setDestination] = useState<string>('');
    const [nodes, setNodes] = useState<any[]>([]);
    const [companies, setCompanies] = useState<any[]>([]);
    const [expandedKeys, setExpandedKeys] = useState({});

    useEffect(() => {
        setScroll(false);
        return () => {
            setScroll(true);
        };
    }, [])

    useEffect(() => {
        setCompanies([]);
        setNodes([])
    }, [source, destination])

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            console.log("Scanned Value:", event.target.value);
            // fetchBarcodeDetails(event.target.value)
            if (source && destination) {
                onSave()
            }
        }
    };

    const onSave = async () => {
        if (source && destination) {
            setLoading(true);
            const companyId = get(user, 'company.companyId');
            const data = {
                sourceId: source,
                targetId: destination
            }
            const response: CustomResponse = await PostCall(`/company/${companyId}/object-allocation`, data);
            if (response.code == 'SUCCESS') {
                setAlert('success', response.message);
                setSource('')
                // setDestination('');
                fetchData(destination);
            } else {
                setAlert('error', response.message);
            }
            setLoading(false);
        }
        else {
            setAlert('error', 'Please provide valid source & destination code');
        }
    }

    const fetchData = async (barcode: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/barcode/${barcode}`);

        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies([response.data]);
        } else {
            setCompanies([]);
        }
    };

    // copy paste from old code : need to refactor this
    const processCompanies = (companies: any[], barcodeType: string) => {
        return companies
            .filter((company: { barcodeType: any; }) => company.barcodeType === barcodeType)
            .map((company: { skuId: any; racks: any[]; subLocation: { skuId: any; }; rows: any[]; rack: { subLocation: { skuId: any; }; skuId: any; bins: any[]; }; row: { skuId: any; }; binNumber: any; items: any[]; }, companyIndex: any) => {
                const generateItemNodes = (items: any[], prefix: string) =>
                    items?.map((item: any, itemIndex: any) => {
                        return {
                            key: `${prefix}-${itemIndex}`,
                            data: {
                                name: `Item: ${item.REID?.replace('REID', '').trim() || 'N/A'}`,
                                imei: item.IMEI || 'N/A',
                                skuId: get(item, 'product.skuId', 'N/A'),
                                grade: get(item, 'product.grade', 'N/A'),
                                vendor: get(item, 'product.poItems.0.po.0.vendor.name', 'N/A'),
                                poNumber: get(item, 'item.product.poItems.0.po.0.PoNumber', 'N/A'),
                                poDate: get(item, 'item.product.poItems.0.po.0.poDate', 'N/A'),
                                cost: get(item, 'product.price', 'N/A')
                            },
                        }
                    });
                switch (barcodeType) {
                    case 'SUB_LOCATION':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.skuId}(${company.racks?.length || 0})`,
                                racksCount: company.racks?.length || 0,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: company.racks?.map((rack: { skuId: any; rows: any[]; }, rackIndex: any) => ({
                                key: `${companyIndex}-${rackIndex}`,
                                data: {
                                    name: `Rack: ${rack.skuId}(${rack.rows?.length || 0})`,
                                    size: '150KB',
                                    type: 'Folder',
                                },
                                children: rack.rows?.map((row: { skuId: any; bins: any[]; }, rowIndex: any) => ({
                                    key: `${companyIndex}-${rackIndex}-${rowIndex}`,
                                    data: {
                                        name: `Row: ${row.skuId}(${row.bins?.length || 0})`,
                                        size: '100KB',
                                        type: 'Folder',
                                    },
                                    children: row.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                        key: `${companyIndex}-${rackIndex}-${rowIndex}-${binIndex}`,
                                        data: {
                                            name: `Bin: ${bin.binNumber}(${bin.items?.length || 0})`,
                                            size: '75KB',
                                            type: 'Folder',
                                        },
                                        children: generateItemNodes(bin.items, `${companyIndex}-${rackIndex}-${rowIndex}-${binIndex}`),
                                    })),
                                })),
                            })),
                        };
                    case 'RACK':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.subLocation?.skuId || 'N/A'}(${company.racks?.length || 1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.skuId}(${company.rows?.length || 0})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: company.rows?.map((row: { skuId: any; bins: any[]; }, rowIndex: any) => ({
                                        key: `${companyIndex}-rack-${rowIndex}`,
                                        data: {
                                            name: `Row: ${row.skuId}(${row.bins?.length || 0})`,
                                            size: '100KB',
                                            type: 'Folder',
                                        },
                                        children: row.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                            key: `${companyIndex}-rack-${rowIndex}-${binIndex}`,
                                            data: {
                                                name: `Bin: ${bin.binNumber}(${bin.items?.length || 0})`,
                                                size: '75KB',
                                                type: 'Folder',
                                            },
                                            children: generateItemNodes(bin.items, `${companyIndex}-${rowIndex}-${binIndex}`),
                                        })),
                                    })),
                                },
                            ],
                        };
                    case 'RCKROW':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.rack?.subLocation?.skuId || 'N/A'}(${1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.rack?.skuId || 'N/A'}(${1})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: [
                                        {
                                            key: `${companyIndex}-rack-row`,
                                            data: {
                                                name: `Row: ${company.skuId || 'N/A'}(${company.rack?.bins?.length || 0})`,
                                                size: '100KB',
                                                type: 'Folder',
                                            },
                                            children: company.rack?.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                                key: `${companyIndex}-rack-row-${binIndex}`,
                                                data: {
                                                    name: `Bin: ${bin.binNumber || 'N/A'}(${bin.items?.length || 0})`,
                                                    size: '75KB',
                                                    type: 'Folder',
                                                },
                                                children: generateItemNodes(bin.items, `${companyIndex}-${binIndex}`),
                                            })),
                                        },
                                    ],
                                },
                            ],
                        };
                    case 'BIN':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.subLocation?.skuId || 'N/A'}(${1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.rack?.skuId || 'N/A'}(${1})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: [
                                        {
                                            key: `${companyIndex}-rack-row`,
                                            data: {
                                                name: `Row: ${company.row?.skuId || 'N/A'}(${1})`,
                                                size: '100KB',
                                                type: 'Folder',
                                            },
                                            children: [
                                                {
                                                    key: `${companyIndex}-rack-row-bin`,
                                                    data: {
                                                        name: `Bin: ${company.binNumber || 'N/A'}(${company.items?.length || 0})`,
                                                        size: '75KB',
                                                        type: 'Folder',
                                                    },
                                                    children: generateItemNodes(company.items || [], `${companyIndex}-bin`),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        };
                    default:
                        return null;
                }
            });
    };

    useEffect(() => {
        if (companies.length > 0) {
            const barcodeTypes = ['SUB_LOCATION', 'RACK', 'RCKROW', 'BIN'];
            const treeNodes = barcodeTypes.flatMap((type) => processCompanies(companies, type));
            setNodes(treeNodes);
        }
    }, [companies]);

    const extractIds: any = (nodes: any) => { return flatMapDeep(nodes, (node) => [node.key, node.children ? extractIds(node.children) : []]); }

    // auto expand all
    useEffect(() => {
        if (nodes.length > 0) {
            const keys = extractIds(nodes);
            setExpandedKeys(keys.reduce((acc: any, key: any) => { acc[key] = true; return acc; }, {}))
        }
        else {
            setExpandedKeys([])
        }
    }, [nodes])

    const [tableHeight, setTableHeight] = useState('30rem');
    const calculateTableHeight = () => {
        const headerHeight = 340;
        const availableHeight = window.innerHeight - headerHeight;
        setTableHeight(`${availableHeight}px`);
    };
    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);
        return () => {
            window.removeEventListener('resize', calculateTableHeight);
        };
    }, []);

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <div className="flex justify-content-between p-4">
                                <span className="p-input-icon-left flex align-items-center">
                                    <h4 className="mb-0">Object Allocation</h4>
                                </span>
                            </div>
                            <div className='px-4'>
                                <div className="flex grid gap-3">
                                    <div className='col-5'>
                                        <label htmlFor="name3" className="w-full ">
                                            Destination<span className='text-red'>*</span>
                                        </label>
                                        <IconField className='mt-2'>
                                            <InputIcon className="pi pi-qrcode"> </InputIcon>
                                            <InputText className='w-full' onChange={(e) => setDestination(e.target.value)} onKeyDown={handleKeyPress} />
                                        </IconField>
                                    </div>
                                    <div className='flex align-items-center'>
                                        <i className='pi pi-arrow-circle-left text-xl'></i>
                                    </div>
                                    <div className='col-5'>
                                        <label htmlFor="name3" className="w-full">
                                            Source<span className='text-red'>*</span>
                                        </label>
                                        <IconField className='mt-2'>
                                            <InputIcon className="pi pi-qrcode"> </InputIcon>
                                            <InputText autoFocus className='w-full' onChange={(e) => setSource(e.target.value)} onKeyDown={handleKeyPress} />
                                        </IconField>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                {
                                    nodes.length > 0 && destination && <TreeTable
                                        scrollable
                                        value={nodes as TreeNode[]}
                                        selectionMode="single"
                                        rows={10}
                                        expandedKeys={expandedKeys}
                                        onToggle={(e) => setExpandedKeys(e.value)}
                                        className="erp-table"
                                        paginator={true}
                                        scrollHeight={tableHeight}
                                        style={{ width: '100%' }}
                                    >
                                        <Column field="name" header="LPN" expander style={{ width: 270 }}></Column>
                                        <Column field="imei" header="IMEI"></Column>
                                        <Column field="skuId" header="SKU"></Column>
                                        <Column field="grade" header="Grade"></Column>
                                        <Column field="vendor" header="Vendor"></Column>
                                        <Column field="poNumber" header="PO Number"></Column>
                                        <Column field="poDate" header="PO Date"></Column>
                                        <Column field="cost" header="Cost"></Column>
                                    </TreeTable>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default ObjectAllocationPage;
