


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { buildQueryParams, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { CustomResponse, ProductItem } from '../../../types';
import { get } from 'lodash';
import { GetCall } from '../../../api/ApiKit';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import MemoizedPieChart from '../../../components/Charts/PieChart';
import { useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';

const chartOptions = {
    plugins: {
        legend: {
            display: false,
            labels: {
                usePointStyle: true
            }
        }
    }
};

const ChatUniqId = {
    locId: 'LocDataChart',
    catId: 'CatDataChart'
}

const ProductItemsPage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null)
    const [productItems, setProductItems] = useState<ProductItem[]>([])

    const [productCountData, setProductCountData] = useState<any>({})

    const [catChartData, setCatChartData] = useState({});
    const [locChartData, setLocChartData] = useState({});

    useEffect(() => {
        fetchData()
        fetchProductCounts();
        fetchProductCatCounts();
        fetchProductLocCounts();
        return () => {
            setScroll(true);
        };
    }, []);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        if (params.filters) {
            params.filters['type'] = 'Product';
        }
        else {
            params.filters = { type: 'Product' }
        }
        params.include = 'po,bin,rack,location';
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/product-items-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setProductItems(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setProductItems([]);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchProductCounts = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/product-item-counts?type=Product`);
        if (response.code == 'SUCCESS') {
            setProductCountData(response.data);
        } else {
            setProductCountData({});
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchProductLocCounts = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/product-item-location-counts?type=Product`);
        if (response.code == 'SUCCESS') {
            const labels = response.data.map((item: any) => item.locationName);
            const values = response.data.map((item: any) => item.itemCount);
            const colors = response.data.map((item: any) => item.color);

            setLocChartData({
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors.map((color: any) => `${color}80`),
                    },
                ],
            });
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchProductCatCounts = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/product-item-category-counts?type=Product`);
        if (response.code == 'SUCCESS') {
            const labels = response.data.map((item: any) => item.name);
            const values = response.data.map((item: any) => item.itemCount);
            const colors = response.data.map((item: any) => item.color);

            setCatChartData({
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: colors,
                        hoverBackgroundColor: colors.map((color: any) => `${color}80`),
                    },
                ],
            });
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12 lg:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Devices</span>
                                <div className="text-900 font-medium text-xl">{get(productCountData, 'totalDevices', 0)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Device Purchased</span>
                                <div className="text-900 font-medium text-xl">{get(productCountData, 'totalPurchased', 0)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-arrow-down-left text-orange-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-4">
                    <div className="card mb-0">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">Total Device Sold</span>
                                <div className="text-900 font-medium text-xl">{get(productCountData, 'totalSold', 0)}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                <i className="pi pi-arrow-up-right text-cyan-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid py-4">
                <div className="col-6 xl:col-4">
                    <MemoizedPieChart key={ChatUniqId.locId} title="Location Wise" data={locChartData} options={chartOptions} />
                </div>
                <div className="col-6 xl:col-4">
                    <MemoizedPieChart key={ChatUniqId.catId} title="Category Wise" data={catChartData} options={chartOptions} />
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Products'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                data={productItems}
                                columns={[
                                    {
                                        header: 'LPN',
                                        field: 'REID',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { width: 100 },
                                        filterPlaceholder: 'LPN'
                                    },
                                    {
                                        header: 'SKU',
                                        field: 'productName',
                                        filter: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'PO Number',
                                        field: 'poNumber',
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        header: 'Price',
                                        field: 'purchasePrice',
                                    },
                                    {
                                        header: 'Bin',
                                        field: 'binNumber',
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        header: 'Rack',
                                        field: 'rackName',
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        header: 'Sub Location',
                                        field: 'locationName',
                                        sortable: true,
                                        filter: true,
                                    },
                                    {
                                        header: 'Age',
                                        field: 'createdAt',
                                        sortable: true,
                                        body: (item) => moment().diff(item.createdAt, 'days'),
                                    },
                                    {
                                        header: 'Category',
                                        field: 'categoryName',
                                        sortable: true,
                                        filter: true
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default ProductItemsPage;
