import { get, intersection } from 'lodash';
import { classNames } from 'primereact/utils';
import { useCallback, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    COMPANIES_MENU,
    COMPANY_MASTER_CODE_MENU,
    COMPANY_MENU,
    COMPANY_ROLE_MENU,
    COMPANY_USER_MENU,
    INVENTORY_BIN_MENU,
    INVENTORY_CATEGORY_MENU,
    INVENTORY_MENU,
    INVENTORY_RACK_MENU,
    INVENTORY_WAREHOUSE_MENU,
    PERMISSION_MENU,
    ROUTE_MENU,
    SALES_CUSTOMER_MENU,
    SALES_MENU,
    SALES_ORDER_MENU,
    SUPPLIER_CATELOGUE_MENU,
    SUPPLIER_MENU,
    SUPPLIER_WAREHOUSE_MENU
} from '../config/permissions';
import { AppMenuItem } from '../types';
import { getCompanyLogo } from '../utils/uitl';
import { useAppContext } from './AppWrapper';
import { LayoutContext } from './context/layoutcontext';
import { MenuProvider } from './context/menucontext';
import { Menu, MenuItem, MenuItemStyles, Sidebar, SubMenu } from 'react-pro-sidebar';

type Theme = 'light' | 'dark';
const theme: Theme = 'light';

const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#030712',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#030712',
            hover: {
                backgroundColor: '#1d5368',
                color: '#fff',
                icon: '#fff',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#5aa445',
            color: '#fff',
        },
        menu: {
            menuContent: '#1d5368',
            icon: '#fff',
            hover: {
                backgroundColor: '#163d4c',
                color: '#fff',
                icon: '#fff',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
}

const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const menuItemStyles: MenuItemStyles = {
    root: {
        fontSize: '13px',
        fontWeight: 600,
    },
    icon: {
        color: themes[theme].menu.icon,
        '&:hover': {
            color: themes[theme].menu.hover.color,
        },
        '&:active': {
            color: themes[theme].menu.hover.color,
        }
    },
    SubMenuExpandIcon: {
        color: themes[theme].menu.icon,
        '&:hover': {
            color: themes[theme].menu.hover.icon,
        },
        '&:active': {
            color: themes[theme].menu.hover.icon,
        }
    },
    subMenuContent: ({ level }) => ({
        backgroundColor:
            level === 0
                ? hexToRgba(themes[theme].menu.menuContent, 1)
                : 'transparent',
    }),
    button: {
        '&:hover': {
            backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
            color: themes[theme].menu.hover.color,
        },
        '&.active': {
            backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
            color: themes[theme].menu.hover.color,
        }
    },
    label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
    }),
};

const AppSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAppContext();
    const companyId = get(user, 'company.companyId');
    const { layoutConfig, layoutState, onMenuToggle } = useContext(LayoutContext);

    const model: AppMenuItem[] = [
        {
            label: '',
            icon: 'pi pi-fw pi-bookmark',
            items: [
                {
                    label: 'Inventory',
                    icon: 'pi pi-warehouse',
                    url: `/products`,
                    check: (user: any) => {
                        const checkComm = intersection(INVENTORY_MENU, get(user, 'permissions', []));
                        if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                            return true;
                        }
                        return false;
                    }
                },
                {
                    label: 'Companies',
                    icon: 'pi pi-building',
                    url: `/companies`,
                    check: (user: any) => {
                        const checkComm = intersection(COMPANIES_MENU, get(user, 'permissions', []));
                        if (get(user, 'isSuperAdmin') || checkComm.length > 0) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    label: 'Permissions',
                    icon: 'pi pi-lock',
                    check: (user: any) => {
                        const checkComm = intersection([...PERMISSION_MENU, ...ROUTE_MENU], get(user, 'permissions', []));
                        if (get(user, 'isSuperAdmin') || checkComm.length > 0) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Routes',
                            url: `/routes`,
                            check: (user: any) => {
                                const checkComm = intersection(ROUTE_MENU, get(user, 'permissions', []));
                                if (get(user, 'isSuperAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Permissions',
                            url: `/permissions`,
                            check: (user: any) => {
                                const checkComm = intersection(PERMISSION_MENU, get(user, 'permissions', []));
                                if (get(user, 'isSuperAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                },
                {
                    label: 'Supplier Management',
                    icon: 'pi pi-shopping-bag',
                    check: (user: any) => {
                        const checkComm = intersection(SUPPLIER_MENU, get(user, 'permissions', []));
                        if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Vendors',
                            url: `/vendors`,
                            check: (user: any) => {
                                const checkComm = intersection(SUPPLIER_CATELOGUE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Purchase Orders',
                            url: `/purchase-orders`,
                            check: (user: any) => {
                                const checkComm = intersection(SUPPLIER_WAREHOUSE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                },
                {
                    label: 'Operations',
                    icon: 'pi pi-desktop',
                    check: (user: any) => {
                        const checkComm = intersection(SUPPLIER_MENU, get(user, 'permissions', []));
                        if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Pallet Receiving',
                            url: `/pallet-receiving`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_WAREHOUSE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Receive Purchase Order',
                            url: `/receive-purchase-order`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_WAREHOUSE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Object Inquiry',
                            url: `/object-inquiry`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Object Allocation',
                            url: `/object-allocation`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Bin Grades',
                            url: `/grade-to-bin`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Device Report',
                            url: `/device-report`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                },
                {
                    label: 'Sales Activity',
                    icon: 'pi pi-shopping-cart',
                    check: (user: any) => {
                        const checkComm = intersection(SALES_MENU, get(user, 'permissions', []));
                        if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Customers',
                            url: `/customers`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_CUSTOMER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Estimates',
                            url: `/estimates`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_ORDER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Sales Orders',
                            url: `/sales-orders`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_ORDER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Packages',
                            url: `/packages`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_ORDER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Shipments',
                            url: `/shipments`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_ORDER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Sales Returns',
                            url: `/sales-returns`,
                            check: (user: any) => {
                                const checkComm = intersection(SALES_ORDER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                },
                {
                    label: 'Control Tower',
                    icon: 'pi pi-cog',
                    check: (user: any) => {
                        const checkComm = intersection(COMPANY_MENU, get(user, 'permissions', []));
                        if (get(user, 'isAdmin') || checkComm.length > 0) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Catalogues',
                            url: `/catalogues`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_BIN_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Master Codes',
                            url: `/master-codes`,
                            check: (user: any) => {
                                const checkComm = intersection(COMPANY_MASTER_CODE_MENU, get(user, 'permissions', []));
                                if (get(user, 'isAdmin') || get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Category',
                            url: `/categories`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_CATEGORY_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Category Mapping',
                            url: `/product-mapping`,
                            check: (user: any) => {
                                const checkComm = intersection(COMPANY_MASTER_CODE_MENU, get(user, 'permissions', []));
                                if (get(user, 'isAdmin') || get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Location',
                            url: `/locations`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_WAREHOUSE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Sub Locations',
                            url: `/sub-location`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_WAREHOUSE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Racks',
                            url: `/racks`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_RACK_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Bins',
                            url: `/bins`,
                            check: (user: any) => {
                                const checkComm = intersection(INVENTORY_BIN_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && (get(user, 'isAdmin') || checkComm.length > 0)) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                },
                {
                    label: 'IAM',
                    icon: 'pi pi-users',
                    check: (user: any) => {
                        const checkComm = intersection(COMPANY_MENU, get(user, 'permissions', []));
                        if (get(user, 'isAdmin') || checkComm.length > 0) {
                            return true;
                        }
                        return false;
                    },
                    items: [
                        {
                            label: 'Users',
                            url: `/users`,
                            check: (user: any) => {
                                const checkComm = intersection(COMPANY_USER_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        },
                        {
                            label: 'Roles',
                            url: `/roles`,
                            check: (user: any) => {
                                const checkComm = intersection(COMPANY_ROLE_MENU, get(user, 'permissions', []));
                                if (!get(user, 'isSuperAdmin') && get(user, 'isAdmin') || checkComm.length > 0) {
                                    return true;
                                }
                                return false;
                            },
                        }
                    ]
                }
            ]
        }
    ];

    const getRootPath = useCallback((menu: AppMenuItem[], currentPath: string): string | null => {
        for (const item of menu) {
            if (item.url === currentPath) {
                return item.label;
            }
            if (item.items) {
                const found = getRootPath(item.items, currentPath);
                if (found) {
                    return item.label;
                }
            }
        }
        return null;
    }, []);

    const rootPath = getRootPath(model[0].items as any[], location.pathname);
    const itemClick = ({ originalEvent, item }: { originalEvent: any, item: AppMenuItem }) => {
        if (item.url) {
            navigate(item.url);
        }
    }

    const iconClass = classNames('pi', {
        'pi-angle-left text-lg p-3': !layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
        'pi-angle-right text-lg p-3': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static'
    });
    return (
        <MenuProvider>
            <div className={`layout-sidebar sidebar-theme-${theme}`}>
                <div className='flex h-5rem border-bottom-1 border-light'>
                    <Link to={'/'} className="flex align-items-center justify-content-center layout-topbar-logo w-full">
                        <img src={getCompanyLogo(user?.company?.logo)} width="90%" height={'35px'} alt="logo" className={layoutState.isMobile ? 'mobile-sidebar-logo-img logo' : 'logo'} />
                    </Link>
                </div>

                <div className="min-h-screen flex relative lg:static">
                    <div id="app-sidebar-2" className="h-screen block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none" style={{ width: !layoutState.isMobile && layoutState.staticMenuDesktopInactive ? 80 : 250 }}>
                        <div className="flex flex-column" style={{ height: '92%' }}>
                            <div className="overflow-y-auto">
                                <Sidebar
                                    collapsed={!layoutState.isMobile && layoutState.staticMenuDesktopInactive}
                                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                                    rootStyles={{
                                        color: themes[theme].sidebar.color,
                                        border: 'none',
                                        marginTop: layoutState.isMobile ? 20 : 0
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <div style={{ flex: 1, marginBottom: '32px' }}>
                                            <Menu menuItemStyles={menuItemStyles} closeOnClick={true}>
                                                {get(model, '0.items', []).map((item, i) => {
                                                    if (!item) return null;

                                                    if (item.check && !item.check(user)) {
                                                        return null;
                                                    }

                                                    if (item.items && item.items.length > 0) {
                                                        return <SubMenu
                                                            key={`SubMenu${i}`}
                                                            label={item.label}
                                                            title={item.label}
                                                            icon={<i className={`${item.icon} ${layoutState.staticMenuDesktopInactive ? 'only-icon' : ''}`}></i>}
                                                        >
                                                            {
                                                                item.items.map((subMenu: AppMenuItem) => {
                                                                    return <MenuItem title={item.label} onClick={(event: any) => itemClick({ originalEvent: event, item: subMenu })}>{subMenu.label}</MenuItem>
                                                                })
                                                            }
                                                        </SubMenu>
                                                    }
                                                    else {
                                                        return <MenuItem title={item.label} icon={<i className={`${item.icon} ${layoutState.staticMenuDesktopInactive ? 'only-icon' : ''}`}></i>} onClick={(event: any) => itemClick({ originalEvent: event, item })}>{item.label}</MenuItem>
                                                    }
                                                })}
                                            </Menu>
                                        </div>
                                    </div>
                                </Sidebar>
                            </div>
                            {!layoutState.isMobile && (
                                <div className="mt-auto">
                                    <div
                                        v-ripple="true"
                                        onClick={onMenuToggle}
                                        className="flex mb-1 justify-content-center align-items-center cursor-pointer p-2 border-top-1 border-light text-black"
                                        style={{ width: layoutState.staticMenuDesktopInactive ? 80 : 250 }}
                                    >
                                        <i className={iconClass}></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </MenuProvider>
    );
};

export default AppSidebar;
