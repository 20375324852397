import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface PoStatusState {
    poStatus: MasterCode[];
}

const initialState: PoStatusState = {
    poStatus: [],
};

const poStatusSlice = createSlice({
    name: "poStatus",
    initialState,
    reducers: {
        setPoStatus: (state, action: PayloadAction<MasterCode[]>) => {
            state.poStatus = action.payload;
        }
    },
});

export const { setPoStatus } = poStatusSlice.actions;
export default poStatusSlice.reducer;
