
import React, { memo, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import Sidebar from '../Sidebar';
import { CustomResponse, Product } from '../../types';
import { get, set, uniqBy } from 'lodash';
import { useAppContext } from '../../layout/AppWrapper';
import { PostCall } from '../../api/ApiKit';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { ACTIONS } from '../../utils/constant';
import { InputNumber } from 'primereact/inputnumber';
import ERPMultiSelect from '../ERPMultiSelect';

interface OptionProps {
    title?: string,
    vendorId: any,
    isVisible: boolean,
    onClose: (isLoad: boolean) => void
}

const VendorPartMapping = memo(({ title = 'New Package', vendorId, isVisible = false, onClose = (isLoad) => { } }: OptionProps) => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const companyId = get(user, 'company.companyId');

    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true)
            setData([])
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);

    const closeIcon = () => {
        onClose(false);
    }

    const onSave = async () => {
        const companyId = get(user, 'company.companyId');
        if (data.length == 0) {
            return;
        }
        let payload = data.map((item) => ({
            productId: item.productId,
            vendorId: item.vendorId,
            unitPrice: item.unitPrice || 0,
            packQuantity: item.packQuantity || 1
        }))
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${companyId}/vendors/${vendorId}/products`, payload);
        if (response.code == 'SUCCESS') {
            onClose(true);
        } else {
            setAlert('error', response.message)
        }
        setLoading(false);
    }

    const updateItem = async (key: any, value: any) => {
        let _items = [...data];
        set(_items, key, value);
        setData(_items);
    }

    const removeItem = (key: string, value: any) => {
        if (!value) return;
        let _items: any[] = [...data];
        _items = _items.filter((item) => item[key] != value);
        setData(_items);
    };

    const onSelectValues = (values: any[]) => {
        setData((prev) => [...prev, ...values.map((item: any) => ({
            productId: item.productId,
            product: item,
            unitPrice: 0,
            packQuantity: 1
        }))])
    }

    const renderProduct = (item: any) => {
        return <div>
            <small>{get(item, 'product.type')}</small>
            <p className='text-900 mb-0'>{get(item, 'product.name')}</p><br />
        </div>
    }

    const renderCross = (item: any) => {
        if (!item?.productId) {
            return <div style={{ width: 80 }}></div>;
        }
        return (
            <div className="flex align-items-center">
                <Button icon="pi pi-times" severity="danger" aria-label="Cancel" size="small" style={{ width: 'unset', padding: '0.5rem 0.6rem' }} onClick={() => removeItem('productId', item.productId)} />
            </div>
        );
    };

    const renderPrice = (item: any, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <div style={{ width: 120 }}></div>;
        }
        return <InputNumber value={item.unitPrice} inputStyle={{ width: 120, textAlign: 'right' }} onChange={(e) => updateItem(`${option.rowIndex}.unitPrice`, e.value)} placeholder='Price' className='w-full' />
    }

    const renderQnt = (item: any, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <div style={{ width: 60 }}></div>;
        }
        return <InputNumber value={item.packQuantity || 1} inputStyle={{ width: 60, textAlign: 'right' }} onChange={(e) => updateItem(`${option.rowIndex}.packQuantity`, e.value)} placeholder='Qnt' className='w-full' />
    }

    return (
        <Sidebar
            isVisible={dialogVisible}
            action={ACTIONS.EDIT}
            title={'Supplied Products'}
            width={'60vw'}
            closeIcon={closeIcon}
            onSave={onSave}
            content={<>
                <div className="mt-4">
                    <div className='mb-2'>
                        <ERPMultiSelect
                            url={`/company/${companyId}/products`}
                            multiple
                            placeholder='Select products'
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            autoClear
                            onSelect={onSelectValues}
                        />
                    </div>
                    <DataTable
                        scrollable={data.length > 4 ? true : false}
                        scrollHeight={'450px'}
                        showGridlines
                        virtualScrollerOptions={{ itemSize: 50, delay: 0 }}
                        value={uniqBy(data, 'productId')}
                        dataKey="productId"
                        className='table-line-item'
                        emptyMessage={'No items added'}
                        footer={<>Total: {data.length} <small style={{ fontWeight: 'normal' }}>(use max 50 items for better performance)</small></>}
                    >
                        <Column header="Products" body={renderProduct} style={{ padding: '0 0 0 10px' }}></Column>
                        <Column header="Unit Price" style={{ width: 50 }} body={renderPrice}></Column>
                        <Column header="Qnt" style={{ width: 50 }} body={renderQnt}></Column>
                        <Column style={{ width: 30 }} body={renderCross}></Column>
                    </DataTable>
                </div>
            </>}
        />
    )
})

export default VendorPartMapping; 