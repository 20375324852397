import { classNames } from 'primereact/utils';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { LayoutContext } from './context/layoutcontext';
import { InputText } from "primereact/inputtext";
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useAppContext } from './AppWrapper';
import { get } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { AppTopbarRef } from '../types';
import { getCompanyLogo } from '../utils/uitl';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import { ReduxStoreState } from '../redux/redux-store';

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {
    const { setAlert, setLoading, signOut, user, locationId, setWarehouse } = useAppContext();
    const navigate = useNavigate();
    const companyId = get(user, 'company.companyId');
    const { layoutConfig, layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    const locations = useSelector((state: ReduxStoreState) => state.locations.locations);

    const [visible, setVisible] = useState<boolean>(false);
    const menu = useRef<any>(null);
    const items = [
        {
            template: (item: any, options: any) => {
                return (
                    <div className="p-menuitem cursor-pointer" style={{ alignItems: 'center', padding: 10 }}>
                        <div style={{ marginLeft: 10 }}>
                            <span style={{ fontWeight: 'bold' }}>{get(user, 'displayName', 'U')}</span>
                            <br></br>
                            <span style={{ color: 'gray', maxWidth: 150, display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>{get(user, 'email')}</span>
                        </div>
                    </div>
                );
            }
        },
        {
            label: 'Profile',
            icon: 'pi pi-user',
            command: () => navigate('/profile')
        },
        {
            label: 'Settings',
            icon: 'pi pi-cog',
            command: () => navigate('/settings')
        },
        {
            separator: true
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => setVisible(true)
        }
    ];
    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));

    const accept = () => {
        signOut(true);
    }

    const avatrClick = (e: any) => {
        if (menu) {
            menu.current.toggle(e)
        }
    }

    const onHide = () => setVisible(false)

    return (
        <div className="layout-topbar" style={{ left: layoutState.isMobile ? '0px' : (!layoutState.staticMenuDesktopInactive ? '250px' : '80px') }}>
            <div className='flex'>
                {
                    layoutState.isMobile && <>
                        <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
                            <i className="pi pi-bars" />
                        </button>
                    </>
                }

                {
                    !get(user, 'isSuperAdmin') && !get(user, 'isAdmin') && <div className={`flex align-items-center ${!layoutState.isMobile ? 'hr-left' : ''}`} style={{ order: layoutState.isMobile ? 2 : undefined }}>
                        <Dropdown
                            placeholder='Select Location'
                            className='ml-3'
                            value={locationId}
                            optionLabel='name'
                            optionValue='locationId'
                            options={locations || []}
                            onChange={(e) => setWarehouse(e.value)}
                            style={{ maxWidth: 200 }}
                        />
                    </div>
                }
            </div>


            <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
                <button type="button" className="p-link layout-topbar-button profile-icon-setting">
                    <Menu model={items} popup ref={menu} />
                    <Avatar label={get(user, 'displayName') ? get(user, 'displayName')[0] : 'U'} style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" onClick={avatrClick} />
                </button>
            </div>

            <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={avatrClick}>
                <Menu model={items} popup ref={menu} />
                <Avatar label={get(user, 'displayName') ? get(user, 'displayName')[0] : 'U'} style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" onClick={avatrClick} />
            </button>

            {/* logout confim  */}
            <ConfirmDialog className='custom-dialog' visible={visible} onHide={onHide} message="Are you sure you want to logout?"
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} />

        </div>
    );
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;
