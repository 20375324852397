import { useContext, useEffect, useState } from "react";
import AppPage from "../../../layout/AppPage"
import { useAppContext } from "../../../layout/AppWrapper";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import { InputText } from "primereact/inputtext";
import { get } from "lodash";
import { CustomResponse } from "../../../types";
import { GetCall, PostCall } from "../../../api/ApiKit";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import Sidebar from "../../../components/Sidebar";

const DeviceReportPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);

    const [barcode, setBarcode] = useState('');
    const [reportTypes, setReportTypes] = useState([]);
    const [reportType, setReportType] = useState('');
    const [data, setData] = useState<any>(null);
    const [isBinShow, setIsBinShow] = useState<boolean>(false);
    const [destination, setDestination] = useState<string>('');

    useEffect(() => {
        setScroll(true);
        getReportTypes();
        return () => {
            setScroll(true);
        };
    }, []);

    const getReportTypes = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/report-types`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setReportTypes(response.data);
        } else {
            setReportTypes([]);
        }
    }

    const fetchData = async () => {
        if (!barcode) {
            setData(null);
            return;
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/reports/${barcode}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setData(response.data);
        } else {
            setAlert('error', response.message)
            setData(null);
        }
    };

    const updateData = async () => {
        if (!barcode || !reportType) {
            setData(null);
            return;
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${companyId}/reports/${barcode}?type=${reportType}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            fetchData();
        } else {
            setAlert('error', response.message);
        }
    };

    const downloadReport = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(get(data, 'data', []));
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, `${get(data, 'product.name', 'N/A')} Report(${get(data, 'REID', '')})`);
        });
    }

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };

    const onClose = () => {
        setIsBinShow(false)
    }

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            if (barcode && destination) {
                onSave()
            }
        }
    };

    const onSave = async () => {
        if (barcode && destination) {
            setLoading(true);
            const companyId = get(user, 'company.companyId');
            const data = {
                sourceId: barcode,
                targetId: destination
            }
            const response: CustomResponse = await PostCall(`/company/${companyId}/object-allocation`, data);
            if (response.code == 'SUCCESS') {
                setAlert('success', response.message);
            } else {
                setAlert('error', response.message);
            }
            setLoading(false);
        }
        else {
            setAlert('error', 'Please provide valid source & destination code');
        }
    }

    return <AppPage>
        <div className="grid">
            <div className="col-12">
                <div className={`panel-container`}>
                    <div className="left-panel">
                        <div className="card erp-table-container">
                            <div style={{ width: '100%' }}>
                                <div className="p-4">
                                    <p className='text-xl font-bold'>Device Report</p>
                                    <div className="grid">
                                        <div className="col-12 md:col-3">
                                            <label>LPN</label>
                                            <IconField className="w-full mt-2">
                                                <InputIcon className="pi pi-qrcode"> </InputIcon>
                                                <InputText className="w-full" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
                                            </IconField>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label>Report Type</label>
                                            <Dropdown className="mt-2 w-full" value={reportType} options={reportTypes} onChange={(e) => setReportType(e.value)} placeholder="Select report type" />
                                        </div>
                                    </div>
                                    <div className="flex mt-2 gap-2">
                                        <Button size="small" label="Fetch Reports" disabled={!barcode} onClick={() => fetchData()}></Button>
                                        <Button size="small" label="Update Reports" disabled={!barcode || !reportType} onClick={() => updateData()}></Button>
                                        {
                                            get(data, 'pItem', null) && <Button size="small" label="Add to Bin" disabled={!barcode} onClick={() => setIsBinShow(true)}></Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            data && <div className="card erp-table-container mt-2 p-4">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <p className="text-lg font-bold">{get(data, 'product.name', 'N/A')}</p>
                                        <div className="flex gap-1">
                                            <Tag severity="success" value={get(data, 'type', 'N/A')}></Tag>
                                            <p className="text-lg">{get(data, 'REID', 'N/A')}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <Button size="small" label="Download Reports" disabled={!barcode} onClick={() => downloadReport()}></Button>
                                    </div>
                                </div>
                                <DataTable
                                    scrollable
                                    filterDisplay={'row'}
                                    value={get(data, 'data', [])}
                                    removableSort
                                >
                                    <Column header="Key" field="reportKey" filter sortable sortField="reportKey" ></Column>
                                    <Column header="Value" field="reportValue" filter></Column>
                                </DataTable>
                            </div>
                        }
                        <div className="h-2rem"></div>
                    </div>
                </div>
            </div>
        </div>
        <Sidebar
            isVisible={isBinShow}
            title="Add to Bin"
            width="40vw"
            closeIcon={onClose}
            onSave={onSave}
            content={<>
                <div className='col-12'>
                    <label htmlFor="name3" className="w-full ">
                        Bin<span className='text-red'>*</span>
                    </label>
                    <IconField className='mt-2'>
                        <InputIcon className="pi pi-qrcode"> </InputIcon>
                        <InputText className='w-full' onChange={(e) => setDestination(e.target.value)} onKeyDown={handleKeyPress} />
                    </IconField>
                </div>
            </>}
        />
    </AppPage>
}

export default DeviceReportPage;