import { ApiKey, BOM, Parts, Product, SalesReturns, Vendor } from ".";

export const DEFAULT_SALES_RETURNS: SalesReturns = {
    rmaId: null,
    rmaNumber: null,
    companyId: null,
    soId: null,
    vendorId: null,
    rmaDate: null,
    statusId: null,
    reasonId: null,
    trackingTypeId: null,
    trackingRef: null,
    items: []
};
export const DEFAULT_PPRODUCT: Product = {
    name: '',
    desc: '',
    categoryId: 0,
    companyId: 0,
    productId: 0,
    skuId: ""
}

export const DEFAULT_PARTS: Parts = {
    name: '',
    desc: ''
}

export const DEFAULT_BOM: BOM = {
    name: '',
    desc: '',
    unitPrice: undefined
}

export const DEFAULT_VENDOR: Vendor = {
    name: '',
    aliasName: '',
    companyName: '',
    phone: '',
    email: '',
    fax: '',
    website: '',
    isSupplier: true,
    locationIds: [],
    paymentTerms: [],
    categoryIds: [],
    note: '',
    paymentTermsId: null,
    vendorId: null,
    profile: null,
    countryCode: null,
    currency: null
};

export const DEFAULT_BULK_ORDER: any = {
    locationId: '',
    poDate: '',
    items: []
}

export const DEFAULT_API_KEY: ApiKey = {
    name: ''
}