import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface CarriersState {
    carriers: MasterCode[];
}

const initialState: CarriersState = {
    carriers: [],
};

const carriersSlice = createSlice({
    name: "carriers",
    initialState,
    reducers: {
        setCarriers: (state, action: PayloadAction<MasterCode[]>) => {
            state.carriers = action.payload;
        }
    },
});

export const { setCarriers } = carriersSlice.actions;
export default carriersSlice.reducer;
