import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface SliceState {
    businessTypes: MasterCode[];
}

const initialState: SliceState = {
    businessTypes: [],
};

const slice = createSlice({
    name: "businessTypes",
    initialState,
    reducers: {
        setBusinessTypes: (state, action: PayloadAction<MasterCode[]>) => {
            state.businessTypes = action.payload;
        }
    },
});

export const { setBusinessTypes } = slice.actions;
export default slice.reducer;
