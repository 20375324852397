import { useContext, useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../layout/AppWrapper";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import { buildQueryParams, getRowLimitWithScreenHeight } from "../../../utils/uitl";
import CustomDataTable, { CustomDataTableRef } from "../../../components/CustomDataTable";
import { get } from "lodash";
import { Asset, CustomResponse, MasterCode, Vendor } from "../../../types";
import { DeleteCall, GetCall, PostCall } from "../../../api/ApiKit";
import { Button } from "primereact/button";
import { ACTIONS, VENDOR_TYPE } from "../../../utils/constant";
import { DEFAULT_VENDOR } from "../../../types/default-forms";
import UploadFile from "../../../components/UploadFile";
import FileView from "../../../components/FileView";
import Sidebar from "../../../components/Sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import VendorViewer from "../../../components/Views/VendorView";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import VendorEditor from "../../../components/Editors/VendorEditor";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import AppPage from "../../../layout/AppPage";
import { ReduxStoreState } from "../../../redux/redux-store";
import { useSelector } from "react-redux";

const Customers = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
    const [action, setAction] = useState<any>(null);

    const vendorViewRef = useRef<any>(null);
    const [visible, setVisible] = useState(false);
    const [assetFile, setAssetFile] = useState<Asset | null>(null);
    const [isShowImage, setShowImage] = useState<boolean>(false);
    const businessTypes = useSelector((state: ReduxStoreState) => state.businessTypes.businessTypes);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryId = searchParams.get('vendorId');
    const initializedRef = useRef(false);
    useEffect(() => {
        const check = async () => {
            if (!initializedRef.current && queryId) {
                initializedRef.current = true;
                setAction(ACTIONS.VIEW);
                handleClick(queryId);
            }
        };
        check();
    }, [queryId]);

    const handleClick = (vendorId?: any) => {
        if (vendorId) {
            const newUrl = `/customers?vendorId=${vendorId}`;
            navigate(newUrl, { replace: true });
        }
        else {
            navigate('/customers', { replace: true });
        }
    };

    useEffect(() => {
        setScroll(false);
        fetchData();
        return () => {
            setScroll(true);
        }
    }, [])


    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        params.filters = { vendorType: 'isCustomer' };
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/vendors?${queryString}`); // get all the Vendors
        setLoading(false);
        if (response.code === 'SUCCESS') {
            setVendors(response.data); // Store vendors in state
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setVendors([]);
        }
    };

    const showAddNew = () => {
        setSelectedVendor(null);
        setAction(ACTIONS.ADD);
    }

    const onRowSelect = async (company: Vendor, action: any) => {
        await setSelectedVendor(company);
        setAction(action);
        if (action === ACTIONS.DELETE) {
            return;
        }
        if (action === ACTIONS.VIEW) {
            handleClick(company.vendorId)
        }
    };

    const closeIcon = () => {
        setAction(null);
        handleClick();
    }

    const uploadDocs = async (assets: any) => {
        let vendorId = selectedVendor?.vendorId;
        if (!vendorId && selectedVendor) {
            vendorId = selectedVendor.vendorId;
        }
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/vendors/${vendorId}/attachments`, assets);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Upload Successfully');
            if (vendorViewRef.current) {
                vendorViewRef.current.fetchDocs()
            }
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const confirmDelete = (item: any) => {
        console.log('item', item)
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this vendor : ${item?.name}?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: async () => {
                if (item) {
                    const companyId = get(user, 'company.companyId');
                    setLoading(true);
                    const response: CustomResponse = await DeleteCall(`/company/${companyId}/vendors/${item.vendorId}`);
                    if (response.code == 'SUCCESS') {
                        fetchData()
                    } else {
                        setAlert('error', response.message)
                    }
                    setLoading(false);
                }
            },
        });
    }

    const getBusinessTypeName = (businessTypeId: string) => {
        const matchingType = businessTypes.find((type: any) => type.masterCodeId === businessTypeId);
        return matchingType ? matchingType.code : '';
    };

    return <AppPage>
        <div className="grid">
            <div className="col-12">
                <div className={`panel-container`}>
                    <div className="left-panel">
                        <CustomDataTable
                            ref={dataTableRef}
                            filter
                            title="Customers"
                            page={page}
                            limit={limit}
                            totalRecords={totalRecords}
                            isEdit={true}
                            isView={true}
                            isDelete={true}
                            data={vendors}
                            headerButtons={[
                                {
                                    icon: 'pi pi-plus',
                                    label: 'New',
                                    onClick: () => showAddNew()
                                }
                            ]}
                            extraButtons={[
                                {
                                    icon: 'pi pi-cloud-upload',
                                    onClick: (item) => {
                                        setSelectedVendor(item)
                                        setVisible(true)
                                    }
                                },
                            ]}
                            columns={[
                                { header: '#', field: 'vendorId', filter: true, sortable: true, bodyStyle: { minWidth: 80, maxWidth: 80 }, filterPlaceholder: 'Search #' },
                                { header: 'Name', filter: true, filterPlaceholder: 'Search name', body: (item: Vendor) => <span onClick={() => onRowSelect(item, ACTIONS.VIEW)} className='text-blue cursor-pointer'>{item.name}</span> },
                                { header: 'Business Type', field: 'businessTypeId', body: (rowData: any) => getBusinessTypeName(rowData.businessTypeId), style: { minWidth: 120, maxWidth: 120 } },
                                { header: 'Email', field: 'email', filter: true, filterPlaceholder: 'Search Email Id' },
                                { header: 'Phone', field: 'phone', filter: true, filterPlaceholder: 'Search phone' }
                            ]}
                            onLoad={(params: any) => fetchData(params)}
                            onEdit={(item: any) => onRowSelect(item, ACTIONS.EDIT)}
                            onView={(item: any) => onRowSelect(item, ACTIONS.VIEW)}
                            onDelete={(item: any) => confirmDelete(item)}
                        />
                    </div>
                    <UploadFile
                        isVisible={visible}
                        onSelect={(option: any) => {
                            setVisible(false);
                            if (option && option.length > 0) {
                                let assets = option.map((item: any) => ({
                                    assetId: item.assetId,
                                    comment: item.comment || ''
                                }))
                                uploadDocs(assets);
                            }
                        }}
                    />
                    <FileView
                        isVisible={isShowImage}
                        assetFile={assetFile}
                        onClose={() => setShowImage(false)}
                    />
                </div>
            </div>
        </div>

        {
            ACTIONS.VIEW === action && <VendorViewer
                ref={vendorViewRef}
                isVisible={ACTIONS.VIEW === action}
                vendorId={queryId}
                type={VENDOR_TYPE.CUSTOMER}
                vendor={selectedVendor}
                onClose={(isAction: any) => {
                    if (isAction) {
                        if (isAction === ACTIONS.EDIT) {
                            setAction(ACTIONS.EDIT);
                        }
                        if (isAction === 'ATTACH') {
                            setVisible(true)
                        }
                        else {
                            fetchData()
                        }
                    }
                    else {
                        closeIcon();
                    }
                }}
            />
        }

        {
            [ACTIONS.ADD, ACTIONS.EDIT].includes(action) && <VendorEditor
                isVisible={true}
                type={VENDOR_TYPE.CUSTOMER}
                vendor={selectedVendor ? selectedVendor : null}
                isEdit={action === ACTIONS.EDIT ? true : false}
                onClose={(isVendorId: any) => {
                    closeIcon();
                    if (isVendorId) {
                        setAction(ACTIONS.VIEW);
                        handleClick(isVendorId)
                        fetchData();
                    }
                    else {
                        setAction('');
                    }
                }}
            />
        }

    </AppPage>
}

export default Customers;