


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { GradeTobin, CustomResponse, Roles } from '../../../types';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, head, keyBy, map, uniq } from 'lodash';
import { DeleteCall, GetCall, PostCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { EmptyGradeToBin } from '../../../types/forms';
import { Dialog } from 'primereact/dialog';
import { buildQueryParams, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import Sidebar from '../../../components/Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptyGradeToBin = {
    binGradeId: ''
};

const GradeToBinPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<GradeTobin[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<GradeTobin | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [action, setAction] = useState<any>(null);

    const [selectedAutoValue, setSelectedAutoValue] = useState<any[] | null>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const [inputValue, setInputValue] = React.useState<string>('');
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const [MasterCodeType, setMasterCodeType] = useState<any>([]);
    const toast = useRef<Toast>(null);
    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchMasterCodeType();
        return () => {
            setScroll(true);
        };
    }, []);

    useEffect(() => {
        // Trigger API call when both grade and bin are set
        if (selectedOption && inputValue) {
            onNewAdd();
        }
    }, [selectedOption, inputValue]);

    const fetchData = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        const { sortBy = 'binId', sortOrder = 'asc', ...otherParams } = params || {};
        const validSortOrder = ['asc', 'desc'].includes(sortOrder) ? sortOrder : 'asc';
        const queryParams = {
            sortBy,
            sortOrder: validSortOrder,
            ...otherParams
        };

        const queryString = new URLSearchParams(queryParams).toString();
        setLoading(true);
        try {
            const response: CustomResponse = await GetCall(`/company/${companyId}/bin-grades?${queryString}`);
            if (response.code === 'SUCCESS') {
                setCompanies(response.data);
            } else {
                setCompanies([]);
            }
        } catch (error) {
            setCompanies([]);
        } finally {
            setLoading(false);
        }
    };
    const fetchMasterCodeType = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        const type = params?.type || 'Internal Grade';
        setLoading(true);
        try {
            const response: CustomResponse = await GetCall(`/company/${companyId}/master-codes-by-types/${encodeURIComponent(type)}`);
            if (response.code === 'SUCCESS') {
                setMasterCodeType(response.data); // Set to an array from the API response
            } else {
                console.warn('Failed to fetch master code types:', response);
            }
        } catch (error) {
            console.error('Error fetching master code types:', error);
        }
    };

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false);
        setAction(null);
        setSelectedAutoValue(null);
    };

    const showAddNew = () => {
        setIsShowSplit(true);
        setAction('add');
        setSelectedCompany(null);
    };

    const onSave = () => {
        if (action == ACTIONS.EDIT) {
            const selectedItems = selectedAutoValue;
            const filteredItems = filter(selectedItems, (item) => item.roleId != null);
            return;
        }

        if (action == ACTIONS.ADD) {
            const selectedItems = selectedAutoValue;
            const filteredItems = filter(selectedItems, (item) => item.roleId != null);
            return;
        }
    };
    const onNewAdd = async () => {
        if (!selectedOption || !inputValue) {
            setAlert('error', 'Please select a grade and enter a bin.'); // Validate inputs
            return;
        }

        const companyForm = {
            gradeId: selectedOption, // Add selected gradeId
            binNumber: inputValue // Add input value as bin
        };

        const companyId = user?.company?.companyId;
        setIsDetailLoading(true);

        try {
            const response = await PostCall(`/company/${companyId}/bin-grades`, companyForm);

            if (response.code === 'SUCCESS') {
                setAlert('success', 'Data Added Successfully');
                setSelectedCompany(response.data);
                setIsShowSplit(false);
                dataTableRef.current?.updatePagination(1); // Refresh data table

                setSelectedOption(null); // Clear dropdown selection
                setInputValue(''); // Clear input field
            } else {
                setAlert('error', response.message);
            }
        } catch (error) {
            setAlert('error', 'Failed to add new entry.');
        } finally {
            setIsDetailLoading(false);
        }
    };

    const onDelete = async (item: any) => {
        const companyId = get(user, 'company.companyId');
        const binGradeId = item?.binGradeId;
        if (!binGradeId) {
            setAlert('error', 'No item selected for deletion.');
            return;
        }
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/bin-grades/${binGradeId}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Deleted successfully');
            setAction('');
            setSelectedCompany(null);
            fetchData();
        } else {
            setAlert('error', response.message);
        }
    };

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            },
        });
    }

    const onRowSelect = async (company: GradeTobin, action: any) => {
        await setSelectedCompany(company);
        setAction(action);
        setSelectedAutoValue(null);

        if (action == ACTIONS.DELETE) {
            return;
        }

        setIsShowSplit(true);
    };

    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                {/* {
                    action == ACTIONS.VIEW_PERMISSIONS ? <Button label="Back" severity="secondary" text onClick={() => setAction(ACTIONS.VIEW)} /> : <div></div>
                } */}
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={isLoading || isDetailLoading} onClick={onSave} />}
                </div>
            </div>
        );
    };

    const dropdownOptions =
        MasterCodeType?.codes?.map((item: any) => ({
            label: item.code, // Display field from `codes`
            value: item.masterCodeId // Unique key
        })) || [];


    const headerTemplate = (<div className='w-full px-4'>
        <p className="text-xl">Bin Grades</p>
        <div className="flex justify-content-between gap-3">
            <div style={{ width: '50%' }}>
                <label htmlFor="categoryDropdown" style={{ display: 'block', marginBottom: '0.5rem' }}>
                    Grade
                </label>
                <Dropdown value={selectedOption} options={dropdownOptions} onChange={(e) => setSelectedOption(e.value)} placeholder="Select Grade" className="mr-2" style={{ width: '100%' }} />
            </div>
            <div style={{ width: '50%' }}>
                <label htmlFor="inputWithIcon" style={{ display: 'block', marginBottom: '0.5rem' }}>
                    Bin
                </label>
                <div style={{ position: 'relative', width: '100%' }}>
                    <InputText
                        id="inputWithIcon"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter Bin"
                        style={{ width: '100%', paddingRight: '1.5rem' }} // Reserve space for the icon
                    />
                    <i
                        className="pi pi-qrcode"
                        style={{
                            position: 'absolute',
                            right: '0.75rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: '1rem',
                            cursor: 'pointer',
                            color: '#6c757d'
                        }}
                    />
                </div>
            </div>
        </div>
    </div>);

    return (
        <AppPage>
            <ConfirmDialog/>
            <div className="grid" style={{ height: '400px', overflowY: 'auto' }}>
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isDelete={true} // show delete button
                                header={headerTemplate}
                                data={companies}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'binGradeId',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search Id'
                                    },
                                    {
                                        header: 'Grade',
                                        field: 'code',
                                        filter: true,
                                        sortable: true,
                                        body: (item) => get(item, 'grade.code'),
                                        filterPlaceholder: 'Search Grade'
                                    },
                                    {
                                        header: 'Bin',
                                        field: 'binNumber',
                                        filter: true,
                                        sortable: true,
                                        body: (item) => get(item, 'bin.binNumber'),
                                        filterPlaceholder: 'Search name'
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onView={(item: any) => onRowSelect(item, 'view')}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <Sidebar
                            isVisible={isShowSplit}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={
                                <>
                                    {isDetailLoading && (
                                        <div className="center-pos">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    )}

                                    {/* Edit Roles */}
                                    {(action == ACTIONS.ADD || action == ACTIONS.EDIT) && <div></div>}
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

const findSelectedKeys = (nodes: any[]): any => {
    let selectedKeys: any = {};
    let parents: any = {}; // To keep track of parent nodes

    const traverse = (node: any) => {
        if (node) {
            selectedKeys[node.key] = {
                checked: true
            }; // Mark the current node as selected
        }
        let allChildrenSelected = true;
        let anyChildSelected = false;

        if (node.children) {
            node.children.forEach((child: any) => {
                traverse(child); // Recursively process children

                if (selectedKeys[child.key] && selectedKeys[child.key].checked === true) {
                    anyChildSelected = true; // At least one child is selected
                } else {
                    allChildrenSelected = false; // Not all children are selected
                }
            });

            // Determine the state of the current node based on its children
            if (anyChildSelected) {
                parents[node.key] = {
                    checked: allChildrenSelected,
                    partialChecked: !allChildrenSelected
                };
            }
        }
    };

    nodes.forEach(traverse);

    // Merge parents into selectedKeys
    Object.keys(parents).forEach((key) => {
        selectedKeys[key] = parents[key];
    });

    return selectedKeys;
};

export default GradeToBinPage;
