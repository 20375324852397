import { useEffect, useRef, useState } from "react";
import { Customer, CustomResponse, MasterCode, Parts, Vendor } from "../../types";
import { DEFAULT_PARTS, DEFAULT_VENDOR } from "../../types/default-forms";
import { useAppContext } from "../../layout/AppWrapper";
import { Panel } from "primereact/panel";
import Sidebar from "../Sidebar";
import { ACTIONS, constant, VENDOR_TYPE } from "../../utils/constant";
import { InputText } from "primereact/inputtext";
import { get, map, set } from "lodash";
import { InputTextarea } from "primereact/inputtextarea";
import { GetCall, PostCall, PutCall } from "../../api/ApiKit";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { validateEmail, validateName, validatePhoneNumber, validateURL } from "../../utils/uitl";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/redux-store";

interface VendorEditorOptions {
    isVisible: boolean,
    isEdit?: boolean,
    vendor?: Vendor | null,
    type?: any,
    onClose: (isLoad: boolean) => void
}


export default function VendorEditor({ isVisible = false, type = VENDOR_TYPE.VENDOR, vendor, isEdit = false, onClose = (isLoad) => { } }: VendorEditorOptions) {
    const ref = useRef<Panel>(null);
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [form, setForm] = useState<Vendor | Customer>({ ...DEFAULT_VENDOR });

    const warehouseOptions = useSelector((state: ReduxStoreState) => state.locations.locations);
    const paymentTerms = useSelector((state: ReduxStoreState) => state.paymentTerms.paymentTerms);
    const categoryOptions = useSelector((state: ReduxStoreState) => state.categories.categories)
    const businessTypes = useSelector((state: ReduxStoreState) => state.businessTypes.businessTypes);
    const industryTypes = useSelector((state: ReduxStoreState) => state.industryTypes.industryTypes);
    const carriers = useSelector((state: ReduxStoreState) => state.carriers.carriers);

    useEffect(() => {
        if (isVisible) {
            setSubmitted(false);
            setDialogVisible(true);
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);

    useEffect(() => {
        if (vendor && isEdit) {
            setForm(vendor)
            fetchData(vendor.vendorId)
        }
        else {
            setForm({ ...DEFAULT_VENDOR })
        }
    }, [vendor]);

    const fetchData = async (vendorId: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/vendors/${vendorId}?query=pocs,vendorCategories,vendorWarehouses,paymentSetup,VendorGrades`);
        setLoading(false);
        if (response.code === 'SUCCESS') {
            let _form = { ...response.data };
            _form.paymentSettings = get(response.data, 'paymentSetup', {})
            _form.categoryIds = map(get(response.data, 'vendorCategories', []), 'categoryId');
            _form.locationIds = map(get(response.data, 'vendorWarehouses', []), 'locationId');
            setForm(_form);
        }
    }

    const closeIcon = () => {
        setForm({ ...DEFAULT_VENDOR })
        onClose(false);
    }

    const onSaveClose = (value: any = true) => {
        setForm({ ...DEFAULT_VENDOR })
        onClose(value);
    }

    const updateItem = async (key: string, value: any) => {
        const _form: Vendor = JSON.parse(JSON.stringify(form));
        set(_form, key, value);
        setForm(_form);
    }

    const onSave = async () => {
        if (isLoading) {
            return;
        }

        setSubmitted(true);
        if (!validateName(form.name) || !validateEmail(form.email)) {
            return;
        }

        if (type === VENDOR_TYPE.VENDOR && (form.categoryIds.length == 0 || form.locationIds.length == 0)) {
            return;
        }
        if (type === VENDOR_TYPE.CUSTOMER && (!form.businessTypeId || !form.industryTypeId)) {
            return;
        }

        if (type === VENDOR_TYPE.VENDOR && form.aliasName && !validateName(form.aliasName)) {
            setAlert('error', 'Please provide valid alias name')
            return;
        }

        if (type === VENDOR_TYPE.VENDOR && !validateName(form.accPayContact)) {
            setAlert('error', 'Please provide valid account payable name')
            return;
        }

        if (form.phone && !validatePhoneNumber(form.phone)) {
            setAlert('error', 'Please provide valid phone number')
            return;
        }

        if (type === VENDOR_TYPE.CUSTOMER) {
            form.isCustomer = true;
        }

        if (type === VENDOR_TYPE.VENDOR) {
            form.isSupplier = true;
        }

        const companyId = get(user, 'company.companyId');
        if (isEdit) {
            setLoading(true);
            const response: CustomResponse = await PutCall(`/company/${companyId}/vendors/${form.vendorId}`, form);
            if (response.code == 'SUCCESS') {
                onSaveClose(form?.vendorId)
            } else {
                setAlert('error', response.message)
            }
            setSubmitted(false);
            setLoading(false);
        }
        else {
            setLoading(true);
            const response: CustomResponse = await PostCall(`/company/${companyId}/vendors`, form);
            if (response.code == 'SUCCESS') {
                onSaveClose(get(response.data, 'vendorId', true))
            } else {
                setAlert('error', response.message)
            }
            setSubmitted(false);
            setLoading(false);
        }
    }

    return <Sidebar
        isVisible={dialogVisible}
        action={ACTIONS.EDIT}
        width={'60vw'}
        title={!isEdit ? (type === VENDOR_TYPE.CUSTOMER ? 'Add Customer' : 'Add vendor') : vendor?.name}
        closeIcon={closeIcon}
        onSave={onSave}
        content={<>
            <div className="grid">
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Name <span className="text-red">*</span>
                        </label>
                        <InputText className="w-full" value={form.name} onChange={(e) => updateItem('name', e.target.value)} />
                        {isSubmitted && !validateName(form.name) && <small className="text-red">Please provide valid name</small>}
                    </div>
                </div>
                {
                    type === VENDOR_TYPE.CUSTOMER ? <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">Account Payable Name <span className="text-red">*</span></label>
                            <InputText className="w-full" value={form.accPayContact} onChange={(e) => updateItem('accPayContact', e.target.value)} />
                            {isSubmitted && form.accPayContact && !validateName(form.accPayContact) && <small className="text-red">Please provide valid name</small>}
                        </div>
                    </div> : <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">Alias</label>
                            <InputText className="w-full" value={form.aliasName} onChange={(e) => updateItem('aliasName', e.target.value)} />
                            {isSubmitted && form.aliasName && !validateName(form.aliasName) && <small className="text-red">Please provide valid alias name</small>}
                        </div>
                    </div>
                }
            </div>
            <div className="grid">
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Email <span className="text-red">*</span>
                        </label>
                        <InputText className="w-full" id="email" value={get(form, 'email')} onChange={(e) => updateItem('email', e.target.value)} />
                        {isSubmitted && !validateEmail(form.email) && <small className="text-red">Please provide valid email</small>}
                    </div>
                </div>
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Phone
                        </label>
                        <InputText className="w-full" id="phone" value={get(form, 'phone')} onChange={(e) => updateItem('phone', e.target.value)} />
                        {isSubmitted && form.phone && !validatePhoneNumber(form.phone) && <small className="text-red">Please provide valid phone</small>}
                    </div>
                </div>
            </div>
            {
                type === VENDOR_TYPE.VENDOR && <div className="grid">
                    <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">
                                Location <span className="text-red">*</span>
                            </label>
                            <MultiSelect className="w-full" value={form.locationIds} options={warehouseOptions} optionLabel="name" optionValue="locationId" onChange={(e) => updateItem('locationIds', e.value)} placeholder="Select location" showSelectAll={true} display="chip" />
                            {isSubmitted && (!form.locationIds || form.locationIds.length == 0) && <small className="text-red">Please select location</small>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">
                                Categories <span className="text-red">*</span>
                            </label>
                            <MultiSelect className="w-full" value={form.categoryIds} filter filterBy='label' options={categoryOptions} optionLabel="name" optionValue="categoryId" onChange={(e) => updateItem('categoryIds', e.value)} placeholder="Select Category" />
                            {isSubmitted && (!form.categoryIds || form.categoryIds.length == 0) && <small className="text-red">Please select category</small>}
                        </div>
                    </div>
                </div>
            }
            {
                type === VENDOR_TYPE.CUSTOMER && <div className="grid">
                    <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">
                                Business Type <span className="text-red">*</span>
                            </label>
                            <Dropdown className="w-full" value={form.businessTypeId} options={businessTypes} optionLabel="code" optionValue="masterCodeId" onChange={(e) => updateItem('businessTypeId', e.value)} placeholder="Select Business Type" />
                            {isSubmitted && (!form.businessTypeId) && <small className="text-red">Please select business type</small>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">
                                Industry Type <span className="text-red">*</span>
                            </label>
                            <Dropdown className="w-full" value={form.industryTypeId} options={industryTypes} optionLabel="code" optionValue="masterCodeId" onChange={(e) => updateItem('industryTypeId', e.value)} placeholder="Select Industry Type" />
                            {isSubmitted && (!form.industryTypeId) && <small className="text-red">Please select industry type</small>}
                        </div>
                    </div>
                </div>
            }
            <div className="grid">
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Payment Terms
                        </label>
                        <Dropdown value={form?.paymentSettings?.paymentTermsId || undefined} options={paymentTerms} onChange={(e) => updateItem('paymentSettings.paymentTermsId', e.value)} optionLabel="code" optionValue="masterCodeId" placeholder="Mode of payment" className="w-full" />
                    </div>
                </div>
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Website
                        </label>
                        <InputText className="w-full" id="website" value={get(form, 'website')} onChange={(e) => updateItem('website', e.target.value)} />
                        {isSubmitted && form.website && !validateURL(form.website) && <small className="text-red">Please provide valid website url</small>}
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-6">
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full">
                            Preferred Shipment Mode
                        </label>
                        <Dropdown value={form.shipmentPrefId || undefined} options={carriers} onChange={(e) => updateItem('shipmentPrefId', e.value)} optionLabel="code" optionValue="masterCodeId" placeholder="Preferred Shipment Mode" className="w-full" />
                    </div>
                </div>
                {
                    type === VENDOR_TYPE.CUSTOMER && <div className="col-6">
                        <div className="field p-0">
                            <label htmlFor="name3" className="w-full">
                                Battery Health
                            </label>
                            <InputNumber className="w-full" id="bhealth" value={get(form, 'batteryHealth')} onChange={(e) => updateItem('batteryHealth', e.value)} />
                        </div>
                    </div>
                }
            </div>
            <div className="field p-0">
                <label htmlFor="name3" className="w-full ">
                    Note
                </label>
                <div className="w-full">
                    <InputTextarea className="w-full" rows={5} value={form.note} onChange={(e) => updateItem('note', e.target.value)} />
                </div>
            </div>
        </>}
    />
}