


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { MultiSelect } from 'primereact/multiselect';
import { CompanySubLocation, CustomResponse, Permissions } from '../../../types';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PostPdfCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { EmptySubLocation } from '../../../types/forms';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, filterArray, formatString, getRowLimitWithScreenHeight, validateName, validatePhoneNumber, validateString, validateSubdomain, validateZipNumber } from '../../../utils/uitl';
import RightSidePanel from '../../../components/RightSidePanel';
import { Dropdown } from 'primereact/dropdown';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import DownloadBarcodeButton from '../../../components/DownloadBarcodeButton';
import AppPage from '../../../layout/AppPage';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptySubLocation = {
    subLocationId: null,
    locationId: null,
    name: '',
    address: '',
    description: '',
    location: {
        name: '',
    }
};

const SubLocationPage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanySubLocation[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<CompanySubLocation | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [details, setDetails] = useState<any>(null);
    const [rolename, setRolename] = useState<any>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [selectedLocationId, setSelectedLocationId] = useState<any>(null);
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<EmptySubLocation>(defaultForm);
    const [confirmTextValue, setConfirmValue] = useState<any>('');

    const [permissions, setPermissions] = useState<any[]>([]);
    const [groupedData, setGroupData] = useState<any>([]);
    const [companyAllLocation, setcomapnyAllLocation] = useState<any>(null);
    const [addWarehouse, setAddWarehouse] = useState<any>(null);
    const [selectedKeys, setSelectedKeys] = useState<TreeCheckboxSelectionKeys | null>({});
    const [companylocationId, setcompanylocationId] = useState<any>(null);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchPermissions();
        return () => {
            setScroll(true);
        };
    }, []);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        setPage(params.page);
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sub-locations?${queryString}`); // get all the roles
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setCompanies([]);
        }
    };

    const fetchPermissions = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/locations?limit=500`); // get company all roles
        if (response.code == 'SUCCESS') {
            setcomapnyAllLocation(response.data);
        } else {
            setcomapnyAllLocation([]);
        }
        setLoading(false);
    };

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false);
        setForm(defaultForm);
        setAction(null);
        setSelectedKeys(null);
    };

    const showAddNew = () => {
        fetchPermissions();
        setIsShowSplit(true);
        setAction('add');
        setSelectedCompany(null);
        setForm(defaultForm);
    };

    const onSave = () => {
        if (action == ACTIONS.ADD) {
            onNewAdd({ ...form });
            return;
        }

        if (action == ACTIONS.EDIT) {
            onUpdate(form);
        }
    };

    const onNewAdd = async (companyForm: any) => {
        if (!validateString(companyForm.name)) {
            setAlert('error', 'Please provide valid name');
            return;
        }
        if (!validateString(companyForm.location)) {
            setAlert('error', 'Please provide valid location');
            return;
        }

        setIsDetailLoading(true);
        const companyId = get(user, 'company.companyId');
        const response: CustomResponse = await PostCall(`/company/${companyId}/locations/${addWarehouse?.locationId}/sub-locations`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setSelectedCompany(response.data);
            setAlert('success', 'Successfully Added');
            dataTableRef.current?.updatePagination(1);
            setIsShowSplit(false);
        } else {
            setAlert('error', response.message);
        }
    };

    const onUpdate = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId');
        if (!validateString(companyForm.name)) {
            setAlert('error', 'Please provide valid name');
            return;
        }

        setIsDetailLoading(true);
        const response: CustomResponse = await PutCall(`/company/${companyId}/locations/${companylocationId?.locationId}/sub-locations/${selectedLocationId?.subLocationId}`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setIsShowSplit(false);
            setSelectedCompany(selectedCompany);
            fetchData();
            setAlert('success', 'Successfully Updated');
            dataTableRef.current?.refreshData();
        } else {
            setAlert('error ', response.message);
        }
    };

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: "Do you really want to delete this sub-location?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            }
        });
    }

    const onDelete = async (item: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/locations/${item?.locationId}/sub-locations/${item?.subLocationId}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setAction('');
            setSelectedCompany(null);
            fetchData();
            setAlert('success', 'Successfully Deleted');
        } else {
            setAlert('error', response.message);
        }
        setIsDeleteDialogVisible(false)
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(companies);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Sub_Location');
        });
    };

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
   
    const openDeleteDialog = (perm: CompanySubLocation) => {
        setSelectedCompany(perm);
        setIsDeleteDialogVisible(true);
    };
    const closeDeleteDialog = () => {
        setIsDeleteDialogVisible(false);
        setSelectedCompany(null);
    };

    const onRowSelect = async (company: CompanySubLocation, action: any) => {
        await setSelectedCompany(company);
        setcompanylocationId(company);
        setSelectedLocationId(company);
        setAction(action);
        setSelectedKeys(null);

        if (action == ACTIONS.DELETE) {
            openDeleteDialog(company);
        }

        setDetails(null);

        if (action == ACTIONS.EDIT) {
            setForm({ ...company });
            setIsShowSplit(true);
        }


    };

    const downloadBarcode = async (item: any) => {
        setLoading(true);
        try {
            const result = await PostPdfCall(`/company/${get(user, 'company.companyId')}/download-barcodes`, { type: 'SUB_LOCATION', ids: [item.subLocationId] });
            if (result && result.code === 'FAILED') {
                setAlert('error', result.message);
            }
        } catch (error: any) {
            setAlert('error', error.message || 'Something went wrong!');
        } finally {
            setLoading(false);
        }
    }

    const onInputChange = (name: string | { [key: string]: any }, val?: any) => {
        setForm((prevForm: any) => {
            let updatedForm = { ...prevForm };
            if (typeof name === 'string') {
                updatedForm[name] = val;
            } else {
                updatedForm = { ...updatedForm, ...name };
            }
            return updatedForm;
        });
    };

    const headerTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <div className="ellipsis-container font-bold" style={{ marginLeft: 10, maxWidth: '22vw' }}>
                        {action == ACTIONS.ADD ? 'Add Sub Location' : selectedCompany?.name}
                    </div>
                </div>
            </div>
        );
    };
    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                {action == ACTIONS.VIEW_PERMISSIONS ? <Button label="Back" severity="secondary" text onClick={() => setAction(ACTIONS.VIEW)} /> : <div></div>}
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={isLoading || isDetailLoading} onClick={onSave} />}
                </div>
            </div>
        );
    };

    const handleLocationChange = (e: any) => {
        const selectedLocation = e.value;
        setAddWarehouse(e.value);
        onInputChange({
            locationId: selectedLocation.locationId,
            location: selectedLocation.location
        }); // Store the subLocationId in the form
    };

    const roleBodyTemplate = (item: any) => item.location.name;

    const statusRowFilterTemplate = (options: any) => {
        return (
            <Dropdown
                filter
                value={options.value}
                options={companyAllLocation}
                optionLabel="name"
                optionValue="locationId"
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Select Location"
                className="p-column-filter"
                showClear
                style={{ minWidth: '12rem' }}
            />
        );
    };

    return (
        <AppPage>
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Sub locations'
                                limit={limit}
                                page={page}
                                totalRecords={totalRecords}
                                data={companies}
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                headerButtons={[
                                    {
                                        icon: 'pi pi-file-excel',
                                        onClick: () => exportExcel()
                                    },
                                    {
                                        content: <DownloadBarcodeButton
                                            url={`/company/${get(user, 'company.companyId')}/download-barcodes`}
                                            type={'SUB_LOCATION'}
                                            ids={companies}
                                            optionLabel='name'
                                            optionValue='skuId' />
                                    },
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                extraButtons={[
                                    {
                                        icon: 'pi pi-qrcode',
                                        onClick: (item) => downloadBarcode(item)
                                    }
                                ]}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'skuId',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search #'
                                    },
                                    {
                                        header: 'Name',
                                        field: 'name',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'Location',
                                        field: 'locationId',
                                        body: roleBodyTemplate,
                                        filter: true,
                                        filterElement: statusRowFilterTemplate,
                                        filterPlaceholder: 'Search location'
                                    },
                                    {
                                        header: 'Description',
                                        field: 'description',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search description'
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <RightSidePanel
                            isVisible={isShowSplit}
                            headerTemplate={headerTemplate}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={
                                <>
                                    {isDetailLoading && (
                                        <div className="center-pos">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    )}
                                    {/* Edit Permissions */}
                                    {(action === ACTIONS.ADD || action === ACTIONS.EDIT) && (
                                        <div className="p-fluid">
                                            <div className="field">
                                                <label htmlFor="name">
                                                    Name <span className="red">*</span>
                                                </label>
                                                <InputText
                                                    id="name"
                                                    value={get(form, 'name')}
                                                    validateOnly
                                                    pattern="[a-zA-Z]*"
                                                    onChange={(e) => onInputChange('name', e.target.value)}
                                                />
                                            </div>

                                            <div className="field">
                                                <label htmlFor="location">
                                                    Location <span className="red">*</span>
                                                </label>
                                                <Dropdown
                                                    value={companyAllLocation.find((loc: any) => loc.locationId === get(form, 'locationId')) || null}
                                                    onChange={handleLocationChange}
                                                    options={companyAllLocation}
                                                    optionLabel="name"
                                                    placeholder="Select"
                                                    disabled={action === ACTIONS.EDIT} // Disable dropdown when action is EDIT
                                                />
                                            </div>

                                            <div className="field">
                                                <label htmlFor="description">
                                                    Description
                                                </label>
                                                <InputText
                                                    id="description"
                                                    value={get(form, 'description')}
                                                    onChange={(e) => onInputChange('description', e.target.value)}
                                                    disabled={action === ACTIONS.EDIT}
                                                />
                                            </div>

                                            <div className="field">
                                                <label htmlFor="address">
                                                    Address
                                                </label>
                                                <InputText
                                                    id="address"
                                                    value={get(form, 'address')}
                                                    validateOnly
                                                    pattern="[a-zA-Z]*"
                                                    onChange={(e) => onInputChange('address', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </AppPage>
    );
};
export default SubLocationPage;
