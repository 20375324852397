import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface GradesState {
    grades: MasterCode[];
}

const initialState: GradesState = {
    grades: [],
};

const gradesSlice = createSlice({
    name: "grades",
    initialState,
    reducers: {
        setGrades: (state, action: PayloadAction<MasterCode[]>) => {
            state.grades = action.payload;
        }
    },
});

export const { setGrades } = gradesSlice.actions;
export default gradesSlice.reducer;
