import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { MultiSelect } from 'primereact/multiselect';
import { CompanyUser, CustomResponse, Roles } from '../../../types';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, head, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { CompanyUserForm, EmptyUser } from '../../../types/forms';
import { Dialog } from 'primereact/dialog';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, getRowLimitWithScreenHeight, validateCountryCode, validateEmail, validateName, validatePhoneNumber, validateRoles, validateSubdomain } from '../../../utils/uitl';
import { InputSwitch } from 'primereact/inputswitch';
import RightSidePanel from '../../../components/RightSidePanel';
import { Dropdown } from 'primereact/dropdown';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import AppPage from '../../../layout/AppPage';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    // VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: CompanyUserForm = {
    companyUserId: null,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    countryCode: ''
};

const UserPage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanyUser[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<CompanyUser | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [details, setDetails] = useState<any>(null);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<CompanyUserForm>(defaultForm);
    const [roles, setRoles] = useState<any>([]);
    const [companyUserId, setCompanyUserId] = useState<any>(null);
    const [userOldRoles, setUserOldRoles] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [companyAllLocation, setcomapnyAllLocation] = useState<any>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchRolesData();
        return () => {
            setScroll(true);
        };
    }, []);
    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/company-users?${queryString}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            fetchAllLocation();

            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setCompanies([]);
        }
    };

    const fetchRolesData = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/roles`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setRoles(response.data);
        } else {
            setRoles([]);
        }
    };

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false);
        setForm(defaultForm);
        setAction(null);
    };

    const showAddNew = () => {
        setIsShowSplit(true);
        setAction('add');
        setSelectedCompany(null);
        setForm(defaultForm);
    };

    const onSave = () => {
        if (!form.isAdmin) {
            const rolePerms: any[] = [];
            if (form.locationIds && form.locationIds.length > 0) {
                form.locationIds.forEach(element => {
                    rolePerms.push({
                        roleId: form.roleId,
                        locationId: element
                    })
                });
            }
            else {
                return;
            }

            form.roles = rolePerms;
        }
        else {
            form.roles = [];
        }

        if (action == ACTIONS.EDIT) {
            onUpdate(form);
            return;
        }

        if (action == ACTIONS.ADD) {
            onNewAdd(form);
            return;
        }
    };

    const onNewAdd = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId');
        if (!validateName(companyForm.firstName)) {
            setAlert('error', 'Please provide valid First Name');
            return;
        }

        if (!validateName(companyForm.lastName)) {
            setAlert('error', 'Please provid valid Last Name');
            return;
        }

        if (!validateEmail(companyForm.email)) {
            setAlert('error', 'Please provide valid email');
            return;
        }
        if (!validateCountryCode(companyForm.countryCode)) {
            setAlert('error', 'Please provide valid Country Code');
            return;
        }
        if (!validatePhoneNumber(companyForm.phone)) {
            setAlert('error', 'Please provide valid phone');
            return;
        }

        if (!validateRoles(companyForm.roles)) {
            setAlert('error', 'Please provide valid Roles');
            return;
        }

        setIsDetailLoading(true);
        const response: CustomResponse = await PostCall(`/company/${companyId}/company-users`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setSelectedCompany(response.data);
            setIsShowSplit(false);
            dataTableRef.current?.updatePagination(1);
            setAlert('success', 'User Created Successfully');
        } else {
            setAlert('error', response.message);
        }
    };

    const onUpdate = async (companyForm: any) => {
        const companyId = get(user, 'company.companyId');
        if (!validateName(companyForm.firstName)) {
            setAlert('error', 'Please provide valid First name');
            return;
        }
        if (!validateName(companyForm.lastName)) {
            setAlert('error', 'Please provide valid Last name');
            return;
        }
        if (!validateCountryCode(companyForm.countryCode)) {
            setAlert('error', 'Please provide valid Country Code');
            return;
        }

        if (!validatePhoneNumber(companyForm.phone)) {
            setAlert('error', 'Please provid valid phone number');
            return;
        }

        const oldPers = filter(userOldRoles, (item) => item.roleId);
        let payload: any[] = [];
        let selected: any[] = [];
        get(companyForm, 'roles', []).forEach((element: any) => {
            selected.push({
                roleId: element.roleId,
                locationId: element.locationId,
                companyUserId: companyUserId,
                action: 'add'
            });
        });

        oldPers.forEach((element) => {
            let doc = find(selected, { roleId: element.roleId, locationId: element.locationId });
            if (!doc) {
                payload.push({
                    roleId: element.roleId,
                    locationId: element.locationId,
                    companyUserId: companyUserId,
                    action: 'remove'
                });
            }
        });

        companyForm.roles = [...payload, ...selected];

        setIsDetailLoading(true);
        const response: CustomResponse = await PutCall(`/company/${companyId}/company-users/${selectedCompany?.companyUserId}`, companyForm);
        setIsDetailLoading(false);
        if (response.code == 'SUCCESS') {
            setSelectedCompany(null);
            setIsShowSplit(false);
            fetchData();
            setAlert('success', 'User Updated Successfully');
        } else {
            setAlert('error', response.message);
        }
    };

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: "Do you really want to delete this?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            }
        });
    }

    const onDelete = async (item: any) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/company-users/${item?.companyUserId}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Deleted successfully');
            setAction('');
            fetchData();
        } else {
            setAlert('error', response.message);
        }
        setIsDeleteDialogVisible(false);
    };
    const fetchAllLocation = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/locations?limit=500`); // get company all roles
        if (response.code == 'SUCCESS') {
            setcomapnyAllLocation(response.data);
        } else {
            setcomapnyAllLocation([]);
        }
        setLoading(false);
    };

    const onUpdatePermissions = async (perms: any[]) => {
        const oldPers = filter(userOldRoles, (item) => item.roleId);

        let payload: any[] = [];

        let selected: any[] = [];
        perms.forEach((element) => {
            selected.push({
                roleId: element.roleId,
                locationId: element.locationId,
                companyUserId: companyUserId,
                action: 'add'
            });
        });

        oldPers.forEach((element) => {
            let doc = find(selected, { roleId: element.roleId, locationId: element.locationId });
            if (!doc) {
                payload.push({
                    roleId: element.roleId,
                    locationId: element.locationId,
                    companyUserId: companyUserId,
                    action: 'remove'
                });
            }
        });

        payload = [...payload, ...selected];

        if (payload.length > 0) {
            setIsDetailLoading(true);
            const response: CustomResponse = await PostCall(`/company/${selectedCompany?.companyId}/sync-user-roles`, payload);
            setIsDetailLoading(false);
            setCompanyUserId(null);
            if (response.code == 'SUCCESS') {
                setIsShowSplit(false);
                setAlert('success', 'Permission updated');
            } else {
                setAlert('error', response.message);
            }
        }
    };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onRowSelect = async (company: CompanyUser, action: any) => {
        await setSelectedCompany(company);
        setAction(action);

        if (action == ACTIONS.EDIT) {
            setCompanyUserId(company.companyUserId);
            let userForm: any = { ...company.user }

            const roleIds = map(get(company, 'roles', []), 'roleId');
            const locationIds = map(filter(get(company, 'roles', []), 'location'), 'locationId');

            userForm.roleId = roleIds[0];
            userForm.roleIds = roleIds;
            userForm.locationIds = locationIds;

            setForm(userForm);
            setUserOldRoles(get(company, 'roles', []));
            setIsShowSplit(true);
        }
    };

    const onInputChange = (name: string | { [key: string]: any }, val?: any) => {
        setForm((prevForm: any) => {
            let updatedForm = { ...prevForm };
            if (typeof name === 'string') {
                updatedForm[name] = val;
            } else {
                updatedForm = { ...updatedForm, ...name };
            }
            return updatedForm;
        });
    };

    const headerTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <div className="ellipsis-container font-bold" style={{ marginLeft: 10, maxWidth: '22vw' }}>
                        {action == ACTIONS.ADD ? 'Add User' : selectedCompany?.user?.displayName}
                    </div>
                </div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={isLoading || isDetailLoading} onClick={onSave} />}
                </div>
            </div>
        );
    };

    const roleBodyTemplate = (company: CompanyUser) => {
        const rolesDetails: any = company.roles;
        if (rolesDetails.length != 0) {
            return uniq(rolesDetails.map((item: any, index: number) => item.role?.name)).join(', ');
        } else if(get(company, 'user.isAdmin')){
            return <div>System Admin</div>;
        }
        else return '';
    };

    const statusBodyTemplate = (company: CompanyUser) => {
        return <Tag value={company.user?.isActive ? 'Active' : 'Inactive'} severity={company.user?.isActive ? 'success' : 'warning'}></Tag>;
    };

    const roleNameFilter = (value: any, filter: any) => {
        if (!filter || filter.trim() === '') {
            return true;
        }
        return value.role.name.toLowerCase().includes(filter.toLowerCase());
    };

    const statusRowFilterTemplate = (options: any) => {
        return (
            <Dropdown
                filter
                value={options.value}
                options={roles}
                optionLabel="name"
                optionValue="roleId"
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Select Role"
                className="p-column-filter"
                showClear
                style={{ minWidth: '12rem' }}
            />
        );
    };

    return (
        <AppPage>
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                title='Users'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                data={companies.map((item: any) => ({
                                    userId: item.userId,
                                    name: item.user.displayName,
                                    email: item.user.email,
                                    phone: item.user.phone,
                                    roles: item.roles,
                                    location: item.user.location,
                                    ...item
                                }))}
                                headerButtons={[
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                // provides columns as PrimeReact DataTable
                                columns={[
                                    {
                                        header: '#',
                                        field: 'userId',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { width: 80 },
                                        filterPlaceholder: 'User Id'
                                    },
                                    {
                                        header: 'Name',
                                        field: 'name',
                                        filter: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'Email',
                                        field: 'email',
                                        filter: true,
                                        filterPlaceholder: 'Search email'
                                    },
                                    {
                                        header: 'Phone',
                                        field: 'phone',
                                        filter: true,
                                        filterPlaceholder: 'Search phone'
                                    },
                                    {
                                        header: 'Role',
                                        field: 'roleId',
                                        body: roleBodyTemplate,
                                        filterFunction: roleNameFilter,
                                        filter: true,
                                        filterElement: statusRowFilterTemplate
                                    },
                                    {
                                        header: 'Location',
                                        field: 'locationId',
                                        body: (item: CompanyUser) => uniq(get(item, 'roles', []).filter((item: any) => item.location).map((item: any, index: number) => item.location?.name)).join(`, `),
                                        filter: true,
                                        filterElement: (options: any) => (<>
                                            <Dropdown
                                                filter
                                                value={options.value}
                                                options={companyAllLocation}
                                                optionLabel="name"
                                                optionValue="locationId"
                                                onChange={(e) => options.filterApplyCallback(e.value)}
                                                placeholder="Select Location"
                                                className="p-column-filter"
                                                showClear
                                                style={{ minWidth: '12rem' }}
                                            />
                                        </>)
                                    },
                                    {
                                        header: 'Status',
                                        field: 'status',
                                        body: statusBodyTemplate
                                    },
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onEdit={(item: any) => onRowSelect(item, 'edit')}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <RightSidePanel
                            isVisible={isShowSplit}
                            headerTemplate={headerTemplate}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={
                                <>
                                    {isDetailLoading && (
                                        <div className="center-pos">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    )}

                                    {/* Edit Roles */}
                                    {(action == ACTIONS.ADD || action == ACTIONS.EDIT) && (
                                        <div className="p-fluid">
                                            {
                                                (get(user, 'isSuperAdmin') || get(user, 'isAdmin')) && <div className='field flex align-items-center'>
                                                    <span className='mr-2'>System Admin</span>
                                                    <InputSwitch className='ml-2' id='isAdmin' checked={get(form, 'isAdmin') ? true : false} onChange={(e) => onInputChange('isAdmin', e.value)} />
                                                </div>
                                            }
                                            {
                                                action === ACTIONS.EDIT && <div className='field flex align-items-center'>
                                                    <span className='mr-2'>Status</span>
                                                    <InputSwitch className='ml-2' id='isActive' checked={get(form, 'isActive') ? true : false} onChange={(e) => onInputChange('isActive', e.value)} />
                                                </div>
                                            }

                                            {
                                                !form.isAdmin && <>
                                                    <div className="field">
                                                        <label>Role <span className="red">*</span></label>
                                                        <div className="p-grid">
                                                            <div className="p-col-12">
                                                                <div className="p-d-flex p-flex-column">
                                                                    <Dropdown
                                                                        value={form.roleId}
                                                                        onChange={(e) => onInputChange('roleId', e.value)}
                                                                        options={roles}
                                                                        optionLabel="name"
                                                                        optionValue='roleId'
                                                                        filter
                                                                        filterBy='name'
                                                                        placeholder="Select role"
                                                                        className="w-full"
                                                                        disabled={form.isAdmin}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="field">
                                                        <label htmlFor="location">
                                                            Location <span className="red">*</span>
                                                        </label>
                                                        <MultiSelect
                                                            value={form.locationIds}
                                                            onChange={(e) => onInputChange({ locationIds: e.value })}
                                                            options={companyAllLocation}
                                                            optionLabel="name"
                                                            display='chip'
                                                            optionValue="locationId"
                                                            placeholder="Select location"
                                                            disabled={form.isAdmin}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <div className="field">
                                                <label htmlFor="firstName">
                                                    {' '}
                                                    First Name <span className="red">*</span>
                                                </label>
                                                <InputText id="firstName" value={get(form, 'firstName')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('firstName', e.target.value)} />

                                            </div>
                                            <div className="field">
                                                <label htmlFor="lastName">
                                                    {' '}
                                                    Last Name <span className="red">*</span>
                                                </label>
                                                <InputText id="lastName" value={get(form, 'lastName')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('lastName', e.target.value)} />

                                            </div>
                                            <div className="field">
                                                <label htmlFor="email">
                                                    Email <span className="red">*</span>
                                                </label>
                                                <InputText id="email" value={get(form, 'email')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('email', e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="countryCode">Mobile Number <span className="red">*</span></label>
                                                <div className="flex gap-3">
                                                    <div style={{ width: '100px' }}>
                                                        {' '}
                                                        {/* Set a fixed width for the country code */}
                                                        <InputText
                                                            id="countryCode"
                                                            value={get(form, 'countryCode')}
                                                            placeholder="+1" // Optional: Placeholder for country code
                                                            onChange={(e) => onInputChange('countryCode', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="flex-1">
                                                        <InputText
                                                            id="phone"
                                                            value={get(form, 'phone')}
                                                            placeholder="Enter phone number" // Optional: Placeholder for phone number
                                                            onChange={(e) => onInputChange('phone', e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default UserPage;
