import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Asset, CustomResponse, PurchaseItem, PurchaseOrder } from "../../types";
import { Panel } from "primereact/panel";
import { buildQueryParams, formatBytes } from "../../utils/uitl";
import { get, map } from "lodash";
import { DeleteCall, GetCall, GetPdfCall, PostCall } from "../../api/ApiKit";
import Sidebar from "../Sidebar";
import { ACTIONS } from "../../utils/constant";
import moment, { isMoment } from "moment";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import MemoizedFileItem from "../MemoizedFileItem";
import UploadFile from "../UploadFile";
import FileView from "../FileView";

interface ViewOptions {
    poId: any,
    po?: PurchaseOrder | null,
    isVisible: boolean,
    onClose: (isLoad: boolean) => void
}

export default function PurchaseOrderView({ poId = null, po = null, isVisible = false, onClose = (isLoad) => { } }: ViewOptions) {
    const navigate = useNavigate();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const [uploadVisible, setUploadVisible] = useState<boolean>(false);
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(null);
    const [pos, setPOs] = useState<any[]>([]);
    const [docs, setDocs] = useState<any[]>([]);
    const [isShowImage, setShowImage] = useState<boolean>(false);
    const [assetFile, setAssetFile] = useState<Asset | null>(null);

    useEffect(() => {
        if (isVisible && poId) {
            setDialogVisible(true);
            fetchPODetails(poId)
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible, poId]);

    const fetchPODetails = async (poId: any) => {
        if (po && po.poId != poId) {
            setDocs([]);
        }
        let params: any = {
            filters: {
                poId: poId
            },
            include: 'location,vendor,items'
        };
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/purchase-orders?${queryString}`);
        if (response.code == 'SUCCESS') {
            setPurchaseOrder(response.data[0]);
            getDocs(poId);
        } else {
            setPurchaseOrder(null);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const printDoc = async (poId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const attacheDocs = async () => {
        setUploadVisible(true)
    }

    const uploadDocs = async (assets: any) => {
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/docs`, assets);
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Upload Successfully');
            getDocs(poId);
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const getDocs = async (poId: any) => {
        setLoading(true);
        setDocs([])
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/docs?include=asset,user`);
        if (response.code == 'SUCCESS') {
            setDocs(response.data)
        } else {
            setDocs([])
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const viewImage = (file: Asset) => {
        setShowImage(true);
        setAssetFile(file)
    }

    const deleteDoc = async (file: any) => {
        const sodIds = map([file], 'id')
        console.log('sodIds', sodIds)
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/purchase-orders/${purchaseOrder?.poId}/docs`, { sodIds: sodIds });
        if (response.code == 'SUCCESS') {
            getDocs(purchaseOrder?.poId)
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const printPODoc = async (poId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/purchase-orders/${poId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const renderProduct = (item: PurchaseItem | null, option: ColumnBodyOptions) => {
        return <div>
            <small>{get(item, 'product.type')}</small>
            <p className='text-900'>{get(item, 'product.name')}</p>
        </div>
    };

    const renderTotal = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $4,000.00)
        }).format((item.price || 0) * (item.quantity || 0));
        return <div className="text-right">{formattedPrice}</div>;
    };


    if (!poId) {
        return <></>
    }

    return <>
        <Sidebar
            isVisible={dialogVisible}
            action={ACTIONS.VIEW}
            width={'60vw'}
            footerTemplate={<></>}
            title={po?.poNumber ? po?.poNumber : purchaseOrder?.poNumber}
            closeIcon={() => {
                setDialogVisible(false)
                onClose(false);
            }}
            content={<>
                <div className='flex w-full absolute bg-ligthgrey br-top br-bottom z-2' style={{ top: '4rem', left: 0 }}>
                    <div className='page-menu-item p-3 pl-5 br-right cursor-pointer' onClick={() => onClose(true)}><i className="pi pi-pencil"></i> Edit</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={() => printDoc(purchaseOrder?.poId)}><i className="pi pi-file-pdf"></i> Pdf/Print</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={() => setUploadVisible(true)}><i className="pi pi-paperclip"></i> Attach Files</div>
                </div>
                <div className='pt-8 pr-2'>
                    <div className='grid justify-content-between p-2'>
                        <div>
                            <h4>Purchase Order</h4>
                            <p>Purchase Order# <strong>{purchaseOrder?.poNumber}</strong></p>
                            <p>Order Date <strong>{isMoment(moment(purchaseOrder?.poDate)) ? moment(purchaseOrder?.poDate).format('MM/DD/YYYY') : ''}</strong></p>
                        </div>
                        <div className="text-right">
                            <p className="mb-1">Delivery Location</p>
                            <p className='text-blue cursor-pointer'><strong>{get(purchaseOrder, 'location.name', '')}</strong></p>
                            <p className="mb-1">Vendor</p>
                            <p className='text-blue cursor-pointer'><strong>{get(purchaseOrder, 'vendor.name', '')}</strong></p>
                        </div>
                    </div>
                    <div>
                        <p>Status <strong>{get(purchaseOrder, 'status.value')}</strong></p>
                    </div>
                    <div className="mt-4">
                        <h5>Line Items</h5>
                        <DataTable
                            scrollable
                            showGridlines
                            value={get(purchaseOrder, 'items', [])}
                            selectionMode="single"
                            dataKey="productId"
                            className='table-line-item'
                            // onSelectionChange={(row: any) => onRowSelect(row.value, 'view')}
                            scrollHeight="70%"
                            style={{ height: '80%' }}
                        >
                            <Column field="poDate" header="Product & Parts" style={{ width: 340, textAlign: 'left' }} body={renderProduct} ></Column>
                            <Column field="quantity" header="Quantity" style={{ width: 80, textAlign: 'right' }}></Column>
                            {/* <Column field="poDate" header="Status" body={renderShipmentStatus} style={{ width: 80, textAlign: 'right' }}></Column> */}
                            <Column
                                field="price"
                                header="Rate"
                                style={{ width: 150, textAlign: 'right' }}
                                body={(rowData) => {
                                    const formattedPrice = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                    }).format(rowData.price);

                                    return <span>{formattedPrice}</span>;
                                }}
                            ></Column>

                            <Column field="poNumber" header="Total" body={renderTotal} style={{ width: 150, textAlign: 'right' }}></Column>
                        </DataTable>
                        <div className="grid mt-3">
                            <div className="col-5 col-offset-7">
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Total</p>
                                    <p className="font-bold">
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                        }).format(purchaseOrder?.totalPrice || 0)}
                                    </p>
                                </div>

                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-bold">Paid</p>
                                    <p className="font-semibold">
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                        }).format(purchaseOrder?.paid || 0)}</p>
                                </div>
                                <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                <div className="flex justify-content-between align-items-baseline">
                                    <p className="font-semibold">Balance</p>
                                    <p className="font-bold">{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                    }).format(purchaseOrder?.balancePrice || 0)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h5>Remarks</h5>
                            <p>{purchaseOrder?.note || 'N/A'}</p>
                        </div>
                        {
                            docs != undefined && docs.length > 0 && <div className="mt-3">
                                <h5>Attachments</h5>
                                <div className='grid mt-2'>
                                    {
                                        docs.map((file: any) => (
                                            <div key={`file_${file.id}_${get(file, 'asset.name')}`} className='col-12 flex'>
                                                <div className="flex gap-3 flex-column p-2 shadow-2 border-round align-items-center text-center border-round-sm mr-4" style={{ maxWidth: 250 }}>
                                                    <MemoizedFileItem edit={true} key={`file_image_${get(file, 'asset.assetId')}_${get(file, 'asset.name')}`} file={file.asset} onView={() => viewImage(file.asset)} onDelete={() => deleteDoc(file)} />
                                                </div>
                                                <div className="file-info flex flex-column  mb-2">
                                                    <p className='m-0  sub-desc text-xl font-bold text-overflow-ellipsis' style={{ overflow: 'hidden' }}>{get(file, 'asset.name')}</p>
                                                    <p className='m-0 mt-2 sub-desc'>{get(file, 'asset.type', '').toUpperCase()} • {formatBytes(get(file, 'asset.sizeInBytes'))}</p>
                                                    <p className='m-0 mt-2 sub-desc text-overflow-ellipsis'>{get(file, 'comment', '')}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>}
        />
        <UploadFile
            isVisible={uploadVisible}
            onSelect={(option: any) => {
                setUploadVisible(false);
                if (option && option.length > 0) {
                    let assets = option.map((item: any) => ({
                        assetId: item.assetId,
                        comment: item.comment || ''
                    }))
                    uploadDocs(assets);
                }
            }}
        />
        <FileView
            isVisible={isShowImage}
            assetFile={assetFile}
            onClose={() => setShowImage(false)}
        />
    </>
}