import { get } from 'lodash';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../layout/AppWrapper';
import { PostPdfCall } from '../api/ApiKit';
import { Dialog } from 'primereact/dialog';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

interface Options {
    url: string,
    type: string,
    optionLabel: string,
    optionValue: string,
    ids: any[],
    onSuccess?: any,
    onError?: any
}

const DownloadBarcodeButton = ({ url = '', type = '', optionLabel, optionValue, ids = [], onSuccess = () => { }, onError = (message: any) => { } }: Options) => {
    const { setAlert, setLoading } = useAppContext();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [rangeType, setRangeType] = useState('range');
    const [selectedIds, setSelectedIds] = useState([]);
    const [fromId, setFromId] = useState<any>(null);
    const [toId, setToId] = useState<any>(null);

    useEffect(()=> {
        closeIcon();
    }, [])

    const generateBarcodes = async () => {
        if (isDownloading) return; // Prevent duplicate requests

        if (rangeType === 'range') {
            if (fromId > toId || !fromId || !toId) {
                setAlert('error', 'Please provide valid range of ids');
                return;
            }
            url += `?from=${fromId}&to=${toId}`
        }

        setIsDownloading(true);
        setLoading(true);
        try {
            const result = await PostPdfCall(url, { type, ids: selectedIds });
            if (result && result.code === 'FAILED') {
                setAlert('error', result.message);
                onError(result.message);
            } else {
                onSuccess();
            }
        } catch (error: any) {
            setAlert('error', error.message || 'Something went wrong!');
            onError(error.message);
        } finally {
            setIsDownloading(false);
            setLoading(false);
        }
    };

    const closeIcon = () => {
        setIsVisible(false)
        setFromId(null)
        setToId(null)
        setRangeType('');
        setSelectedIds([])
    }

    return <div>
        <Button
            type="button"
            size="small"
            icon={`pi ${isDownloading ? 'pi-spin pi-spinner' : 'pi-download'}`}
            label="Barcodes"
            onClick={() => setIsVisible(true)}
            data-pr-tooltip="Generate barcodes"
            disabled={isDownloading}
        />
        <Dialog
            visible={isVisible}
            onHide={closeIcon}
            header={'Download Barcodes'}
            footer={<div className="flex justify-content-end p-2">
                <div>
                    <Button label="Cancel" size="small" severity="secondary" text onClick={closeIcon} />
                    <Button icon={isDownloading ? 'pi pi-spin pi-spinner' : ''} label="Download" size="small" text onClick={generateBarcodes} />
                </div>
            </div>}
            style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        >
            <div>
                <div className="flex align-items-center">
                    <RadioButton inputId="ingredient1" name="range" value="range" onChange={(e) => setRangeType(e.value)} checked={rangeType === 'range'} />
                    <label htmlFor="ingredient1" className="ml-2 cursor-pointer">Range</label>
                </div>
                {
                    rangeType === 'range' && <div className='flex align-items-center justify-content-between p-4'>
                        <InputNumber value={fromId} onValueChange={(e) => setFromId(e.value)} placeholder='eg. 1' className='w-full' inputClassName='w-full' />
                        <span className='pl-2 pr-2'>to</span>
                        <InputNumber value={toId} onValueChange={(e) => setToId(e.value)} placeholder='eg. 100' className='w-full' inputClassName='w-full' />
                    </div>
                }
                <div className="flex align-items-center mt-4">
                    <RadioButton inputId="ingredient2" name="range" value="ids" onChange={(e) => setRangeType(e.value)} checked={rangeType === 'ids'} />
                    <label htmlFor="ingredient2" className="ml-2 cursor-pointer">All</label>
                </div>
                {
                    rangeType !== 'range' && <div className='flex mt-4 pl-4 pr-4'></div>
                }
            </div>

        </Dialog>
    </div>;
};

export default DownloadBarcodeButton;
