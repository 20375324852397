import { useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { filter, find, get, groupBy, map, set, sumBy } from "lodash";
import moment, { isMoment } from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useAppContext } from "../../layout/AppWrapper";
import Sidebar from "../Sidebar";
import { ACTIONS, constant } from "../../utils/constant";
import CustomPanel from "../CustomPanel";
import { CustomResponse, MasterCode, Product, PurchaseItem, PurchaseOrder, SalesOrder, SalesOrderItem, SalesReturnItems, SalesReturns, Vendor, Warehouse } from "../../types";
import { GetCall, PostCall, PutCall } from "../../api/ApiKit";
import { Message } from "primereact/message";
import { InputSwitch } from "primereact/inputswitch";
import { buildQueryParams } from "../../utils/uitl";
import { DEFAULT_SALES_RETURNS } from "../../types/default-forms";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/redux-store";


interface SalesReturnsEditorOptions {
    isEdit?: boolean,
    rmaId?: any,
    soId?: any,
    isVisible: boolean,
    action?: any,
    salesReturn?: SalesReturns | null,
    onClose: (isLoad: boolean) => void
}

const defaultSalesReturns: SalesReturns = {
    rmaId: null,
    rmaNumber: null,
    companyId: null,
    soId: null,
    vendorId: null,
    rmaDate: null,
    statusId: null,
    reasonId: null,
    trackingTypeId: null,
    trackingRef: null,
    items: []
}

export default function SalesReturnsEditor({ isEdit = false, rmaId = null, soId = null, isVisible = false, action = '', salesReturn, onClose = (isLoad) => { } }: SalesReturnsEditorOptions) {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const containerRef = useRef(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [salesReturnDetails, setSalesReturn] = useState<SalesReturns>({ ...defaultSalesReturns });
    const [productItems, setProductItems] = useState<any[]>([]);
    const [allProductItems, setAllProductItems] = useState<any[]>([]);
    const [reasons, setReasons] = useState<any[]>([]);
    const [statuses, setStatuses] = useState<any[]>([]);
    const [isGrouped, setIsGrouped] = useState<boolean>(false);
    const [salesOrders, setSalesOrders] = useState<SalesOrder[]>([]);
    const [soSearch, setSOSearch] = useState<any>('');
    const trackings = useSelector((state: ReduxStoreState) => state.carriers.carriers);

    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true);
            fetchReasons();
            fetchStatuses();
            if (!soId) {
                fetchSo()
            }
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);

    useEffect(() => {
        if (salesReturn) {
            setSalesReturn(salesReturn)
        }
        else {
            setSalesReturn({ ...defaultSalesReturns })
            if (soId) {
                updateItem('soId', soId);
            }
        }
    }, [salesReturn, soId])

    useEffect(() => {
        if (rmaId) {
            fetchRMADetails(rmaId)
        }
    }, [rmaId])

    useEffect(() => {
        if (salesReturnDetails.soId) {
            fetchSoProducts(salesReturnDetails.soId)
        }
    }, [salesReturnDetails.soId])

    const fetchRMADetails = async (_rmaId: any) => {
        let params: any = {
            filters: {
                rmaId: _rmaId
            },
            include: 'customer,status,items,so'
        };
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/sales-returns?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesReturn(response.data[0])
        } else {
            setSalesReturn({ ...DEFAULT_SALES_RETURNS });
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const fetchSo = async (soNumber = '') => {
        let params: any = {
            filters: {},
            limit: 500,
            page: 0,
            include: 'customer,having_packages'
        };

        if (soNumber) {
            params.filters['soNumber'] = soNumber.toUpperCase();
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-orders?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesOrders(response.data);
        } else {
            setSalesOrders([]);
        }
        setLoading(false);
    }

    const fetchSoProducts = async (_soId = null) => {
        if (!user?.company?.companyId) {
            return;
        }
        if (!_soId) {
            _soId = soId;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/items-to-return/${_soId}`);
        if (response.code == 'SUCCESS') {
            setAllProductItems(response.data);
            setProductItems(response.data);
        } else {
            setAllProductItems([])
            setProductItems([]);
        }
        setLoading(false);
    };

    const fetchReasons = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.ReturnReasons}`);
        if (response.code == 'SUCCESS') {
            setReasons(response.data);
        } else {
            setReasons([]);
        }
        setLoading(false);
    };

    const fetchStatuses = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.ReturnStatus}`);
        if (response.code == 'SUCCESS') {
            setStatuses(response.data);
        } else {
            setStatuses([]);
        }
        setLoading(false);
    };

    const onFilter = (e: any) => {
        e.originalEvent.stopPropagation();
        if (e.filter) {
            const result = allProductItems.filter(item => {
                const REID = get(item, 'pItem.REID', '').toLowerCase();
                const pname = get(item, 'pItem.product.name', '').toLowerCase();
                return item.skuId == e.filter.toLowerCase() || REID.indexOf(e.filter.toLowerCase()) !== -1 || pname.indexOf(e.filter.toLowerCase()) !== -1;
            });
            setProductItems(result)
        }
        else {
            setProductItems(allProductItems)
        }
    }

    const closeIcon = () => {
        onClose(false);
    }

    const onSave = async () => {
        if (!salesReturnDetails.rmaDate || !isMoment(moment(salesReturnDetails.rmaDate))) {
            setAlert('error', 'Please provide valid RMA date');
            return;
        }
        if (!salesReturnDetails.reasonId) {
            setAlert('error', 'Please provide valid reason');
            return;
        }

        if (isEdit && !salesReturnDetails.statusId) {
            setAlert('error', 'Please provide valid status');
            return;
        }

        if (salesReturnDetails.items.length == 0) {
            setAlert('error', 'Please add atleast one product to return');
            return;
        }

        setLoading(true);

        if (isEdit) {
            const response: CustomResponse = await PutCall(`/company/${user?.company?.companyId}/sales-returns/${salesReturnDetails?.rmaId}`, salesReturnDetails);
            if (response.code == 'SUCCESS') {
                setAlert('success', 'Sales return updated')
                onClose(true);
            } else {
                setAlert('error', response.message)
            }
        }
        else {
            const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/sales-returns/${salesReturnDetails.soId}`, salesReturnDetails);
            if (response.code == 'SUCCESS') {
                setAlert('success', 'Sales return created')
                onClose(true);
            } else {
                setAlert('error', response.message)
            }
        }
        setLoading(false);

    }

    const updateItem = async (key: string, value: any) => {
        const _pOrder: SalesReturns = JSON.parse(JSON.stringify(salesReturnDetails));
        set(_pOrder, key, value);
        if (key === 'soId') {
            let findDoc = find(salesOrders, { soId: +value })
            if (findDoc) {
                set(_pOrder, 'so', findDoc);
            }
        }
        setSalesReturn(_pOrder);
    }

    const onProductSelect = (pItemId: number) => {
        let product = find(productItems, { pItemId: pItemId });
        let _items = [...get(salesReturnDetails, 'items', [])];
        let isPresent = find(_items, { pItemId: pItemId });
        if (!isPresent) {
            _items.push({
                rmaItemId: undefined,
                companyId: product?.companyId,
                rmaId: undefined,
                skuId: product?.skuId,
                pItemId: product.pItemId,
                quantity: 1,
                price: product?.price,
                conditionId: null,
                product: get(product.pItem, 'product', null),
                pItem: product.pItem
            });
            updateItem('items', _items);
            setAlert('info', 'Added')
        } else {
            setAlert('info', 'Product already added');
        }
    };

    const removeItem = (skuId: number | null) => {
        if (!skuId) return;
        let _items = [...salesReturnDetails.items];
        _items = _items.filter((item) => item.skuId != skuId);
        updateItem('items', _items);
    };

    const renderProduct = (item: SalesReturnItems | null) => {
        if (item?.skuId) {
            if (isGrouped) {
                return <div>
                    <span className='text-sm'>{get(item, 'skuId')}</span><br />
                    <label className='text-900'>{get(item, 'product.name')}</label>
                </div>
            }

            return <div>
                <span className='text-sm'>LPN: {get(item, 'pItem.REID')}</span><br />
                <label className='text-900'>{get(item, 'product.name')}</label>
            </div>
        }

        return (
            <Dropdown
                value={item?.pItemId}
                filter={true}
                // onFilter={onFilter}
                onChange={(e) => onProductSelect(e.value)}
                valueTemplate={selectedProductTemplate}
                itemTemplate={productOptionTemplate}
                options={productItems.filter((item) => !map(get(salesReturnDetails, 'items', []), 'pItemId').includes(item.pItemId))}
                optionLabel="name"
                optionValue="pItemId"
                filterBy="pItemId"
                showFilterClear
                placeholder="Select a Product"
                className="w-30rem"
            />
        );
    };

    const renderGroupCross = (item: SalesReturnItems) => {
        if (!item?.skuId) {
            return <div style={{ width: 80 }}></div>;
        }
        return (
            <div className="flex align-items-center">
                <Button icon="pi pi-times" severity="danger" aria-label="Cancel" size="small" onClick={() => removeItem(get(item, 'skuId', null))} />
            </div>
        );
    };

    const renderCross = (item: SalesReturnItems) => {
        if (!item?.skuId) {
            return <div style={{ width: 80 }}></div>;
        }
        return (
            <div className="flex align-items-center">
                <Button icon="pi pi-times" severity="danger" aria-label="Cancel" size="small" onClick={() => removeItem(item.skuId)} />
            </div>
        );
    };

    const selectedProductTemplate = (option: Product, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-start">
                    <div className="text-base">{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const productOptionTemplate = (option: SalesOrderItem) => {
        return (
            <div className="flex justify-content-between flex-wrap">
                <div className="flex flex-column">
                    <div className="text-xs text-grey">LPN: {get(option, 'pItem.REID')}</div>
                    <div className="text-base">{get(option, 'pItem.product.name', '')}</div>
                </div>
            </div>
        );
    };

    const customContent = (
        <span className="p-input-icon-right w-full">
            <IconField>
                <InputIcon className={`pi ${isLoading ? 'pi-spin pi-spinner' : 'pi-search'}`}> </InputIcon>
                <InputText value={soSearch}
                    onChange={(e) => setSOSearch(e.target.value)}
                    placeholder="Search SO #"
                    style={{ width: "100%" }}
                    autoFocus />
            </IconField>
        </span>
    );

    const grouped = groupBy(get(salesReturnDetails, 'items', []), 'skuId');
    const groupData = Object.keys(grouped).map((key) => ({
        skuId: key,
        quantity: grouped[key].length,
        price: get(grouped[key], '0.price'),
        product: get(grouped[key], '0.product'),
        pItems: grouped[key],
    }));
    return (
        <>
            <Sidebar
                isVisible={dialogVisible}
                action={isEdit ? ACTIONS.EDIT : ACTIONS.ADD}
                width={'60vw'}
                title={isEdit ? (salesReturnDetails.rmaNumber ? salesReturnDetails.rmaNumber : '') : 'New Sales Return'}
                closeIcon={closeIcon}
                onSave={onSave}
                content={<>
                    {
                        soId == null && !isEdit && <div className='grid p-0'>
                            <div className='col-6'>
                                <div className="field p-0">
                                    <label htmlFor="name3" className="w-full ">
                                        Sales Order<span className='text-red'>*</span>
                                    </label>
                                    <div className="w-full">
                                        <Dropdown
                                            value={salesReturnDetails.soId}
                                            filter
                                            disabled={salesReturn ? true : false}
                                            onChange={(e) => updateItem('soId', e.value)}
                                            options={salesOrders}
                                            optionValue="soId"
                                            optionLabel="soId"
                                            placeholder="Select a Sales Order"
                                            className={`w-full ${isSubmitted && soId ? 'p-invalid' : ''}`}
                                            required={true}
                                            filterTemplate={customContent}
                                            filterBy='soNumber'
                                            valueTemplate={(option: any) => {
                                                return <>
                                                    <small className='small-desc'>{get(salesReturnDetails, 'so.customer.name')}</small>
                                                    <p>{get(salesReturnDetails, 'so.soNumber', 'Select a Sales Order')}</p>
                                                </>
                                            }}
                                            itemTemplate={(option) => <>
                                                <small className='small-desc'>{get(option, 'customer.name')}</small>
                                                <p>{get(option, 'soNumber')}</p>
                                            </>}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="grid">
                        <div className='col-6'>
                            <div className="field p-0">
                                <label htmlFor="name3" className="w-full ">
                                    RMA Date<span className='text-red'>*</span>
                                </label>
                                <div className="w-full">
                                    <Calendar appendTo={'self'} value={salesReturnDetails?.rmaDate ? moment(salesReturnDetails?.rmaDate).toDate() : null} onChange={(e) => updateItem('rmaDate', e.value)} placeholder="MM/DD/YYYY" className={`w-full ${isSubmitted && !salesReturnDetails.rmaDate ? 'p-invalid' : ''}`} showIcon required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="field">
                                <label htmlFor="name3" className="w-full">
                                    Reason<span className='text-red'>*</span>
                                </label>
                                <div className="w-full">
                                    <Dropdown value={salesReturnDetails.reasonId} onChange={(e) => updateItem('reasonId', e.value)} options={reasons} optionLabel="code" optionValue="masterCodeId" placeholder="Reason" className="w-full" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="field">
                                <label htmlFor="name3" className="w-full">
                                    Shipping Mode
                                </label>
                                <div className="w-full">
                                    <Dropdown value={salesReturnDetails.trackingTypeId} onChange={(e) => updateItem('trackingTypeId', e.value)} options={trackings} optionLabel="code" optionValue="masterCodeId" placeholder="Shipment carrier" className="w-full" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="field">
                                <label htmlFor="name3" className="w-full">
                                    Tracking Number
                                </label>
                                <div className="w-full">
                                    <InputText value={salesReturnDetails?.trackingRef || ''} onChange={(e) => updateItem('trackingRef', e.target.value)} placeholder="Tracking Number" className="w-full" />
                                </div>
                            </div>
                        </div>
                        {
                            isEdit && <div className="col-6">
                                <div className="field">
                                    <label htmlFor="name3" className="w-full">
                                        Status<span className='text-red'>*</span>
                                    </label>
                                    <div className="w-full">
                                        <Dropdown value={salesReturnDetails.statusId} onChange={(e) => updateItem('statusId', e.value)} options={statuses} optionLabel="code" optionValue="masterCodeId" placeholder="Status" className="w-full" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <Message
                        style={{
                            color: '#696cff'
                        }}
                        className="w-full justify-content-start mb-2"
                        severity="warn"
                        content={<div className="flex align-items-center">
                            <InputSwitch checked={isGrouped} onChange={(e) => setIsGrouped(e.value)} />
                            <div className="text-warn ml-2">Show items by product group</div>
                        </div>}
                    />
                    <DataTable
                        scrollable
                        showGridlines
                        value={isGrouped ? [...groupData, defaultSalesReturns] : [...get(salesReturnDetails, 'items', []), defaultSalesReturns]}
                        selectionMode="single"
                        dataKey="productId"
                        className='table-line-item'
                        scrollHeight="70%"
                        style={{ height: '80%' }}
                        emptyMessage={'No items added'}
                    >
                        <Column field="soDate" header="Product & SKU" body={(data, options: ColumnBodyOptions) => renderProduct(data)}></Column>
                        {
                            isGrouped && <Column field="quantity" header="Quantity" style={{ width: 80, textAlign: 'right' }}></Column>
                        }
                        <Column field="price" header="Price" style={{ width: 80, textAlign: 'right' }}></Column>
                        <Column style={{ width: 30 }} body={isGrouped ? renderGroupCross : renderCross}></Column>
                    </DataTable>
                    <div className="grid mt-3">
                        <div className="col-4 col-offset-8">
                            <div className="flex justify-content-between align-items-baseline">
                                <p className="font-semibold">Total</p>
                                <p className="font-bold">${sumBy(get(salesReturnDetails, 'items', []), (item) => item.quantity * item.price)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid mt-3">
                        <h5>Remarks</h5>
                        <InputTextarea className="w-full" value={salesReturnDetails.note || ''} onChange={(e) => updateItem('note', e.target.value)} rows={5} cols={30} style={{ resize: 'none' }} />
                    </div>
                </>}
            />
        </>
    )
}