


import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { Category, CompanyUser, CustomResponse, Product, Roles } from '../../../types';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, head, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { EmptyUser } from '../../../types/forms';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, getRowLimitWithScreenHeight, validateCountryCode, validateEmail, validateName, validatePhoneNumber, validateSubdomain } from '../../../utils/uitl';
import { COMPANIES_MENU, COMPANY_MENU, CompanyModule, CompanyWrite, DashboardModule } from '../../../config/permissions';
import { InputSwitch } from 'primereact/inputswitch';
import DefaultLogo from '../../../components/DefaultLogo';
import RightSidePanel from '../../../components/RightSidePanel';
import { RadioButton } from 'primereact/radiobutton';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import SKUView from '../../../components/Views/SKUView';
import AppPage from '../../../layout/AppPage';
import ProductEditor from '../../../components/Editors/ProductEditor';
import BulkUploader from '../../../components/BulkUploader';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptyUser = {
    companyUserId: null,
    companyId: null,
    user: {
        companyUserId: null,
        displayName: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: null,
        countryCode: '',
        isActive: true,
        location: '',
        userId: null,
        profile: ''
    }
};

const ProductListPage = forwardRef((props, ref) => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const companyId = get(user, 'company.companyId');
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<CompanyUser[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [action, setAction] = useState<any>(null);

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [allCategory, setAllCategory] = useState<any>([]);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);


    useEffect(() => {
        fetchData();
        fetchCategories();
    }, []);

    useImperativeHandle(ref, () => ({
        showAddNew: showAddNew
    }));

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        params.include = 'category';
        if (params.filters && params.filters.category) {
            params.filters.categoryId = params.filters.category.categoryId;
            delete params.filters.category;
        }

        if (params.filters) {
            params.filters['type'] = 'Product';
        }
        else {
            params.filters = { type: 'Product' }
        }

        const companyId = get(user, 'company.companyId');
        setLoading(true);

        // Build the query string
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/products?${queryString}`);

        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            // fetchPermissions();
            if (response.total) {
                setTotalRecords(response.total);
            }
        } else {
            setCompanies([]);
        }
    };
    const fetchCategories = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/categories`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setAllCategory(response.data);

        } else {
            setAllCategory([]);
        }
    };

    const fetchViewData = async (params?: any) => {
        const companyId = get(user, 'company.companyId');
        const productId = params.productId
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/products?filters.productId=${productId}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            if (response.data && response.data.length > 0) {
                setSelectedProduct(response.data[0]);
            } else {
                setSelectedProduct(null);
            }
        } else {
            setSelectedProduct(null);
        }
    };

    const closeIcon = () => {
        setSelectedProduct(null);
        setIsShowSplit(false);
        setAction(null);
        setIsViewModalOpen(false);
    };

    const showAddNew = () => {
        setAction(ACTIONS.ADD)
    };

    const onRowSelect = async (company: Product, action: any) => {
        await setSelectedProduct(company);
        setAction(action);
        if (action == ACTIONS.DELETE) {
            return;
        }

        if (action === ACTIONS.VIEW) {
            setIsViewModalOpen(true);
        }
    };

    const categoryDropdown = (options: any) => (
        <Dropdown
            filter={true}
            value={options.value || null}
            options={allCategory}
            optionLabel="name"
            optionValue="categoryId"
            onChange={(e) => {
                options.filterApplyCallback({ categoryId: e.value });
            }}
            placeholder="Select category"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );

    return (
        <>
            <CustomDataTable
                ref={dataTableRef}
                filter={true}
                page={page}
                limit={limit}
                totalRecords={totalRecords}
                title='Products'
                headerButtons={[
                    {
                        icon: 'pi pi-file-excel',
                        label: 'Bulk',
                        onClick: () => setIsFileUpload(true)
                    },
                    {
                        icon: 'pi pi-plus',
                        label: 'New',
                        onClick: () => showAddNew()
                    }
                ]}
                data={companies.map((item: any) => ({
                    productId: item.productId,
                    category: item.category.name,
                    skuId: item.skuId,
                    categoryId: item.category.categoryId,
                    name: item.name
                }))}
                columns={[
                    {
                        header: '#',
                        field: 'productId',
                        filter: true,
                        sortable: true,
                        bodyStyle: { width: 80, height: '50px' },
                        filterPlaceholder: 'Id'
                    },
                    {
                        header: 'Category',
                        field: 'category',
                        filter: true,
                        filterElement: categoryDropdown,
                        filterPlaceholder: 'Search Category'
                    },
                    {
                        header: 'SKU',
                        field: 'skuId',
                        filter: true,
                        filterPlaceholder: 'Search SKU'
                    },
                    {
                        header: 'Name',
                        field: 'name',
                        filter: true,
                        filterPlaceholder: 'Search name'
                    }
                ]}
                onLoad={(params: any) => fetchData(params)}
                onView={(item: any) => onRowSelect(item, 'view')}
            />
            {/* // bulk upload  */}
            <BulkUploader
                isVisible={isFileUpload}
                url={`/company/${companyId}/bulk-upload-products`}
                fileInputColumns={['SKU', 'Title']}
                closeIcon={(isLoad: boolean) => {
                    setIsFileUpload(false);
                    setSelectedCategory(null);
                    if (isLoad) {
                        fetchData()
                    }
                }}
                formData={{ categoryId: selectedCategory }}
                requiredFormData={['categoryId']}
                formContent={<div className='mt-3'>
                    <div className="field p-0">
                        <label htmlFor="name3" className="w-full ">
                            Category <span className="text-red">*</span>
                        </label>
                        <Dropdown
                            filter={true}
                            value={selectedCategory}
                            options={allCategory}
                            optionLabel="name"
                            optionValue="categoryId"
                            onChange={(e) => setSelectedCategory(e.value)}
                            placeholder="Select category"
                            className="p-column-filter"
                            showClear
                            style={{ minWidth: '20rem' }}
                        />
                    </div>
                </div>}
            />
            {/* Add Product */}
            {
                action === ACTIONS.ADD && <ProductEditor
                    isVisible={true}
                    onClose={(isLoad: any) => {
                        closeIcon();
                        if (isLoad) {
                            fetchData();
                        }
                    }}
                />
            }
        </>
    );
});

export default ProductListPage;
