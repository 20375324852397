import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Asset, CustomResponse, SalesOrder, Vendor, VendorAddresses, VendorPOC } from "../../types";
import { useAppContext } from "../../layout/AppWrapper";
import { Panel } from "primereact/panel";
import { buildQueryParams, formatBytes, validateEmail, validateName, validateNumberAndString, validatePhoneNumber, validateZipNumber } from "../../utils/uitl";
import { get, iteratee, map, set } from "lodash";
import { DeleteCall, GetCall, PostCall, PutCall } from "../../api/ApiKit";
import Sidebar from "../Sidebar";
import { ACTIONS, VENDOR_TYPE } from "../../utils/constant";
import { TabPanel, TabView } from "primereact/tabview";
import { DataView } from "primereact/dataview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MemoizedFileItem from "../MemoizedFileItem";
import FileView from "../FileView";
import { Link, useSearchParams } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import MemoizedSuppliedTab from "../SupplierParts";

interface PackageEditorOptions {
    title?: string,
    isVisible: boolean,
    vendorId: any;
    type?: any,
    vendor?: Vendor | null,
    onClose: (isLoad: any) => void
}

const tabType = {
    DETAILS: 'Details',
    PURCHASE_ORDER: 'Purchase Orders',
    SUPPLIED_PRODUCTS: 'Supplied Products',
    ATTACHMENTS: 'Attachments',
    SALES_ORDERS: 'Sales Orders'
}

const VendorViewer = forwardRef(({ isVisible = false, type = VENDOR_TYPE.VENDOR, vendorId, vendor, onClose = (isLoad) => { } }:PackageEditorOptions, panelRef) => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [data, setData] = useState<Vendor | null>(null);
    const [viewIndex, setViewIndex] = useState(0);
    const [docs, setDocs] = useState<any[]>([]);
    const [pos, setPOs] = useState<VendorPOC[]>([]);
    const [so, setSOs] = useState<SalesOrder[]>([]);

    const [assetFile, setAssetFile] = useState<Asset | null>(null);
    const [isShowImage, setShowImage] = useState<boolean>(false);

    const [isShowPOC, setShowPOC] = useState<boolean>(false);
    const [isShowAddress, setShowAddress] = useState<boolean>(false);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const [pocForm, setPOCForm] = useState<VendorPOC | null>(null);
    const [addressForm, setAddressForm] = useState<VendorAddresses | null>(null);
    const [action, setAction] = useState<any>(null);

    useImperativeHandle(panelRef, () => ({
        fetchDocs: fetchDocs
    }));
    
    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true);
            setViewIndex(0);
            setDocs([]);
            setPOs([]);
            if (vendorId) {
                if (vendor) {
                    setData(vendor)
                }
                fetchData();
            }
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible, vendorId]);

    useEffect(() => {
        switch (viewIndex) {
            case 0:
                fetchData();
                break;
            case 1:
                fetchPOs();
                break;
            case 2:
                break;
            case 3:
                fetchDocs();
                break;
            default:
                break;
        }
    }, [viewIndex])

    const onChangeTab = (tabIndex: any) => {
        console.log(type, tabIndex);
        setViewIndex(tabIndex)
        switch (tabIndex) {
            case 0:
                {
                    fetchData();
                }
                break;
            case 1:
                {
                    if (type === VENDOR_TYPE.VENDOR) {
                        fetchPOs();
                    }
                    else if (type === VENDOR_TYPE.CUSTOMER) {
                        fetchSOs();
                    }
                }
                break;
            case 2:
                if (type === VENDOR_TYPE.CUSTOMER) {
                    fetchSOs();
                }
                break;
            case 3:
                if (type === VENDOR_TYPE.VENDOR) {
                    fetchDocs();
                }
                break;
            case 4:
                fetchDocs();
                break;
            default:
                break;
        }
    }

    const closeIcon = () => {
        if (isEdit) {
            setEdit(false);
            return;
        }
        onClose(false);
    }

    const fetchData = async () => {
        if (!vendorId) {
            return;
        }
        const params = {
            filters: { vendorId: vendorId },
            query: 'pocs,address,vendorCategories,vendorWarehouses,VendorGrades,businessType,industryType,shipCarrier,shipmentPref'
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/vendors/${vendorId}?${queryString}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setData(response.data);
        } else {
            setData(null);
        }
    }

    const fetchPOs = async () => {
        const companyId = get(user, 'company.companyId');
        const params = {
            limit: 5,
            filters: { vendorId: vendorId },
            sortBy: 'createdAt',
            sortOrder: 'desc'
        }
        const queryString = buildQueryParams(params);
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/purchase-orders?${queryString}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setPOs(response.data);
        } else {
            setPOs([]);
        }
    }

    const fetchSOs = async () => {
        console.log('fetchSO')
        const companyId = get(user, 'company.companyId');
        const params = {
            limit: 5,
            filters: { vendorId: vendorId },
            sortBy: 'createdAt',
            sortOrder: 'desc'
        }
        const queryString = buildQueryParams(params);
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-orders?${queryString}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setSOs(response.data);
        } else {
            setSOs([]);
        }
    }

    const fetchDocs = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/vendors/${vendorId}/attachments`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setDocs(response.data);
        } else {
            setDocs([]);
        }
    }

    const onSave = () => {
        onClose(true);
    }

    const viewImage = (file: Asset) => {
        setShowImage(true);
        setAssetFile(file)
    }

    const updatePOCItem = (key: any, value: any) => {
        let _poc = pocForm ? { ...pocForm } : {};
        set(_poc, key, value);
        setPOCForm(_poc as VendorPOC)
    }

    const updateAddressItem = (key: any, value: any) => {
        let _address = addressForm ? { ...addressForm } : {};
        set(_address, key, value);
        setAddressForm(_address as VendorAddresses)
    }

    const showAddNew = (type: any, item?: any) => {
        switch (type) {
            case 'poc':
                setShowPOC(true)
                if (item) {
                    setAction(ACTIONS.EDIT)
                    setPOCForm(item)
                }
                else {
                    setAction(ACTIONS.ADD)
                }
                break;
            case 'address':
                setShowAddress(true)
                if (item) {
                    setAction(ACTIONS.EDIT)
                    setAddressForm(item)
                }
                else {
                    setAction(ACTIONS.ADD)
                }
                break;
            default:
                break;
        }
    }

    const closeSubModal = () => {
        setAction(null);
        setShowAddress(false)
        setShowPOC(false);
        setPOCForm(null)
        setAddressForm(null);
        setSubmitted(false);
    }

    const savePOC = async () => {
        setSubmitted(true)
        console.log(pocForm)
        if (!validateEmail(pocForm?.email || '') || !validatePhoneNumber(pocForm?.phone || '') || !pocForm?.name) {
            return;
        }
        let payload: any = {
            ...pocForm,
            firstName: pocForm.name.split(' ')[0] || pocForm.name,
            lastName: get(pocForm, 'name', '').split(' ').length > 1 ? pocForm.name.split(' ')[1] : '',
        }

        if (action === ACTIONS.EDIT) {
            const companyId = get(user, 'company.companyId');
            setLoading(true);
            const response: CustomResponse = await PutCall(`/company/${companyId}/vendors/${vendorId}/pocs/${pocForm.vendorPOCId}`, payload);
            setLoading(false);
            if (response.code == 'SUCCESS') {
                fetchData();
                closeSubModal();
            } else {
                setAlert('error', response.message)
            }
        }
        else {
            const companyId = get(user, 'company.companyId');
            setLoading(true);
            const response: CustomResponse = await PostCall(`/company/${companyId}/vendors/${vendorId}/pocs`, payload);
            setLoading(false);
            if (response.code == 'SUCCESS') {
                fetchData();
                closeSubModal();
            } else {
                setAlert('error', response.message)
            }
        }
    }

    const saveAddress = async () => {
        setSubmitted(true)
        console.log(addressForm)

        if (!validateNumberAndString(addressForm?.address2 || '')) {
            setAlert('error', 'Please provide a Street Address');
            return;
        }
        if (!validateName(addressForm?.city || '')) {
            setAlert('error', 'Please provide a valid City');
            return;
        }
        if (!validateName(addressForm?.state || '')) {
            setAlert('error', 'Please provide a valid State');
            return;
        }
        if (!validateZipNumber(addressForm?.zip || '')) {
            setAlert('error', 'Please provide a valid Zip Code');
            return;
        }

        let payload: any = {
            ...addressForm,
            type: 'Other'
        }

        if (action === ACTIONS.EDIT) {
            const companyId = get(user, 'company.companyId');
            setLoading(true);
            const response: CustomResponse = await PutCall(`/company/${companyId}/vendors/${vendorId}/addresses/${addressForm?.sAddrId}`, payload);
            setLoading(false);
            if (response.code == 'SUCCESS') {
                fetchData();
                closeSubModal();
            } else {
                setAlert('error', response.message)
            }
        }
        else {
            const companyId = get(user, 'company.companyId');
            setLoading(true);
            const response: CustomResponse = await PostCall(`/company/${companyId}/vendors/${vendorId}/addresses`, payload);
            setLoading(false);
            if (response.code == 'SUCCESS') {
                fetchData();
                closeSubModal();
            } else {
                setAlert('error', response.message)
            }
        }
    }

    const confirmDelete = (type: any, item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: async () => {
                console.log(type, item)
                if (type === 'poc') {
                    const companyId = get(user, 'company.companyId');
                    setLoading(true);
                    const response: CustomResponse = await DeleteCall(`/company/${companyId}/vendors/${vendorId}/pocs/${item.vendorPOCId}`);
                    setLoading(false);
                    if (response.code == 'SUCCESS') {
                        fetchData();
                    } else {
                        setAlert('error', response.message)
                    }
                }
                else if (type === 'address') {
                    const companyId = get(user, 'company.companyId');
                    setLoading(true);
                    const response: CustomResponse = await DeleteCall(`/company/${companyId}/vendors/${vendorId}/addresses/${item.sAddrId}`);
                    setLoading(false);
                    if (response.code == 'SUCCESS') {
                        fetchData();
                    } else {
                        setAlert('error', response.message)
                    }
                }
            },
        });
    }

    const deleteDoc = async (file: any) => {
        const assetIds = map([file], 'assetId')
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/vendors/${vendorId}/attachments`, { assetIds: assetIds });
        if (response.code == 'SUCCESS') {
            fetchDocs()
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const formatItems = (arr: any[], key: any, limit = 3) => {
        if (!arr || (arr && arr.length === 0)) {
            return ''
        }
        const items = arr?.map((item) => get(item, key)).filter((el) => el);
        return items.join(', ')
    }
    const formatLimitedItems = (arr: any[], key: any, limit = 3) => {
        if (!arr || (arr && arr.length === 0)) {
            return ''
        }
        const items = arr?.map((item) => get(item, key)).filter((el) => el);

        let fullText = items.join(', ')
        return items.length > limit ? [...items.slice(0, limit), `more (${items.length - limit})`].join(', ') : items.join(', ');
    }

    const renderLoader = (isLoading ? <div className="flex absolute z-2 w-10 h-20rem align-items-center">
        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
    </div> : <></>)

    return <>
        <ConfirmDialog />
        <Sidebar
            isVisible={dialogVisible}
            action={isEdit ? ACTIONS.EDIT : ACTIONS.VIEW}
            width={'60vw'}
            title={data?.name}
            closeIcon={closeIcon}
            onSave={onSave}
            content={<>
                <div className='flex w-full absolute bg-ligthgrey br-top br-bottom z-2' style={{ top: '4rem', left: 0 }}>
                    <div className='page-menu-item p-3 pl-5 br-right cursor-pointer' onClick={() => onClose(ACTIONS.EDIT)}><i className="pi pi-pencil"></i> Edit</div>
                    <div className='page-menu-item p-3 br-right cursor-pointer' onClick={() => onClose('ATTACH')}><i className="pi pi-paperclip"></i> Attach Files</div>
                </div>
                <div className='pt-6 pr-2'>
                    <TabView className="erp-tabview" scrollable activeIndex={viewIndex} onTabChange={(e) => onChangeTab(e.index)}>
                        <TabPanel header="Details">
                            {renderLoader}
                            <div className="grid">
                                <div className="col-6 mb-4">
                                    <small>Company Name</small>
                                    <p className="font-bold">{data?.name}</p>
                                </div>
                                {
                                    type == VENDOR_TYPE.VENDOR && <div className="col-6 mb-4">
                                        <small>Alias Name</small>
                                        <p className="font-bold">{data?.aliasName}</p>
                                    </div>
                                }
                                {
                                    type == VENDOR_TYPE.CUSTOMER && <div className="col-6 mb-4">
                                        <small>Account Payable Name</small>
                                        <p className="font-bold">{data?.accPayContact}</p>
                                    </div>
                                }
                            </div>
                            <div className="grid">
                                <div className="col-6 mb-4">
                                    <small>Phone</small>
                                    <p className="font-bold">{data?.phone || 'N/A'}</p>
                                </div>
                                <div className="col-6 mb-4">
                                    <small>Email</small>
                                    <p className="font-bold">{data?.email || 'N/A'}</p>
                                </div>
                            </div>
                            {
                                type === VENDOR_TYPE.VENDOR && <div className="grid">
                                    <div className="col-6 mb-4">
                                        <small>Categories</small>
                                        <Tooltip target=".categoryIds" mouseTrack mouseTrackLeft={10}></Tooltip>
                                        <p className="categoryIds font-bold" data-pr-tooltip={formatItems(data?.vendorCategories as any[], 'category.name')}>{formatLimitedItems(data?.vendorCategories as any[], 'category.name') || 'N/A'}</p>
                                    </div>
                                    <div className="col-6 mb-4">
                                        <small>Locations</small>
                                        <Tooltip target=".locationIds" mouseTrack mouseTrackLeft={10}></Tooltip>
                                        <p className="locationIds font-bold" data-pr-tooltip={formatItems(data?.vendorWarehouses as any[], 'location.name')}>{formatLimitedItems(data?.vendorWarehouses as any[], 'location.name') || 'N/A'}</p>
                                    </div>
                                </div>
                            }
                            {
                                type === VENDOR_TYPE.CUSTOMER && <div className="grid">
                                    <div className="col-6 mb-4">
                                        <small>Business Type</small>
                                        <p className="categoryIds font-bold">{get(data, 'businessType.code') || 'N/A'}</p>
                                    </div>
                                    <div className="col-6 mb-4">
                                        <small>Industry Type</small>
                                        <p className="categoryIds font-bold">{get(data, 'industryType.code') || 'N/A'}</p>
                                    </div>
                                </div>
                            }
                            <div className="grid">
                                <div className="col-6 mb-4">
                                    <small>Website</small>
                                    <p className="font-bold">{data?.website || 'N/A'}</p>
                                </div>

                                <div className="col-6 mb-4">
                                    <small>Preferred Shipment Mode</small>
                                    <p className="font-bold">{get(data, 'shipmentPref.code') || 'N/A'}</p>
                                </div>
                            </div>

                            {/* POC  */}
                            <div className="flex-1">
                                <DataTable
                                    value={data?.pocs}
                                    emptyMessage={'No POCs available'}>
                                    <Column header="POC" field="name"></Column>
                                    <Column field="email"></Column>
                                    <Column field="phone"></Column>
                                    <Column body={(item) => <div className="flex justify-content-end">
                                        <Button text icon="pi pi-pencil" className="p-button-sm p-button-text mr-2" onClick={() => showAddNew('poc', item)} ></Button>
                                        <Button text icon="pi pi-trash" className="p-button-sm p-button-text" style={{ color: 'red' }} onClick={() => confirmDelete('poc', item)}></Button>
                                    </div>} alignHeader={'right'} headerStyle={{ padding: 0 }} header={<div className="mr-3"><Button onClick={() => showAddNew('poc')} icon="pi pi-plus" size="small" style={{ width: 'unset', padding: '0.5rem 0.6rem' }}></Button></div>}></Column>
                                </DataTable>
                            </div>

                            {/* Addresses */}
                            <div className="mt-4">
                                <DataTable
                                    value={data?.addresses}
                                    emptyMessage={'No addresses available'}>
                                    <Column header="Addresses" body={(item) => [item.address1, item.address2, item.city, item.state, item.zip, item.country].filter((el) => el).join(', ')}></Column>
                                    <Column body={(item) => <div className="flex justify-content-end">
                                        <Button text icon="pi pi-pencil" className="p-button-sm p-button-text mr-2" onClick={() => showAddNew('address', item)}></Button>
                                        <Button text icon="pi pi-trash" className="p-button-sm p-button-text" style={{ color: 'red' }} onClick={() => confirmDelete('address', item)}></Button>
                                    </div>} alignHeader={'right'} headerStyle={{ padding: 0 }} header={<div className="mr-3"><Button onClick={() => showAddNew('address')} icon="pi pi-plus" size="small" style={{ width: 'unset', padding: '0.5rem 0.6rem' }}></Button></div>}></Column>
                                </DataTable>
                            </div>
                        </TabPanel>
                        {
                            type === VENDOR_TYPE.VENDOR && <TabPanel header="Purchase Orders">
                                {renderLoader}
                                <DataTable
                                    value={pos || []}
                                    footer={<small style={{ fontWeight: 'normal' }}>Recent {pos.length} orders only, please check Purchase Order page for more</small>}>
                                    <Column header="PO Number" body={(item) => <Link to={`/purchase-orders?poId=${item.poId}`} className="text-blue cursor-pointer">{item.poNumber}</Link>}></Column>
                                    <Column header="PO Date" field="poDate"></Column>
                                    <Column header="Status" field="status.code"></Column>
                                </DataTable>
                            </TabPanel>
                        }
                        {
                            type === VENDOR_TYPE.CUSTOMER && <TabPanel header="Sales Orders">
                                {renderLoader}
                                <DataTable
                                    value={so || []}
                                    footer={<small style={{ fontWeight: 'normal' }}>Recent {pos.length} orders only, please check Sales Order page for more</small>}>
                                    <Column header="SO Number" body={(item) => <Link to={`/sales-orders?soId=${item.soId}`} className="text-blue cursor-pointer">{item.soNumber}</Link>}></Column>
                                    <Column header="SO Date" field="soDate"></Column>
                                    <Column header="Status" field="status.code"></Column>
                                </DataTable>
                            </TabPanel>
                        }
                        {
                            type === VENDOR_TYPE.VENDOR && <TabPanel header="Supplied Products">
                                {renderLoader}
                                <MemoizedSuppliedTab vendorId={vendorId} />
                            </TabPanel>
                        }
                        <TabPanel header="Attachments">
                            {renderLoader}
                            {
                                docs != undefined && docs.length > 0 && <div className="mt-3">
                                    <div className='grid mt-2'>
                                        {
                                            docs.map((file: any) => (
                                                <div key={`file_${file.id}_${get(file, 'asset.name')}`} className='col-12 flex'>
                                                    <div className="flex gap-3 flex-column p-2 shadow-2 border-round align-items-center text-center border-round-sm mr-4" style={{ maxWidth: 250 }}>
                                                        <MemoizedFileItem edit={true} key={`file_image_${get(file, 'asset.assetId')}_${get(file, 'asset.name')}`} file={file.asset} onView={() => viewImage(file.asset)} onDelete={() => deleteDoc(file)} />
                                                    </div>
                                                    <div className="file-info flex flex-column  mb-2">
                                                        <p className='m-0  sub-desc text-xl font-bold text-overflow-ellipsis' style={{ overflow: 'hidden' }}>{get(file, 'asset.name')}</p>
                                                        <p className='m-0 mt-2 sub-desc'>{get(file, 'asset.type', '').toUpperCase()} • {formatBytes(get(file, 'asset.sizeInBytes'))}</p>
                                                        <p className='m-0 mt-2 sub-desc text-overflow-ellipsis'>{get(file, 'comment', '')}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !isLoading && docs.length == 0 && <>
                                    <div className="center">
                                        <p>No attachments</p>
                                    </div>
                                </>
                            }
                        </TabPanel>
                    </TabView>
                </div>

            </>}
        />
        <FileView
            isVisible={isShowImage}
            assetFile={assetFile}
            onClose={() => setShowImage(false)}
        />

        {/* POC Editor */}
        <Dialog
            visible={isShowPOC}
            header={(action === ACTIONS.EDIT ? 'Update' : 'New') + ' POC'}
            position={'top'}
            style={{ width: '30vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            draggable={false}
            onHide={closeSubModal}
            footer={<div className="flex justify-content-end py-2">
                <Button text label="Cancel" size="small" className="mr-2" onClick={closeSubModal}></Button>
                <Button label="Save" size="small" onClick={savePOC}></Button>
            </div>}
        >
            <div className="m-0">
                <div className="field p-0">
                    <label htmlFor="name3" className="w-full ">
                        Name <span className="text-red">*</span>
                    </label>
                    <div className="w-full">
                        <InputText className="w-full" value={pocForm?.name} onChange={(e) => updatePOCItem('name', e.target.value)} />
                    </div>
                    {isSubmitted && !pocForm?.name && <small className="text-red">Please provide valid name</small>}
                </div>
                <div className="field p-0">
                    <label htmlFor="name3" className="w-full ">
                        Phone <span className="text-red">*</span>
                    </label>
                    <div className="w-full">
                        <InputText className="w-full" value={pocForm?.phone} onChange={(e) => updatePOCItem('phone', e.target.value)} />
                    </div>
                    {isSubmitted && !validatePhoneNumber(pocForm?.phone || '') && <small className="text-red">Please provide valid phone</small>}
                </div>
                <div className="field p-0">
                    <label htmlFor="name3" className="w-full ">
                        Email <span className="text-red">*</span>
                    </label>
                    <div className="w-full">
                        <InputText className="w-full" value={pocForm?.email} onChange={(e) => updatePOCItem('email', e.target.value)} />
                    </div>
                    {isSubmitted && !validateEmail(pocForm?.email || '') && <small className="text-red">Please provide valid email</small>}
                </div>
            </div>
        </Dialog>

        {/* Address Editor */}
        <Dialog
            visible={isShowAddress}
            position={'top'}
            header={(action === ACTIONS.EDIT ? 'Update' : 'New') + ' Address'}
            style={{ width: '30vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            draggable={false}
            onHide={closeSubModal}
            footer={<div className="flex justify-content-end py-2">
                <Button text label="Cancel" size="small" className="mr-2" onClick={closeSubModal}></Button>
                <Button label="Save" size="small" onClick={saveAddress}></Button>
            </div>}
        >
            <div className="m-0">
                <div className="field p-0">
                    <label htmlFor={`address2`} className="w-full">Street Address <span className="red">*</span></label>
                    <InputText id={`address2`} className="w-full" value={addressForm?.address2 || ''} onChange={(e) => updateAddressItem(`address2`, e.target.value)} />
                    {isSubmitted && !validateNumberAndString(addressForm?.address2 || '') && <small className="text-red">Please provide valid address</small>}
                </div>

                <div className="field">
                    <label htmlFor={`city`}>City <span className="red">*</span></label>
                    <InputText id={`city`} className="w-full" value={addressForm?.city || ''} onChange={(e) => updateAddressItem(`city`, e.target.value)} />
                    {isSubmitted && !validateName(addressForm?.city || '') && <small className="text-red">Please provide valid city</small>}
                </div>
                <div className="field">
                    <label htmlFor={`state`}>State <span className="red">*</span></label>
                    <InputText id={`state`} className="w-full" value={addressForm?.state || ''} onChange={(e) => updateAddressItem(`state`, e.target.value)} />
                    {isSubmitted && !validateName(addressForm?.state || '') && <small className="text-red">Please provide valid state</small>}
                </div>
                <div className="field">
                    <label htmlFor={`zip`}>Zip Code <span className="red">*</span></label>
                    <InputText id={`zip`} className="w-full" value={addressForm?.zip || ''} onChange={(e) => updateAddressItem(`zip`, e.target.value)} />
                    {isSubmitted && !validateZipNumber(addressForm?.zip || '') && <small className="text-red">Please provide valid zip</small>}
                </div>
            </div>
        </Dialog>
    </>
});

export default VendorViewer;