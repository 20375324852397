import { every, has, isNil } from 'lodash';
import { CONFIG } from '../config/config';

export const validateSubdomain = (subdomain: string) => {
    const subdomainLower = subdomain.toLowerCase();
    const subdomainRegex = /^[a-z0-9-]{3,63}$/;

    // List of reserved subdomain names (lowercase)
    const reservedNames = ['admin', 'user', 'erp', 'localhost', 'local', 'login', 'reset', 'change', 'example'];

    // Check if the subdomain matches the regex
    if (!subdomainRegex.test(subdomain)) {
        return false;
    }

    // Check if the subdomain does not start or end with a hyphen
    if (subdomainLower.startsWith('-') || subdomainLower.endsWith('-')) {
        return false;
    }

    // Check if the subdomain is one of the reserved names
    if (reservedNames.includes(subdomainLower)) {
        return false;
    }

    return true;
};

export const validateName = (firstName: string, key?: string) => {
    const namePattern = /^[a-zA-Z\s'-.]+$/;
    if (typeof firstName !== 'string' || firstName.trim() === '') {
        return false;
    }
    if (!namePattern.test(firstName)) {
        return false;
    }
    return true;
};
export const validateNumber = (firstName: number, key?: number) => {
    if (typeof firstName !== 'number') {
        return false;
    }
    return true;
};
// export const validatePhoneNumber = (phoneNumber: string) => {
//     const phonePattern = /^[0-9]{10}$/; // Pattern that allows only 10 digits
//     if (typeof phoneNumber !== 'string' || phoneNumber.trim() === '') {
//         return false;
//     }
//     if (!phonePattern.test(phoneNumber)) {
//         return false;
//     }
//     return true;
// };

export const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phonePattern = /^\+?(\d{1,3})?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    // Pattern explanation:
    // ^            : Start of string
    // \+?          : Optional '+' character for country code
    // (\d{1,3})?   : Optional country code (1-3 digits)
    // \s?          : Optional space
    // \(?\d{3}\)?  : Area code with optional parentheses
    // [-.\s]?      : Optional separator (dash, dot, or space)
    // \d{3}        : First 3 digits
    // [-.\s]?      : Optional separator
    // \d{4}        : Last 4 digits
    // $            : End of string

    if (typeof phoneNumber !== 'string' || phoneNumber.trim() === '') {
        return false;
    }
    return phonePattern.test(phoneNumber);
};

export const validateStringLength = (input: string) => {
    if (typeof input !== 'string' || input.trim() === '') {
        return false;
    }
    return input.length <= 250;
};
// // Validate optional number fields
// export const validateOptionalNumber = (input: any) => {
//     return input === undefined || input === null || validateNumber(input);
// };

export const validateOptionalNumber = (input: any): boolean => {
    // Allow undefined or null as valid since it's optional
    if (input === undefined || input === null) {
        return true;
    }

    // Check if it's a number or a string representing a number
    const isValidNumber = typeof input === 'number' || (typeof input === 'string' && !isNaN(Number(input)));

    // Optionally: You can also allow other string values if required
    const isValidString = typeof input === 'string';

    return isValidNumber || isValidString;
};

export const validateNumberAndString = (input: unknown): boolean => {
    // Check if the input is not null, undefined, or an empty string
    if (input === null || input === undefined || (typeof input === 'string' && input.trim() === '')) {
        return false; // Input is required
    }

    // Input is valid if it's not null/undefined/empty
    return true;
};

// Validate URL
// export const validateURL = (url: string) => {
//     try {
//         new URL(url);
//         return true;
//     } catch {
//         return false;
//     }
// };

export const validateURL = (url: string): boolean => {
    if (!url || url.trim() === '') {
        return true; // Valid since it's optional
    }

    try {
        // Add scheme if missing
        const prefixedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
        new URL(prefixedUrl); // Validate the URL
        return true;
    } catch {
        return false;
    }
};

// Validate address fields
export const validateAddress = (address: any) => {
    return validateStringLength(address.address1) && validateStringLength(address.city) && validateStringLength(address.state) && validateZipNumber(address.zip);
};
export const validateMaxWords = (input: string | number): boolean => {
    if (input === null || input === undefined) {
        return false; // Invalid if null or undefined
    }

    // Convert number to string for consistent word count handling
    const inputStr = typeof input === 'number' ? input.toString() : input;

    // Split the input into words using whitespace and special character boundaries
    const words = inputStr.trim().split(/\s+/); // \s+ matches one or more spaces

    // Check the word count
    return words.length <= 250;
};

export const validateZipNumber = (phoneNumber: string) => {
    const phonePattern = /^[0-9]{5,6}$/; // Pattern that allows only 10 digits
    if (typeof phoneNumber !== 'string' || phoneNumber.trim() === '') {
        return false;
    }
    if (!phonePattern.test(phoneNumber)) {
        return false;
    }
    return true;
};
// export const validateFaxNumber = (phoneNumber?: string) => {
//     // If phoneNumber is undefined or empty, consider it valid since it's optional
//     if (typeof phoneNumber !== 'string'|| phoneNumber.trim() === '') {
//         return true;
//     }

//     // Pattern that allows exactly 10 digits
//     const phonePattern = /^[0-9]$/;

//     return phonePattern.test(phoneNumber.trim());
// };

export const validateFaxNumber = (faxNumber?: string) => {
    // If faxNumber is undefined, null, or empty, consider it valid since it's optional
    if (!faxNumber || faxNumber.trim() === '') {
        return true;
    }

    // Pattern that allows numbers, spaces, plus, and minus signs
    const faxPattern = /^[\d\s\+\-\(\)]+$/;

    return faxPattern.test(faxNumber.trim());
};

export const validateCountryCode = (countryCode: string) => {
    const countryCodePattern = /^\+[0-9]{1,3}$/; // Pattern that allows + followed by 1 to 3 digits
    if (typeof countryCode !== 'string' || countryCode.trim() === '') {
        return false;
    }
    if (!countryCodePattern.test(countryCode)) {
        return false;
    }
    return true;
};

export const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (typeof email !== 'string' || email.trim() === '') {
        return false;
    }
    if (!emailPattern.test(email)) {
        return false;
    }
    return true;
};
export const validateMaxNumberOfRacks = (noOfRacks: number) => {
    console.log('noOfRacks', noOfRacks);
    if (typeof noOfRacks !== 'number') {
        return false; // Invalid if not a positive number
    }
    if (noOfRacks > 50) {
        return false; // Invalid if more than 50
    }
    return true; // Valid
};
export const validateNonEmptyValue = (value: string | number): boolean => {
    // Check if the value is null, undefined, or an empty string
    if (value === null || value === undefined || value === '') {
        return false; // Invalid if empty
    }

    // Additional checks for string and number types
    if (typeof value === 'string' && value.trim() === '') {
        return false; // Invalid if string is just whitespace
    }
    if (typeof value === 'number' && isNaN(value)) {
        return false; // Invalid if number is NaN
    }

    return true; // Valid if non-empty
};

export const validateMinNumberOfRacks = (noOfRacks: number) => {
    if (typeof noOfRacks !== 'number' || noOfRacks <= 0) {
        return false; // Invalid if not a positive number
    }
    if (noOfRacks < 1) {
        return false; // Invalid if more than 50
    }
    return true; // Valid
};
export const validateNumberOfRackRow = (noOfRows: number) => {
    if (typeof noOfRows !== 'string' || noOfRows <= 0) {
        return false; // Invalid if not a positive number
    }
    if (noOfRows < 1) {
        return false; // Invalid if more than 50
    }
    return true; // Valid
};

export const formatBytes = (bytes = 0, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const parseYouTubeID = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
};

export const formatString = (str = '') => {
    return str
        .toLowerCase() // Convert to lowercase
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

export const generateRandomId = () => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 1000000); // Generate a random number
    return `${timestamp}-${randomNum}`;
};

// Function to get the logo URL
export const getCompanyLogo = (logo: string | undefined, fallback: string = '/images/logo-new.png') => {
    // Return fallback if logo is not defined
    if (!logo) {
        return fallback;
    }

    // Check if logo contains 'http' and return accordingly
    return logo.includes('http') ? logo : `${CONFIG.ASSET_LINK}${logo}`;
};

export const validateRoles = (roles: any[]) => {
    if (!Array.isArray(roles)) {
        return false;
    }
    if (roles.length === 0) {
        return false; // Invalid if the array is empty
    }

    return true; // Valid if it's a non-empty array
};

export const filterArray = (data: any[], filters: any) => {
    return data.filter((item) => {
        // Check global filter first
        if (filters.global && filters.global.value) {
            const globalValue = filters.global.value.toLowerCase();
            if (filters.global.matchMode === 'contains') {
                const containsGlobal = Object.values(item).some((value) => String(value).toLowerCase().includes(globalValue));
                if (!containsGlobal) return false;
            }
        }

        // Check field-specific filters
        for (const key in filters) {
            if (key === 'global') continue; // Skip global filter here

            const filter = filters[key];
            const itemValue = String(item[key] || '').toLowerCase();
            const filterValue = filter.value.toLowerCase();

            switch (filter.matchMode) {
                case 'contains':
                    if (!itemValue.includes(filterValue)) return false;
                    break;
                case 'startsWith':
                    if (!itemValue.startsWith(filterValue)) return false;
                    break;
                default:
                    return false;
            }
        }
        return true;
    });
};

export const buildQueryParams = (params: any) => {
    const query = new URLSearchParams();

    query.append('limit', (params.limit ? params.limit : 10) || 10);
    query.append('page', (params.page ? params.page : 1) || 1);

    for (const filterField in params.filters) {
        if (params.filters[filterField]) {
            query.append(`filters.${filterField}`, params.filters[filterField]);
        }
    }

    if (typeof params.include == 'object') {
        query.append('include', params.include.join(','));
    } else if (typeof params.include == 'string') {
        query.append('include', params.include);
    }

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const element = params[key];
            if (!['include', 'filters', 'limit', 'page'].includes(key) && element) {
                query.append(`${key}`, element);
            }
        }
    }

    return query.toString();
};

export const getRowLimitWithScreenHeight = ({ headerHeight = 250, footerHeight = 50 } = { headerHeight: 250, footerHeight: 50 }) => {
    const availableHeight = window.innerHeight - headerHeight - footerHeight + 100;
    return Math.max(Math.floor(availableHeight / 50), 10);
};

export const validateString = (firstName: string, key?: string) => {
    const namePattern = /^[a-zA-Z0-9\-_()]+( [a-zA-Z0-9\-_()]+)*$/;
    if (typeof firstName !== 'string' || firstName.trim() === '') {
        return false;
    }
    if (!namePattern.test(firstName)) {
        return false;
    }
    return true;
};

export const formatWithWordsNumber = (num: any) => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B'; // Billion
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M'; // Million
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K'; // Thousand
    }
    return num.toString(); // Less than 1000
};

export const isValidArray = (data: any[], requiredKeys: any[]) => {
    return every(data, obj =>
        every(requiredKeys, key => has(obj, key) && !isNil(obj[key]) && obj[key] !== '')
    )
}

export const hasAllKeys = (obj: any, requiredKeys: any[]) => {
    return requiredKeys.every(key => obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '');
}