

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { CustomResponse, MasterCode, Product, Item, PurchaseItem, Vendor, Warehouse, SalesOrderItem, SalesOrder, Asset } from '../../../types';
import Sidebar from '../../../components/Sidebar';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DeleteCall, GetCall, GetPdfCall, PostCall, PutCall } from '../../../api/ApiKit';
import { filter, find, get, includes, map, set, sumBy } from 'lodash';
import { constant } from '../../../utils/constant';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import moment, { isMoment } from 'moment-timezone';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { buildQueryParams, formatWithWordsNumber, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Menu } from 'primereact/menu';
import UploadFile from '../../../components/UploadFile';
import FileView from '../../../components/FileView';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SalesOrderView from '../../../components/Views/SalesOrderView';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useSelector } from 'react-redux';
import { ReduxStoreState } from '../../../redux/redux-store';
import AppPage from '../../../layout/AppPage';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete'
};

const defaultLineItem: PurchaseItem = {
    poId: null,
    poItemId: null,
    companyId: null,
    categoryId: null,
    productId: null,
    gradeId: null,
    isCrossDock: false,
    quantity: 0,
    price: null,
    rowId: null,
    skuId: null,
    internalGrade: null,
    batteryHealthIds: null,
};

const salesOrderDefault: SalesOrder = {
    soId: null,
    soNumber: null,
    vendorId: null,
    companyId: null,
    soDate: null,
    approxDeliveryDate: null,
    statusId: null,
    trackingTypeId: null,
    trackingNumber: null,
    paymentTermsId: null,
    shippingPrice: 0,
    discountType: 'FIXED',
    discountAmount: 0,
    vat: null,
    paid: 0,
    note: '',
    price: 0,
    items: [],
}

const SalesOrderPage = () => {
    const { companyId } = useParams();
    const createMenuRef = useRef<Menu>(null);
    const initializedRef = useRef(false);
    const [searchParams] = useSearchParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const containerRef = useRef(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [action, setAction] = useState<any>(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [pos, setPOs] = useState<SalesOrder[]>([]);
    const [selectedSO, setSelectedSO] = useState<SalesOrder | null>(null);
    const [allVendors, setAllVendors] = useState<Vendor[]>([]);
    const [salesOrder, setSalesOrder] = useState<SalesOrder>({ ...salesOrderDefault });
    const [statuses, setStatuses] = useState<MasterCode[]>([]);
    const [paid, setPaid] = useState<number>(0);
    const [lineItems, setLineItems] = useState<SalesOrderItem[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<any>('');
    const [visible, setVisible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const querySoId = searchParams.get('soId');

    const trackings = useSelector((state: ReduxStoreState) => state.carriers.carriers);
    const paymentTerms = useSelector((state: ReduxStoreState) => state.grades.grades);

    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchAllVendors();
        fetchPOStatus();
        return () => {
            setScroll(true);
        };
    }, []);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            fetchProducts(searchText);
        }, 500);

        return () => clearTimeout(delayDebounce);
    }, [searchText]);


    useEffect(() => {
        const check = async () => {
            if (!initializedRef.current && querySoId) {
                initializedRef.current = true;
                let sampleSO = { ...salesOrderDefault };
                sampleSO.soId = +querySoId;
                setAction(ACTIONS.VIEW);
                handleClick(sampleSO);
            }
        };
        check();
    }, [querySoId]);

    const handleClick = (_salesOrder?: SalesOrder) => {
        if (_salesOrder) {
            const newUrl = `/sales-orders?soId=${_salesOrder.soId}`;
            navigate(newUrl, { replace: true });
            setSalesOrder(_salesOrder)
            setIsShowSplit(true);
        }
        else {
            navigate('/sales-orders', { replace: true });
        }
    };

    const updateItem = async (key: string, value: any) => {
        const _so = JSON.parse(JSON.stringify(salesOrder));
        set(_so, key, value);
        setSalesOrder(_so);
    }

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        params.include = 'customer,status';
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-orders-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setPOs(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setPOs([]);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchSoDetails = async (soId: any) => {
        let params: any = {
            filters: {
                soId: soId
            },
            include: 'customer,status,items'
        };
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-orders?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesOrder(response.data[0]);
        } else {
            setSalesOrder(salesOrderDefault);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchPOStatus = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/master-codes?codeType=${constant.SYSTEM_MSTR_CODE.soStatus}`);
        if (response.code == 'SUCCESS') {
            setStatuses(response.data);
        } else {
            setStatuses([]);
        }
        setLoading(false);
    };

    const fetchAllVendors = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors?filters.vendorType=isCustomer&limit=500`);
        if (response.code == 'SUCCESS') {
            setAllVendors(response.data);
        } else {
            setAllVendors([]);
        }
        setLoading(false);
    };

    const fetchProducts = async (search = '') => {
        if (!user?.company?.companyId) {
            return;
        }

        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/products?search=${search}`);
        if (response.code == 'SUCCESS') {
            setProducts(response.data);
        } else {
            setProducts([]);
        }
        setLoading(false);
    };

    const confirmDelete = (_sOrder: SalesOrder) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this Sales Order?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (_sOrder) {
                    deleteSalesOrder(_sOrder)
                }
            },
        });
    }

    const deleteSalesOrder = async (_sOrder: SalesOrder) => {
        // if (!selectedSOToDelete) return;
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/sales-orders/${_sOrder.soId}`);
        setLoading(false);
        if (response.code === 'SUCCESS') {
            fetchData();
            setAlert('success', 'Successfully Deleted');
        } else {
            setAlert('error', response.message);
        }
    };

    const closeIcon = () => {
        setSubmitted(false);
        setIsShowSplit(false);
        setSelectedSO(null);
        setSearchText('')
        setSalesOrder({ ...salesOrderDefault })
        handleClick();
    };
    const openDeleteDialog = (perm: SalesOrder) => {
    };
    const onRowSelect = async (perm: SalesOrder, action = ACTIONS.VIEW) => {
        setAction(action);
        await setSelectedSO(perm);
        if (action === ACTIONS.DELETE) {
            openDeleteDialog(perm);
        }
        if (action === ACTIONS.EDIT) {
            setSalesOrder(perm);
            setIsShowSplit(true);
            fetchSoDetails(perm.soId)
        }

        if (action === ACTIONS.VIEW) {
            setSalesOrder(perm);
            setIsShowSplit(true);
            handleClick(perm)
        }
    };

    const showAddNew = () => {
        setIsShowSplit(true);
        setSubmitted(false);
        setAction(ACTIONS.ADD);
        setSelectedSO(null);
        fetchProducts();
    };

    const onProductSelect = (productId: number, isPreSelected: boolean, rowIndex: any) => {
        let product = find(products, { productId: productId });
        let _items = [...get(salesOrder, 'items', [])];
        if (!isPreSelected) {
            let isPresent = find(_items, { productId: productId })
            if (!isPresent) {
                _items.push({
                    soId: null,
                    companyId: null,
                    quantity: 1,
                    price: product.price || 0,
                    productId: productId,
                    product: product
                });
            }
            else {
                setAlert('warn', 'duplicate entry')
            }
        } else {
            _items[rowIndex].productId = productId;
            _items[rowIndex].price = product.price || 0;
            _items[rowIndex].product = product;
        }
        updateItem('items', _items);
    };

    const removeItem = (productId: any) => {
        if (!productId) return;
        let _items = [...lineItems];
        _items = _items.filter((item) => item.productId != productId);
        updateItem('items', _items);
    };

    const onSave = async () => {
        setSubmitted(true);
        if (!salesOrder.vendorId || !salesOrder.soDate || get(salesOrder, 'items', []).length == 0) {
            return;
        }
        setLoading(true);
        if (action == ACTIONS.ADD) {
            const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/sales-orders`, salesOrder);
            if (response.code == 'SUCCESS') {
                closeIcon();
                fetchData();
                setAlert('success', 'Add Successfully');
            } else {
                setAlert('error', response.message);
            }
        }
        if (action == ACTIONS.EDIT) {
            const response: CustomResponse = await PutCall(`/company/${user?.company?.companyId}/sales-orders/${selectedSO?.soId}`, salesOrder);
            if (response.code == 'SUCCESS') {
                closeIcon();
                fetchData();
                setAlert('success', 'Updated Successfully');
            } else {
                setAlert('error', response.message);
            }
        }
        setLoading(false);
    };

    const printDoc = async (soId: any) => {
        setLoading(true);
        const response: any = await GetPdfCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/print`);
        if (response && response.code == 'FAILED') {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const uploadDocs = async (assetIds: any) => {
        let soId = salesOrder.soId;
        if (!soId && selectedSO) {
            soId = selectedSO.soId;
        }
        setLoading(true);
        const response: CustomResponse = await PostCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/docs`, { assetIds });
        if (response.code == 'SUCCESS') {
            setAlert('success', 'Upload Successfully');
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const updateStatus = async (soId: any, masterCodeId: any) => {
        setLoading(true);
        const response: CustomResponse = await PutCall(`/company/${user?.company?.companyId}/sales-orders/${soId}/status/${masterCodeId}`);
        if (response.code == 'SUCCESS') {
            fetchData();
            setAlert('success', 'Status Updated');
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between p-4">
                <span className="p-input-icon-left flex align-items-center">
                    <h4 className="mb-0">Sales Orders</h4>
                </span>
                <span className="flex gap-5">
                    <div className=" ">
                        <Button label="Create SO" size="small" icon="pi pi-plus" className=" mr-2" onClick={showAddNew} />
                    </div>
                </span>
            </div>
        );
    };
    const header = renderHeader();

    const onFilter = (e: any) => {
        if (e) {
            setSearchText(e.filter)
        }
    }

    const renderProduct = (item: SalesOrderItem | null, option: ColumnBodyOptions) => {
        if (item?.productId) {
            return <div>
                <small>{get(item, 'product.productId')}</small>
                <p className='text-900'>{get(item, 'product.name')}</p><br />
            </div>
        }

        return (
            <Dropdown
                value={item?.productId}
                filter={true}
                filterClearIcon
                onFilter={onFilter}
                onChange={(e) => onProductSelect(e.value, item?.productId != null, option.rowIndex)}
                valueTemplate={selectedProductTemplate}
                itemTemplate={productOptionTemplate}
                options={products.filter((item) => !map(get(salesOrder, 'items', []), 'productId').includes(item.productId))}
                optionLabel="name"
                optionValue="productId"
                placeholder="Select a Product"
                className="w-30rem"
            />
        );
    };

    const renderQuantity = (item: SalesOrderItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <div style={{ width: 80 }}></div>;
        }
        return (
            <div>
                <InputNumber value={item.quantity} onValueChange={(e) => updateItem(`items.${option.rowIndex}.quantity`, e.value)} inputClassName="text-base w-full" inputStyle={{ width: '80px', textAlign: 'right' }} />
                {
                    get(item, 'product.itemCounts', undefined) != undefined && <small className={`${get(item, 'product.itemCounts', 0) < get(item, 'quantity', 0) ? 'text-red-500' : ''}`}>Stock in hand: {formatWithWordsNumber(get(item, 'product.itemCounts', 0))}</small>
                }
            </div>
        );
    };

    const renderRate = (item: SalesOrderItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <div style={{ width: 150 }}></div>;
        }
        return (
            <>
                <InputNumber value={item.price} onValueChange={(e) => updateItem(`items.${option.rowIndex}.price`, e.value)} mode="currency" currency="USD" locale="en-US" placeholder="Price" inputClassName="text-base" inputStyle={{ width: '150px', textAlign: 'right' }} />
            </>
        );
    };
    const renderTotal = (item: SalesOrderItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return item.price * item.quantity;
    };

    const renderCross = (item: SalesOrderItem) => {
        if (!item?.productId) {
            return <div style={{ width: 50 }}></div>;
        }
        return (
            <div className="flex align-items-center">
                <Button icon="pi pi-times" severity="danger" aria-label="Cancel" size="small" onClick={() => removeItem(get(item, 'skuId', null))} />
            </div>
        );
    };

    const selectedProductTemplate = (option: Product, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-start">
                    <div className="text-base">{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const productOptionTemplate = (option: Product) => {
        return (
            <div className="flex justify-content-between flex-wrap">
                <div className="flex flex-column">
                    <div className="text-xs text-grey">{option.type}</div>
                    <div className="text-base">{option.name}</div>
                </div>
                <div className={`flex flex-column align-items-end ${get(option, 'itemCounts', 0) == 0 ? 'text-red-500' : ''}`}>
                    <div className="text-xs text-grey">Stock in hand</div>
                    {formatWithWordsNumber(get(option, 'itemCounts', 0))}
                </div>
            </div>
        );
    };

    const renderPOTotal = (option: SalesOrder) => {
        return <>${get(option, 'totalPrice', 0)}</>
    };

    const renderPOBalace = (option: SalesOrder) => {
        return <>${get(option, 'balancePrice', 0)}</>
    };

    const renderVendor = (item: any) => get(item, 'customer');
    const renderStatus = (rowData: SalesOrder) => {
        return (
            <Dropdown
                value={rowData.statusId || null}
                options={statuses}
                optionLabel="code"
                optionValue="masterCodeId"
                onChange={(e) => updateStatus(rowData.soId, e.value)}
                className="dropdown-small w-full" checkmark={true}
            />
        );
    };
    const vendorDropdown = (options: any) => (
        <Dropdown
            filter={true}
            value={options.value || null}
            options={allVendors}
            optionLabel="name"
            optionValue="vendorId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select customer"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );

    const statusDropdown = (options: any) => (
        <Dropdown
            filter={true}
            value={options.value || null}
            options={statuses}
            optionLabel="code"
            optionValue="masterCodeId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select status"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );

    const getDiscountAmount = (_salesOrder?: SalesOrder) => {
        if (!_salesOrder) {
            _salesOrder = salesOrder;
        }
        let sum = sumBy(get(_salesOrder, 'items', []), (item) => item.quantity * item.price);
        let discountAmount = _salesOrder.discountAmount ? get(_salesOrder, 'discountAmount', 0) : 0 || 0;
        if (_salesOrder.discountType == 'PERCENTAGE') {
            return sum * (discountAmount / 100);
        }
        return discountAmount;
    }

    const calculateBalance = (_salesOrder?: SalesOrder) => {
        if (!_salesOrder) {
            _salesOrder = salesOrder;
        }
        let sum = sumBy(get(_salesOrder, 'items', []), (item) => item.quantity * item.price);
        return sum + (sum * (get(_salesOrder, 'vat', 0) / 100)) - getDiscountAmount(_salesOrder) + get(_salesOrder, 'shippingPrice', 0) - get(_salesOrder, 'paid', 0)
    }

    const salesOrderEditor = (<>
        <div className="grid" ref={containerRef}>
            <div className="field col-4">
                <label htmlFor="name3" className="w-full ">
                    Customer Name<span className='text-red'>*</span>
                </label>
                <div className="w-full">
                    <Dropdown
                        value={salesOrder.vendorId}
                        filter={true}
                        onChange={(e) => updateItem('vendorId', e.value)}
                        options={allVendors}
                        optionLabel="name"
                        optionValue="vendorId"
                        placeholder="Select a Customer"
                        className={`w-full ${isSubmitted && !salesOrder.vendorId ? 'p-invalid' : ''}`}
                        required={true}
                    />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full ">
                    Sales Order Date<span className='text-red'>*</span>
                </label>
                <div className="w-full">
                    <Calendar appendTo={'self'} value={salesOrder?.soDate ? moment(salesOrder?.soDate).toDate() : null} onChange={(e) => updateItem('soDate', e.value)} placeholder="MM/DD/YYYY" className={`w-full ${isSubmitted && !salesOrder.soDate ? 'p-invalid' : ''}`} showIcon required={true} />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full">
                    Expected Shipment Date
                </label>
                <div className="w-full">
                    <Calendar appendTo={'self'} value={salesOrder.approxDeliveryDate ? moment(salesOrder.approxDeliveryDate).toDate() : null} onChange={(e) => updateItem('approxDeliveryDate', e.value)} placeholder="MM/DD/YYYY" className="w-full" showIcon required={true} />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full">
                    Payment Terms
                </label>
                <div className="w-full">
                    <Dropdown
                        value={salesOrder.paymentTermsId}
                        options={paymentTerms}
                        onChange={(e) => updateItem('paymentTermsId', e.value)}
                        optionLabel="code"
                        optionValue="masterCodeId"
                        placeholder="Payment Terms"
                        className={`w-full`}
                    />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full">
                    Shipping Mode
                </label>
                <div className="w-full">
                    <Dropdown value={salesOrder.trackingTypeId} onChange={(e) => updateItem('trackingTypeId', e.value)} options={trackings} optionLabel="code" optionValue="masterCodeId" placeholder="Shipment carrier" className="w-full" />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full">
                    Shipping Tracking Number
                </label>
                <div className="w-full">
                    <InputText value={salesOrder?.trackingNumber || ''} onChange={(e) => updateItem('trackingNumber', e.target.value)} placeholder="Tracking Number" className="w-full" />
                </div>
            </div>

            <div className="field col-4">
                <label htmlFor="name3" className="w-full  ">
                    Status<span className='text-red'>*</span>
                </label>
                <div className="w-full">
                    <Dropdown
                        value={salesOrder.statusId}
                        options={statuses}
                        onChange={(e) => updateItem('statusId', e.value)}
                        optionLabel="code"
                        optionValue="masterCodeId"
                        placeholder="Status"
                        className={`w-full ${isSubmitted && !salesOrder.statusId ? 'p-invalid' : ''}`}
                    />
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h5>Line Items</h5>
            <DataTable
                scrollable
                showGridlines
                value={[...get(salesOrder, 'items', []), defaultLineItem]}
                selectionMode="single"
                dataKey="productId"
                className='table-line-item'
                // onSelectionChange={(row: any) => onRowSelect(row.value, 'view')}
                scrollHeight="70%"
                style={{ height: '80%', zIndex: 0 }}
            >
                <Column field="soDate" header="Product & SKU" body={(data, options: ColumnBodyOptions) => renderProduct(data, options)}></Column>
                <Column field="soDate" header="Quantity" body={renderQuantity} style={{ width: 80, textAlign: 'right' }}></Column>
                <Column field="poNumber" header="Rate" body={renderRate} style={{ width: 150, textAlign: 'right' }}></Column>
                <Column field="poNumber" header="Total" body={renderTotal} style={{ width: 150, textAlign: 'right' }}></Column>
                <Column style={{ width: 30 }} body={renderCross}></Column>
            </DataTable>
            <div className="grid mt-3">
                <div className="col-4 col-offset-8">
                    <div className="flex justify-content-between align-items-baseline">
                        <p className="font-semibold">Total</p>
                        <p className="font-bold">${sumBy(get(salesOrder, 'items', []), (item) => item.quantity * item.price)}</p>
                    </div>
                    <div className="flex justify-content-between align-items-baseline">
                        <p className="font-semibold">VAT %</p>
                        <InputNumber
                            value={salesOrder.vat || 0}
                            onValueChange={(e) => {
                                if (e.value) {
                                    updateItem('vat', e.value);
                                } else {
                                    updateItem('vat', 0);
                                }
                            }}
                            inputClassName="text-base font-bold"
                            inputStyle={{ width: '130px', textAlign: 'end' }}
                        />
                    </div>
                    <div className="flex justify-content-between align-items-baseline">
                        <p className="font-semibold">Shipping Charges</p>
                        <InputNumber
                            value={salesOrder.shippingPrice || 0}
                            onValueChange={(e) => {
                                if (e.value) {
                                    updateItem('shippingPrice', e.value > 0 ? e.value : 0);
                                } else {
                                    updateItem('shippingPrice', 0);
                                }
                            }}
                            inputClassName="text-base font-bold"
                            inputStyle={{ width: '130px', textAlign: 'end' }}
                        />
                    </div>

                    <div className="flex justify-content-between align-items-center">
                        <div className='grid align-items-baseline ml-0'>
                            <p className="font-semibold">Discount</p>
                            <div className='ml-2'>
                                <SelectButton className='p-discount' value={salesOrder.discountType == 'FIXED' ? '$' : '%'} onChange={(e) => updateItem('discountType', e.value == '$' ? 'FIXED' : 'PERCENTAGE')} options={['$', '%']} />
                            </div>
                        </div>
                        <InputNumber
                            value={salesOrder.discountAmount || 0}
                            onValueChange={(e) => {
                                if (e.value) {
                                    updateItem('discountAmount', e.value);
                                } else {
                                    updateItem('discountAmount', 0);
                                }
                            }}
                            inputClassName="text-base font-bold"
                            inputStyle={{ width: '130px', marginBottom: 5, textAlign: 'end' }}
                        />
                    </div>
                    <div className="flex justify-content-between align-items-baseline">
                        <p className="font-bold">Paid</p>
                        <InputNumber
                            value={salesOrder.paid || 0}
                            onValueChange={(e) => {
                                if (e.value) {
                                    updateItem('paid', e.value);
                                } else {
                                    updateItem('paid', 0);
                                }
                            }}
                            inputClassName="text-base font-bold"
                            inputStyle={{ width: '130px', textAlign: 'end' }}
                        />
                    </div>
                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                    <div className="flex justify-content-between align-items-baseline">
                        <p className="font-semibold">Balance</p>
                        <p className="font-bold">${calculateBalance()}</p>
                    </div>
                </div>
            </div>
            <div className="grid mt-3">
                <h5>Remarks</h5>
                <InputTextarea className="w-full" value={salesOrder.note || ''} onChange={(e) => updateItem('note', e.target.value)} rows={5} cols={30} style={{ resize: 'none' }} />
            </div>
        </div>
    </>);

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter={true}
                                page={page}
                                title='Sales Orders'
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isView={true}
                                isEdit={true} // show edit button
                                isDelete={true} // show delete button
                                headerButtons={[
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                extraButtons={[
                                    {
                                        icon: 'pi pi-cloud-upload',
                                        onClick: (item) => {
                                            setSelectedSO(item)
                                            setVisible(true)
                                        }
                                    },
                                    {
                                        icon: 'pi pi-file-pdf',
                                        onClick: (item) => {
                                            printDoc(item.soId)
                                        }
                                    }
                                ]}
                                data={pos}
                                columns={[
                                    {
                                        header: 'SO Date',
                                        field: 'soDate',
                                        sortable: true,
                                        style: { minWidth: 120, maxWidth: 120 },
                                        body: (options: any) => isMoment(moment(options.soDate)) ? moment(options.soDate).format('MM/DD/YYYY') : ''
                                    },
                                    {
                                        header: 'SO Number',
                                        field: 'soNumber',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { minWidth: 150, maxWidth: 150 },
                                        filterPlaceholder: 'SO Number',
                                        body: (options: any) => (<label className='text-blue cursor-pointer' onClick={() => onRowSelect(options, ACTIONS.VIEW)}>{options.soNumber}</label>)
                                    },
                                    {
                                        header: 'Customer',
                                        field: 'vendorId',
                                        body: renderVendor,
                                        filter: true,
                                        filterElement: vendorDropdown,
                                        filterPlaceholder: 'Search customer'
                                    },
                                    {
                                        header: 'Status',
                                        field: 'statusId',
                                        body: renderStatus,
                                        filter: true,
                                        filterElement: statusDropdown
                                    },
                                    {
                                        header: 'Total',
                                        field: 'total',
                                        body: renderPOTotal
                                    },
                                    {
                                        header: 'Balance',
                                        field: 'balance',
                                        body: renderPOBalace
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onView={(item: any) => onRowSelect(item, ACTIONS.VIEW)}
                                onEdit={(item: any) => onRowSelect(item, ACTIONS.EDIT)}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                        <Sidebar
                            isVisible={isShowSplit && [ACTIONS.EDIT, ACTIONS.ADD].includes(action)}
                            action={action}
                            title={`${[ACTIONS.EDIT].includes(action) ? (salesOrder.soNumber ? salesOrder.soNumber : '') : 'New Sales Order'}`}
                            closeIcon={closeIcon}
                            onSave={onSave}
                            content={[ACTIONS.EDIT, ACTIONS.ADD].includes(action) ? salesOrderEditor : <></>}
                        />
                    </div>
                </div>
            </div>
            {
                isShowSplit && action == ACTIONS.VIEW && salesOrder?.soId && <SalesOrderView
                    soId={salesOrder.soId}
                    isVisible={true}
                    onClose={(isEdit) => {
                        console.log('isEdit', isEdit)
                        if (isEdit) {
                            onRowSelect(salesOrder, ACTIONS.EDIT)
                        }
                        else {
                            closeIcon();
                        }
                    }}
                />
            }
            <ConfirmDialog appendTo={document.body} />
            <UploadFile isVisible={visible}
                onSelect={(option: any) => {
                    setVisible(false);
                    if (option && option.length > 0) {
                        let assets = option.map((item: any) => ({
                            assetId: item.assetId,
                            comment: item.comment || ''
                        }))
                        uploadDocs(assets);
                    }
                }}
            />
        </AppPage>
    );
};

export default SalesOrderPage;