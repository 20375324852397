import { memo, useContext, useEffect, useRef, useState } from "react";
import { useAppContext } from "../layout/AppWrapper";
import { LayoutContext } from "../layout/context/layoutcontext";
import { useNavigate, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ACTIONS } from "../utils/constant";
import Sidebar from "./Sidebar";
import { MultiSelect } from "primereact/multiselect";
import VendorPartMapping from "./Editors/VendorPartMapping";
import React from "react";
import { get, set } from "lodash";
import CustomDataTable, { CustomDataTableRef } from "./CustomDataTable";
import { buildQueryParams, getRowLimitWithScreenHeight } from "../utils/uitl";
import { CustomResponse, Parts, Product } from "../types";
import { DeleteCall, GetCall, PostCall, PutCall } from "../api/ApiKit";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import BulkUploader from "./BulkUploader";


const MemoizedSuppliedTab = memo(({ vendorId }: { vendorId: any }) => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const companyId = get(user, 'company.companyId');
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const [parts, setParts] = useState<any[]>([]);
    const [action, setAction] = useState<any>('');
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [form, setForm] = useState<any>({ productId: null, parts: null, unitPrice: 0, packQuantity: 1 });

    const [isFileUpload, setIsFileUpload] = useState(false);
    const fileInputRef = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);

    useEffect(() => {
        fetchData()
    }, [vendorId])

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        const companyId = get(user, 'company.companyId');
        params.filters = { vendorId: vendorId }
        const queryString = buildQueryParams(params);
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/products?${queryString}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setParts(response.data);
            if (response.total) {
                setTotalRecords(response.total);
            }
        } else {
            setParts([]);
        }
    }

    const closeIcon = () => {
        setAction('')
    }

    const updateItem = (key: any, value: any) => {
        let _poc = { ...form };
        set(_poc, key, value);
        setForm(_poc)
    }

    const onRowSelect = (item: Product, action: any) => {
        setAction(action);
        setForm({
            vpId: get(item, 'vendorProducts.0.vpId'),
            productId: item.productId,
            parts: item,
            unitPrice: get(item, 'vendorProducts.0.unitPrice'),
            packQuantity: get(item, 'vendorProducts.0.packQuantity')
        });
    }

    const confirmDelete = (item: Product) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: async () => {
                const companyId = get(user, 'company.companyId');
                setLoading(true);
                const response: CustomResponse = await DeleteCall(`/company/${companyId}/vendors/${vendorId}/products`, [item.productId]);
                setLoading(false);
                if (response.code == 'SUCCESS') {
                    fetchData();
                } else {
                    setAlert('error', response.message)
                }
            },
        });
    }

    const onSave = async () => {
        let payload: any = {
            productId: form.productId,
            unitPrice: form.unitPrice,
            packQuantity: form.packQuantity,
        }
        setSubmitted(true)
        if (!form.unitPrice || !form.packQuantity) {
            return;
        }

        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await PutCall(`/company/${companyId}/vendors/${vendorId}/products/${form.vpId}`, payload);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            fetchData();
            closeIcon();
            setSubmitted(false);
        } else {
            setAlert('error', response.message)
        }
    }

    return <>
        <CustomDataTable
            ref={dataTableRef}
            filter
            page={page}
            limit={limit}
            totalRecords={totalRecords}
            isEdit={true}
            isDelete={true}
            data={parts}
            headerButtons={[
                {
                    icon: 'pi pi-file-excel',
                    onClick: () => setIsFileUpload(true)
                },
                {
                    icon: 'pi pi-plus',
                    label: 'Add',
                    onClick: () => setAction(ACTIONS.ADD)
                }
            ]}
            columns={[
                { header: '#', field: 'productId', sortable: true },
                { header: 'Product', field: 'name', filter: true, sortable: true, bodyStyle: { minWidth: 80, maxWidth: 80 }, filterPlaceholder: 'Search name' },
                { header: 'Unit Price', body: (item: Product) => `$${get(item, 'vendorProducts.0.unitPrice')}` },
                { header: 'Pack Qnt', field: 'vendorProducts.0.packQuantity' }
            ]}
            onLoad={(params: any) => fetchData(params)}
            onEdit={(item: any) => onRowSelect(item, ACTIONS.EDIT)}
            onDelete={(item: any) => confirmDelete(item)}
        />

        {
            action === ACTIONS.ADD && <VendorPartMapping
                isVisible={true}
                vendorId={vendorId}
                onClose={(isLoad: any) => {
                    closeIcon();
                    if (isLoad) {
                        fetchData();
                    }
                }}
            />
        }

        {/* Edit Vendor Part */}
        <Dialog
            visible={action === ACTIONS.EDIT && form.vpId != null}
            header={get(form, 'parts.name')}
            position={'top'}
            style={{ width: '30vw' }}
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            draggable={false}
            onHide={closeIcon}
            footer={<div className="flex justify-content-end py-2">
                <Button text label="Cancel" size="small" className="mr-2" onClick={closeIcon}></Button>
                <Button label="Save" size="small" onClick={onSave}></Button>
            </div>}
        >
            <div className="m-0">
                <div className="field p-0">
                    <label htmlFor="name3" className="w-full ">
                        Unit Price
                    </label>
                    <div className="w-full">
                        <InputNumber className="w-full" value={form.unitPrice} onChange={(e) => updateItem('unitPrice', e.value)} />
                    </div>
                    {isSubmitted && !form.unitPrice && <small className="text-red">Please provid valid unit price</small>}
                </div>
                <div className="field p-0">
                    <label htmlFor="name3" className="w-full ">
                        Pack Qnt
                    </label>
                    <div className="w-full">
                        <InputNumber max={100} className="w-full" value={form?.packQuantity} onChange={(e) => updateItem('packQuantity', e.value)} />
                    </div>
                    {isSubmitted && !form.packQuantity && <small className="text-red">Please provid valid pacl quantity</small>}
                </div>
            </div>
        </Dialog>

        {/* // bulk upload  */}
        <BulkUploader
            isVisible={isFileUpload}
            url={`/company/${companyId}/vendors/${vendorId}/bulk-vendor-products`}
            fileInputColumns={['Product Id', 'Unit Price', 'Pack Qnt']}
            closeIcon={(isLoad: boolean) => {
                setIsFileUpload(false);
                if (isLoad) {
                    fetchData()
                }
            }}
        />
    </>
})

export default MemoizedSuppliedTab;