import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Warehouse } from "../../types";

interface LocationState {
    locations: Warehouse[];
}

const initialState: LocationState = {
    locations: [],
};

const locationSlice = createSlice({
    name: "locations",
    initialState,
    reducers: {
        setLocations: (state, action: PayloadAction<Warehouse[]>) => {
            state.locations = action.payload;
        },
        addLocation: (state, action: PayloadAction<Warehouse>) => {
            state.locations.push(action.payload);
        },
    },
});

export const { setLocations, addLocation } = locationSlice.actions;
export default locationSlice.reducer;
