import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../layout/AppWrapper";
import { LayoutContext } from "../../../layout/context/layoutcontext";
import { Menu } from "primereact/menu";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { Button } from "primereact/button";
import { ApiKey, CustomResponse } from "../../../types";
import { DeleteCall, GetCall, PostCall, PutCall } from "../../../api/ApiKit";
import { get, set } from "lodash";
import { ACTIONS } from "../../../utils/constant";
import Sidebar from "../../../components/Sidebar";
import { DEFAULT_API_KEY } from "../../../types/default-forms";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { IconField } from "primereact/iconfield";
import { Tag } from "primereact/tag";

const SettingPage = () => {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();

    const [items, setItems] = useState<any[]>([]);
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<ApiKey>({ ...DEFAULT_API_KEY });
    const [selectedAPIKey, setSelectedAPIKey] = useState<ApiKey | null>(null);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/api-keys`);
        setLoading(false)
        if (response.code == 'SUCCESS') {
            setItems(response.data);
        }
        else {
            setItems([]);
        }
    }

    const updateItem = async (key: string, value: any) => {
        const _pOrder: ApiKey = JSON.parse(JSON.stringify(form));
        set(_pOrder, key, value);
        setForm(_pOrder);
    }

    const showAddNew = () => {
        setAction(ACTIONS.ADD)
    }

    const onClose = () => {
        setAction(null)
        setForm({ ...DEFAULT_API_KEY })
    }

    const onRowSelect = (item: any) => {
        console.log('item', item)
    }

    const onSave = async () => {
        console.log('save', form)

        if (isLoading) {
            return;
        }

        if (!form.name) {
            setAlert('error', 'Please provide valid name')
            return;
        }

        const companyId = get(user, 'company.companyId');

        if (action === ACTIONS.EDIT) {
            setLoading(true);
            const response: CustomResponse = await PutCall(`/company/${companyId}/api-keys/${form.apiKeyId}`, form);
            setLoading(false)
            if (response.code == 'SUCCESS') {
                setAlert('sucess', 'Updated!')
                fetchData()
            }
            else {
                setAlert('error', response.message)
            }
        }
        else {
            setLoading(true);
            const response: CustomResponse = await PostCall(`/company/${companyId}/api-keys`, form);
            setLoading(false)
            if (response.code == 'SUCCESS') {
                fetchData()
                setSelectedAPIKey(response.data)
                setAction(ACTIONS.VIEW)
            }
            else {
                setAlert('error', response.message)
            }
        }
    }

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to revoke this key?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    deleteItem(item)
                }
            },
        });
    }

    const deleteItem = async (item: ApiKey) => {
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${companyId}/api-keys/${item.apiKeyId}`);
        setLoading(false)
        if (response.code == 'SUCCESS') {
            console.log(response.data);
            fetchData()
        }
        else {
            setAlert('error', response.message)
        }
    }

    const viewKey = (item: ApiKey) => {
        setSelectedAPIKey(item);
        setAction(ACTIONS.VIEW)
    }

    const copyKey = async (key: any) => {
        if (!key) {
            return;
        }
        await navigator.clipboard.writeText(key);
        setAlert('success', 'Copied!')
    }

    const downloadSecret = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet([{ 'API Key': selectedAPIKey?.apiKey, 'API Secret': selectedAPIKey?.apiSecret }]);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, selectedAPIKey?.name || 'API Keys');
        });
    }

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, `${fileName}` + EXCEL_EXTENSION);
                setAlert('success', 'Downloaded!')
            }
        });
    };

    const actionTemplate = (rowData: any, options: ColumnBodyOptions) => {
        return <div className='flex'>
            <Button type="button" icon={'pi pi-eye'} className="p-button-sm p-button-text" onClick={() => viewKey(rowData)} />
            <Button type="button" icon={'pi pi-trash'} className="p-button-sm p-button-text text-red" onClick={() => confirmDelete(rowData)} />
        </div>;
    };

    return <>
        <ConfirmDialog />
        <div className="grid">
            <div className="col-12">
                <TabView className={layoutState.isMobile ? 'w-full' : 'w-desktop'}>
                    <TabPanel header="API keys" leftIcon="pi pi-key mr-2 text-blue">
                        <div className="flex justify-content-end pt-2 pb-4">
                            <span className="p-input-icon-left flex align-items-center">
                                <Button type="button" icon={'pi pi-plus'} label="Add" className="p-button-sm" onClick={() => showAddNew()} />
                            </span>
                        </div>
                        <div className='card erp-table-container'>
                            <DataTable
                                scrollable
                                value={items}
                                selectionMode="single"
                                rows={10}
                                totalRecords={items.length}
                                dataKey="apiKeyId"
                                paginator={true}
                                className='erp-table'
                                style={{ width: '100%', height: '80%' }}>
                                <Column header="Name" field="name"></Column>
                                <Column header="API Key" field="apiKey" body={(item) => <>{item.apiKey} <IconField className="pi pi-clone" size={30} onClick={() => copyKey(item.apiKey)} /></>}></Column>
                                <Column header="Active" field="isActive" body={(item) => <Tag severity={item.isActive ? 'success' : 'danger'}>{item.isActive ? 'Active' : 'In Active'}</Tag>}></Column>
                                <Column header="Last Used" field="lastUsedAt" body={(item) => item.lastUsedAt ? moment(item.lastUsedAt).format('MM/DD/YYYY hh:mm A') : ''}></Column>
                                <Column header="Created By" field="fullName" body={(item) => `${get(item, 'user.firstName')} ${get(item, 'user.lastName') ? ' ' + get(item, 'user.lastName') : ''}`}></Column>
                                <Column header="" body={actionTemplate}></Column>
                            </DataTable>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>

        <Sidebar
            isVisible={[ACTIONS.ADD, ACTIONS.EDIT].includes(action)}
            action={action}
            title={action == ACTIONS.EDIT ? 'Update' : 'Add new'}
            width="40%"
            onSave={onSave}
            closeIcon={onClose}
            content={<>
                <div className="col-12">
                    <div className="field">
                        <label htmlFor="name3" className="w-full">
                            Name
                        </label>
                        <div className="w-full">
                            <InputText value={form.name || ''} onChange={(e) => updateItem('name', e.target.value)} placeholder="Name" className="w-full" />
                        </div>
                    </div>
                </div>
            </>}
        />

        <Sidebar
            isVisible={selectedAPIKey?.apiKey && [ACTIONS.VIEW].includes(action)}
            action={action}
            title={selectedAPIKey?.name}
            width="40%"
            onSave={onSave}
            closeIcon={onClose}
            content={<>
                <div className="col-12">
                    <div className="field">
                        <label htmlFor="name3" className="w-full font-bold">
                            API Key
                        </label>
                        <div className="w-full">
                            {selectedAPIKey?.apiKey} <IconField className="pi pi-clone cursor-pointer" size={30} onClick={() => selectedAPIKey && copyKey(selectedAPIKey.apiKey)} />
                        </div>
                    </div>
                    {
                        selectedAPIKey?.apiSecret && <>
                            <div className="field">
                                <label htmlFor="name3" className="w-full font-bold">
                                    API Secret
                                </label>
                                <div className="w-full">
                                    {selectedAPIKey?.apiSecret} <IconField className="pi pi-clone cursor-pointer" size={30} onClick={() => selectedAPIKey && copyKey(selectedAPIKey.apiSecret)} />
                                </div>
                            </div>
                            <div className="field">
                                <p className="text-blue cursor-pointer" onClick={downloadSecret}><IconField className="pi pi-download text-blue" /> Download Secret</p>
                            </div>
                        </>
                    }
                </div>
            </>}
        />
    </>
}

export default SettingPage;