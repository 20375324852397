


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { buildQueryParams, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { get } from 'lodash';
import { CustomResponse, SalesReturns, Vendor } from '../../../types';
import { DeleteCall, GetCall } from '../../../api/ApiKit';
import { isMoment } from 'moment-timezone';
import moment from 'moment';
import { ACTIONS } from '../../../utils/constant';
import { Dropdown } from 'primereact/dropdown';
import SalesReturnView from '../../../components/Views/SalesReturnView';
import SalesReturnsEditor from '../../../components/Editors/SalesReturnEditor';
import { DEFAULT_SALES_RETURNS } from '../../../types/default-forms';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { IconField } from 'primereact/iconfield';
import { Tooltip } from 'primereact/tooltip';
import DiscrepancyView from '../../../components/Views/DiscrepancyView';
import AppPage from '../../../layout/AppPage';

const SalesReturnsPage = () => {
    const initializedRef = useRef(false);
    const [searchParams] = useSearchParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [action, setAction] = useState<any>(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const dataTableRef = useRef<CustomDataTableRef>(null);
    const queryRMAId = searchParams.get('rmaId');

    const [rma, setRMA] = useState<SalesReturns | null>(null);
    const [salesReturns, setSalesReturns] = useState<SalesReturns[]>([]);
    const [allVendors, setAllVendors] = useState<Vendor[]>([]);
    const [discripancyItem, setDescripancy] = useState<any>(null);

    useEffect(() => {
        setScroll(false);
        fetchData();
        fetchAllVendors();
        return () => {
            setScroll(true);
        };
    }, []);

    useEffect(() => {
        const check = async () => {
            if (!initializedRef.current && queryRMAId) {
                initializedRef.current = true;
                let sampleSO = { ...DEFAULT_SALES_RETURNS };
                sampleSO.rmaId = +queryRMAId;
                setAction(ACTIONS.VIEW);
                handleClick(sampleSO);
            }
        };
        check();
    }, [queryRMAId]);

    const handleClick = (_salesOrder?: SalesReturns) => {
        if (_salesOrder) {
            const newUrl = `/sales-returns?rmaId=${_salesOrder.rmaId}`;
            navigate(newUrl, { replace: true });
            setRMA(_salesOrder)
        }
        else {
            navigate('/sales-returns', { replace: true });
        }
    };

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        params.include = 'customer,status';
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/sales-returns-view?${queryString}`);
        if (response.code == 'SUCCESS') {
            setSalesReturns(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setSalesReturns([]);
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchAllVendors = async () => {
        if (!user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors?filters.vendorType=isCustomer&limit=500`);
        if (response.code == 'SUCCESS') {
            setAllVendors(response.data);
        } else {
            setAllVendors([]);
        }
        setLoading(false);
    };

    const showAddNew = () => {
        setSubmitted(false);
        setAction(ACTIONS.ADD)
    };

    const confirmDelete = (_sOrder: SalesReturns) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this Sales Return?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (_sOrder) {
                    deleteSalesReturn(_sOrder)
                }
            },
        });
    }

    const deleteSalesReturn = async (_sOrder: SalesReturns) => {
        setLoading(true);
        const response: CustomResponse = await DeleteCall(`/company/${user?.company?.companyId}/sales-returns/${_sOrder.rmaId}`);
        setLoading(false);
        if (response.code === 'SUCCESS') {
            fetchData();
            setAlert('success', 'Successfully Deleted');
        } else {
            setAlert('error', response.message);
        }
    };

    const vendorDropdown = (options: any) => (
        <Dropdown
            filter={true}
            value={options.value || null}
            options={allVendors}
            optionLabel="name"
            optionValue="vendorId"
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Select customer"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }}
        />
    );

    const onRowSelect = async (perm: SalesReturns, action = ACTIONS.VIEW) => {
        setAction(action);
        setRMA(perm)
        if (action === ACTIONS.VIEW) {
            handleClick(perm)
        }
    };

    const closeIcon = () => {
        setSubmitted(false);
        setIsShowSplit(false);
        setAction('');
        setRMA({ ...DEFAULT_SALES_RETURNS })
        handleClick();
    };

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                ref={dataTableRef}
                                filter={true}
                                title='Sales Returns'
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isView={true}
                                isEdit={true} // show edit button
                                isDelete={true}
                                data={salesReturns}
                                headerButtons={[
                                    {
                                        icon: 'pi pi-plus',
                                        label: 'New',
                                        onClick: () => showAddNew()
                                    }
                                ]}
                                columns={[
                                    {
                                        header: 'RMA Date',
                                        field: 'rmaDate',
                                        sortable: true,
                                        style: { minWidth: 120, maxWidth: 120 },
                                        body: (options: any) => isMoment(moment(options.rmaDate)) ? moment(options.rmaDate).format('MM/DD/YYYY') : ''
                                    },
                                    {
                                        header: 'RMA #',
                                        field: 'rmaNumber',
                                        filter: true,
                                        sortable: true,
                                        bodyStyle: { minWidth: 150, maxWidth: 150 },
                                        filterPlaceholder: 'RMA Number',
                                        body: (item) => <div><Tooltip target={`.${item.rmaNumber}`} /><span onClick={() => onRowSelect(item, ACTIONS.VIEW)} className='text-blue cursor-pointer'>{item.rmaNumber}</span>{item.isDiscrepancy ? <IconField className={`pi pi-exclamation-triangle text-red ml-2 cursor-pointer ${item.rmaNumber}`} data-pr-tooltip="Discrepancy found" data-pr-position="right" onClick={()=> setDescripancy(item)} /> : <></>}</div>,
                                    },
                                    {
                                        header: 'Customer',
                                        field: 'vendorId',
                                        body: (options: any) => options.customerName,
                                        filter: true,
                                        filterElement: vendorDropdown,
                                        filterPlaceholder: 'Search customer'
                                    },
                                    {
                                        header: 'Status',
                                        field: 'statusId',
                                        body: (options: any) => options.status
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onView={(item: any) => onRowSelect(item, ACTIONS.VIEW)}
                                onEdit={(item: any) => onRowSelect(item, ACTIONS.EDIT)}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog appendTo={document.body} />
            {
                action === ACTIONS.VIEW && rma?.rmaId && <SalesReturnView
                    title={rma.rmaNumber}
                    rmaId={rma?.rmaId}
                    isVisible={true}
                    onClose={(isLoad: any) => {
                        if (isLoad) {
                            fetchData();
                        }
                        closeIcon()
                    }} />
            }

            {
                action === ACTIONS.EDIT && rma?.rmaId && <SalesReturnsEditor
                    isEdit={true}
                    soId={rma?.soId}
                    rmaId={rma.rmaId}
                    isVisible={true}
                    onClose={(isLoad: any) => {
                        if (isLoad) {
                            fetchData();
                        }
                        closeIcon()
                    }}
                />
            }

            {/* Sales Return */}
            {
                action === ACTIONS.ADD && <SalesReturnsEditor
                    isVisible={true}
                    onClose={(isLoad) => {
                        setAction('');
                        if (isLoad) {
                            fetchData();
                        }
                    }}
                />
            }

            {
                discripancyItem && <DiscrepancyView
                    isVisible={discripancyItem != null}
                    id={discripancyItem.rmaId}
                    type={'RMA'}
                    title={discripancyItem.rmaNumber}
                    onClose={(isLoad: boolean) => {
                        setDescripancy(null)
                    }}
                />
            }
        </AppPage>
    );
};

export default SalesReturnsPage;
