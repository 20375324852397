import { configureStore } from "@reduxjs/toolkit";
import { Category, CompanySubLocation, MasterCode, Warehouse } from "../types";
import categoryReducer from "./slices/category-slice";
import locationReducer from "./slices/location-slice";
import sublocationReducer from "./slices/sublocation-slice";
import paymentTermsReducer from "./slices/payment-terms-slice";
import gradesReducer from "./slices/grades-slice";
import poStatusReducer from "./slices/po-status.slice";
import carriersReducer from "./slices/carriers-slice";
import countryReducer from "./slices/carriers-country";
import businessReducer from "./slices/business-slice";
import industryReducer from "./slices/industry-slice";

export interface ReduxStoreState {
  categories: { categories: Category[] },
  locations: { locations: Warehouse[] },
  sublocations: { sublocations: CompanySubLocation[] },
  paymentTerms: { paymentTerms: MasterCode[] },
  grades: { grades: MasterCode[] },
  poStatus: { poStatus: MasterCode[] },
  carriers: { carriers: MasterCode[] },
  carrierCountries: { carrierCountries: MasterCode[] },
  industryTypes: { industryTypes: MasterCode[] },
  businessTypes: { businessTypes: MasterCode[] }
}

export const ReduxStore = configureStore({
  reducer: {
    categories: categoryReducer,
    locations: locationReducer,
    sublocations: sublocationReducer,
    paymentTerms: paymentTermsReducer,
    grades: gradesReducer,
    poStatus: poStatusReducer,
    carriers: carriersReducer,
    carrierCountries: carriersReducer,
    industryTypes: industryReducer,
    businessTypes: businessReducer
  },
});

// Define RootState and AppDispatch types
export type RootState = ReturnType<typeof ReduxStore.getState>;
export type AppDispatch = typeof ReduxStore.dispatch;
