


import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { Pallet, CustomResponse, Roles } from '../../../types';
import { filter, find, get, groupBy, head, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { EmptyPallet } from '../../../types/forms';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, getRowLimitWithScreenHeight } from '../../../utils/uitl';
import CustomDataTable, { CustomDataTableRef } from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptyPallet = {
    palletId: [],
    po: [
        {
            poNumber: ''
        }
    ]
};

const GradePage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);

    const [companies, setCompanies] = useState<Pallet[]>([]);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);
    const [inputValue, setInputValue] = React.useState<string>('');
    const dataTableRef = useRef<CustomDataTableRef>(null);

    useEffect(() => {
        setScroll(false);
        fetchData();
        return () => {
            setScroll(true);
        };
    }, []);
   
    useEffect(() => {
        console.log(companies, 'abhishek - companies updated');
    }, [companies]);

    const fetchData = async (params?: any) => {
        // const companyId = get(user, 'company.companyId');
        // const { sortBy = 'poId', sortOrder = 'asc', ...otherParams } = params || {};
        // const validSortOrder = ['asc', 'desc'].includes(sortOrder) ? sortOrder : 'asc';
        // const queryParams = {
        //     groupBy: 1,
        //     sortBy,
        //     sortOrder: validSortOrder,
        //     ...otherParams
        // };
        // const queryString = new URLSearchParams(queryParams).toString();
        // setLoading(true);
        // try {
        //     const response: CustomResponse = await GetCall(`/company/${companyId}/pallet-receiving?${queryString}`);
        //     if (response.code === 'SUCCESS') {
        //         setCompanies(response.data);
        //     } else {
        //         setCompanies([]);
        //     }
        // } catch (error) {
        //     setCompanies([]);
        // } finally {
        //     setLoading(false);
        // }
    };

    const onDelete = async (item: any) => {
        const palletIdString = item?.palletIds; // Extract the palletIds string from selectedCompany
        if (!palletIdString) {
            setAlert('error', 'No Pallet IDs provided');
            return;
        }
        const palletIdsArray = palletIdString.split(',').map((id: any) => parseInt(id.trim(), 10)).filter((id: any) => !isNaN(id));
        const companyId = get(user, 'company.companyId'); // Get the companyId from the user object
        setLoading(true);
        try {
            const response: CustomResponse = await PostCall(`/company/${companyId}/pallet-receiving/delete`, {
                palletIds: palletIdsArray // Payload containing palletIds
            });
            if (response.code === 'SUCCESS') {
                setAlert('success', 'Deleted successfully');
            } else {
                setAlert('error', response.message);
            }
        } catch (error) {
            setAlert('error', 'Failed to delete');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const renderHeader = (
        <div className='p-4 bg-white' style={{ width: '100%' }}>
            <p className='text-xl font-bold'>Grade</p>
            <div className="flex justify-content-between gap-3 mt-3">
                <div style={{ width: '50%' }}>
                    <label htmlFor="inputWithIcon" style={{ display: 'block', marginBottom: '0.5rem' }}>
                        LPN
                    </label>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <InputText
                            id="inputWithIcon"
                            onChange={handleInputChange}
                            value={inputValue}
                            placeholder="Enter LPN"
                            style={{ width: '100%', paddingRight: '2.5rem' }} // Reserve space for the icon
                        />
                        <i
                            className="pi pi-qrcode"
                            style={{
                                position: 'absolute',
                                right: '0.75rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                color: '#6c757d'
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '50%' }}>
                    <label htmlFor="inputWithIcon" style={{ display: 'block', marginBottom: '0.5rem' }}>
                        Graded Bin
                    </label>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <InputText
                            id="inputWithIconn"
                            value={inputValue}
                            placeholder="Enter Graded Bin"
                            style={{ width: '100%', paddingRight: '2.5rem' }} // Reserve space for the icon
                        />
                        <i
                            className="pi pi-qrcode"
                            style={{
                                position: 'absolute',
                                right: '0.75rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                fontSize: '1.5rem',
                                cursor: 'pointer',
                                color: '#6c757d'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const confirmDelete = (item: any) => {
        confirmDialog({
            className: 'confirm-dialog',
            message: `Do you really want to delete this?`,
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle text-red",
            position: 'top',
            accept: () => {
                if (item) {
                    onDelete(item)
                }
            },
        });
    }

    return (
        <AppPage>
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            {renderHeader}
                            <CustomDataTable
                                ref={dataTableRef}
                                filter
                                page={page}
                                limit={limit} // no of items per page
                                totalRecords={totalRecords} // total records from api response
                                isDelete={true} // show delete button
                                data={companies}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'id',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search Id'
                                    },
                                    {
                                        header: 'LPN',
                                        field: 'reid',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search LPN'
                                    },
                                    {
                                        header: 'Graded Bin',
                                        field: 'bin',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search Bin Number'
                                    },
                                    {
                                        header: 'Grade',
                                        field: 'grade',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search Grade'
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onDelete={(item: any) => confirmDelete(item)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AppPage>
    );
};

export default GradePage;
