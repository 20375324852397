import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { CompanyProductsMapping, CustomResponse, ObjectInquiry, Permissions, TreeNode } from '../../../types';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, keyBy, map, uniq } from 'lodash';
import { GetCall, PostCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { EmptyCategoryAttribute } from '../../../types/forms';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import Sidebar from '../../../components/Sidebar';
import { TreeTable } from 'primereact/treetable';
import { useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';


const ACTIONS = {
    ADD: 'add',
    EDIT: 'update',
    DELETE: 'delete',
    REMOVE: 'remove'
};

const defaultForm: EmptyCategoryAttribute = {
    catAttrId: undefined,
    codeTypeId: null,
    selectionType: '',
    action: '',
    isSKUEnabled: true,
    isSKURank: 0,
    sampleValue: '',
    codeType: '',
    desc: ''
};

const ObjectInquiryPage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [companies, setCompanies] = useState<any[]>([]);
    const [nodes, setNodes] = useState<any[]>([]);
    const [action, setAction] = useState<any>(null);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [barcode, setBarcode] = useState('');
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setScroll(false);
        fetchData();
        return () => {
            setScroll(true);
        };
    }, []);
    const fetchData = async () => {
        if (!barcode) {
            setCompanies([]);
            return;
        }
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${companyId}/barcode/${barcode}`);
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies([response.data]);
        } else {
            setCompanies([]);
        }
    };
    useEffect(() => {
        if (barcode) {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                fetchData();
            }, 1000);
            setDebounceTimeout(timeout);
        }
        else {
            setCompanies([]);
        }
        // Cleanup timeout on unmount or barcode change
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [barcode]);


    const [tableHeight, setTableHeight] = useState('30rem');
    const calculateTableHeight = () => {
        const headerHeight = 340;
        const availableHeight = window.innerHeight - headerHeight;
        setTableHeight(`${availableHeight}px`);
    };

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);
        return () => {
            window.removeEventListener('resize', calculateTableHeight);
        };
    }, []);

    const closeIcon = () => {
        setIsShowSplit(false);
        setAction(null);
    };

    const onSave = () => {
        if (action == ACTIONS.ADD) {
            setIsShowSplit(false);
            setAction(null);
            return;
        }
    };
    
    const processCompanies = (companies: any[], barcodeType: string) => {
        return companies
            .filter((company: { barcodeType: any; }) => company.barcodeType === barcodeType)
            .map((company: { skuId: any; racks: any[]; subLocation: { skuId: any; }; rows: any[]; rack: { subLocation: { skuId: any; }; skuId: any; bins: any[]; }; row: { skuId: any; }; binNumber: any; items: any[]; }, companyIndex: any) => {
                const generateItemNodes = (items: any[], prefix: string) =>
                    items?.map((item: any, itemIndex: any) => ({
                        key: `${prefix}-${itemIndex}`,
                        data: {
                            name: `Item: ${item.REID?.replace('REID', '').trim() || 'N/A'}`,
                            imei: item.IMEI || 'N/A',
                            skuId: get(item, 'product.skuId') || 'N/A',
                            grade: get(item, 'purchaseItem.grade.value') || 'N/A',
                            vendor: get(item, 'purchaseItem.po.vendor.name') || 'N/A',
                            poNumber: get(item, 'purchaseItem.po.poNumber') || 'N/A',
                            poDate: get(item, 'purchaseItem.po.poDate') || 'N/A',
                            cost: get(item, 'purchasePrice') ? `$${get(item, 'purchasePrice')}` : 'N/A',
                        },
                    }));
                switch (barcodeType) {
                    case 'SUB_LOCATION':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.skuId}(${company.racks?.length || 0})`,
                                racksCount: company.racks?.length || 0,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: company.racks?.map((rack: { skuId: any; rows: any[]; }, rackIndex: any) => ({
                                key: `${companyIndex}-${rackIndex}`,
                                data: {
                                    name: `Rack: ${rack.skuId}(${rack.rows?.length || 0})`,
                                    size: '150KB',
                                    type: 'Folder',
                                },
                                children: rack.rows?.map((row: { skuId: any; bins: any[]; }, rowIndex: any) => ({
                                    key: `${companyIndex}-${rackIndex}-${rowIndex}`,
                                    data: {
                                        name: `Row: ${row.skuId}(${row.bins?.length || 0})`,
                                        size: '100KB',
                                        type: 'Folder',
                                    },
                                    children: row.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                        key: `${companyIndex}-${rackIndex}-${rowIndex}-${binIndex}`,
                                        data: {
                                            name: `Bin: ${bin.binNumber}(${bin.items?.length || 0})`,
                                            size: '75KB',
                                            type: 'Folder',
                                        },
                                        // children: bin.items?.map((item: { REID: any; }, itemIndex: any) => ({
                                        //     key: `${companyIndex}-${rackIndex}-${rowIndex}-${binIndex}-${itemIndex}`,
                                        //     data: {
                                        //         name: `Item: ${item.REID}`,
                                        //         size: '50KB',
                                        //         type: 'File',
                                        //     },
                                        // })),
                                        children: generateItemNodes(bin.items, `${companyIndex}-${rackIndex}-${rowIndex}-${binIndex}`),
                                    })),
                                })),
                            })),
                        };
                    case 'RACK':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.subLocation?.skuId || 'N/A'}(${company.racks?.length || 1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.skuId}(${company.rows?.length || 0})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: company.rows?.map((row: { skuId: any; bins: any[]; }, rowIndex: any) => ({
                                        key: `${companyIndex}-rack-${rowIndex}`,
                                        data: {
                                            name: `Row: ${row.skuId}(${row.bins?.length || 0})`,
                                            size: '100KB',
                                            type: 'Folder',
                                        },
                                        children: row.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                            key: `${companyIndex}-rack-${rowIndex}-${binIndex}`,
                                            data: {
                                                name: `Bin: ${bin.binNumber}(${bin.items?.length || 0})`,
                                                size: '75KB',
                                                type: 'Folder',
                                            },
                                            // children: bin.items?.map((item: { REID: any; }, itemIndex: any) => ({
                                            //     key: `${companyIndex}-rack-${rowIndex}-${binIndex}-${itemIndex}`,
                                            //     data: {
                                            //         name: `Item: ${item.REID?.replace('REID', '').trim() || 'N/A'}`,
                                            //         size: '50KB',
                                            //         type: 'File',
                                            //     },
                                            // })),
                                            children: generateItemNodes(bin.items, `${companyIndex}-${rowIndex}-${binIndex}`),
                                        })),
                                    })),
                                },
                            ],
                        };
                    case 'RCKROW':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.rack?.subLocation?.skuId || 'N/A'}(${1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.rack?.skuId || 'N/A'}(${1})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: [
                                        {
                                            key: `${companyIndex}-rack-row`,
                                            data: {
                                                name: `Row: ${company.skuId || 'N/A'}(${company.rack?.bins?.length || 0})`,
                                                size: '100KB',
                                                type: 'Folder',
                                            },
                                            children: company.rack?.bins?.map((bin: { binNumber: any; items: any[]; }, binIndex: any) => ({
                                                key: `${companyIndex}-rack-row-${binIndex}`,
                                                data: {
                                                    name: `Bin: ${bin.binNumber || 'N/A'}(${bin.items?.length || 0})`,
                                                    size: '75KB',
                                                    type: 'Folder',
                                                },
                                                // children: bin.items?.map((item: { REID: any; }, itemIndex: any) => ({
                                                //     key: `${companyIndex}-rack-row-${binIndex}-${itemIndex}`,
                                                //     data: {
                                                //         name: `Item:${item.REID?.replace('REID', '').trim() || 'N/A'}`,
                                                //         size: '50KB',
                                                //         type: 'File',
                                                //     },
                                                // })),
                                                children: generateItemNodes(bin.items, `${companyIndex}-${binIndex}`),
                                            })),
                                        },
                                    ],
                                },
                            ],
                        };
                    case 'BIN':
                        return {
                            key: `${companyIndex}`,
                            data: {
                                name: `Sub Location: ${company.subLocation?.skuId || 'N/A'}(${1})`,
                                size: '200KB',
                                type: 'Folder',
                            },
                            children: [
                                {
                                    key: `${companyIndex}-rack`,
                                    data: {
                                        name: `Rack: ${company.rack?.skuId || 'N/A'}(${1})`,
                                        size: '150KB',
                                        type: 'Folder',
                                    },
                                    children: [
                                        {
                                            key: `${companyIndex}-rack-row`,
                                            data: {
                                                name: `Row: ${company.row?.skuId || 'N/A'}(${1})`,
                                                size: '100KB',
                                                type: 'Folder',
                                            },
                                            children: [
                                                {
                                                    key: `${companyIndex}-rack-row-bin`,
                                                    data: {
                                                        name: `Bin: ${company.binNumber || 'N/A'}(${company.items?.length || 0})`,
                                                        size: '75KB',
                                                        type: 'Folder',
                                                    },
                                                    // children: company.items?.map((item: { REID: any; }, itemIndex: any) => ({
                                                    //     key: `${companyIndex}-rack-row-bin-${itemIndex}`,
                                                    //     data: {
                                                    //         name: `Item: ${item.REID?.replace('REID', '').trim() || 'N/A'}`,
                                                    //         size: '50KB',
                                                    //         type: 'File',
                                                    //     },
                                                    // })),
                                                    children: generateItemNodes(company.items || [], `${companyIndex}-bin`),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        };
                    default:
                        return null;
                }
            });
    };

    useEffect(() => {
        if (companies.length > 0) {
            const barcodeTypes = ['SUB_LOCATION', 'RACK', 'RCKROW', 'BIN'];
            const treeNodes = barcodeTypes.flatMap((type) => processCompanies(companies, type));
            setNodes(treeNodes);
        }
        else {
            setNodes([]);
        }
    }, [companies]);

    const panelFooterTemplate = () => {

        return (
            <div className="flex justify-content-end p-2">
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD].includes(action) && <Button label="Save" onClick={onSave} />}
                </div>
            </div>
        );
    };
    const palletHeader = (<div style={{ width: '100%' }}>
        <div className="p-4">
            <p className='text-xl font-bold'>Object Inquiry</p>
            <div>
                <label htmlFor="inputWithIcon" style={{ display: 'block', marginBottom: '0.5rem' }}>
                    Barcode
                </label>
                <div className='w-4' style={{ position: 'relative' }}>
                    <InputText
                        id="inputWithIcon"
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                        style={{ width: '100%', paddingRight: '2.5rem' }} // Reserve space for the icon
                    />
                    <i
                        className="pi pi-qrcode"
                        style={{
                            position: 'absolute',
                            right: '0.75rem',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: '#6c757d',
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
    );

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <div className="card erp-table-container">
                                {palletHeader}
                                {nodes.length > 0 ? (
                                    <TreeTable
                                        scrollable
                                        value={nodes as TreeNode[]}
                                        selectionMode="single"
                                        rows={10}
                                        expandedKeys={expandedKeys}
                                        onToggle={(e) => setExpandedKeys(e.value)} // Update expandedKeys dynamically
                                        className="erp-table"
                                        paginator={true}
                                        scrollHeight={tableHeight}
                                        style={{ width: '100%' }}
                                    >
                                        <Column field="name" header="LPN" expander style={{ width: 270 }}></Column>
                                        <Column field="imei" header="IMEI"></Column>
                                        <Column field="skuId" header="SKU"></Column>
                                        <Column field="grade" header="Grade"></Column>
                                        <Column field="vendor" header="Vendor"></Column>
                                        <Column field="poNumber" header="PO Number"></Column>
                                        <Column field="poDate" header="PO Date"></Column>
                                        <Column field="cost" header="Cost"></Column>
                                    </TreeTable>

                                ) : (
                                    barcode.length > 0 && !isLoading ? <div style={{ textAlign: 'center', padding: '1rem' }}>
                                        No data available. Please enter a valid barcode.
                                    </div> : <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppPage>
    );
};
export default ObjectInquiryPage;