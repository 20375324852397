import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterCode } from "../../types";

interface SliceState {
    carrierCountries: MasterCode[];
}

const initialState: SliceState = {
    carrierCountries: [],
};

const slice = createSlice({
    name: "carrierCountries",
    initialState,
    reducers: {
        setCarrierCountries: (state, action: PayloadAction<MasterCode[]>) => {
            state.carrierCountries = action.payload;
        }
    },
});

export const { setCarrierCountries } = slice.actions;
export default slice.reducer;
