


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppContext } from '../../../layout/AppWrapper';
import { LayoutContext } from '../../../layout/context/layoutcontext';
import { MultiSelect } from 'primereact/multiselect';
import { MasterCode, CustomResponse, Permissions } from '../../../types';
import { Panel } from 'primereact/panel';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filter, find, get, groupBy, keyBy, map, uniq } from 'lodash';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { DeleteCall, GetCall, PostCall, PutCall } from '../../../api/ApiKit';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { InputTextarea } from 'primereact/inputtextarea';
import { EmptyWarehouse } from '../../../types/forms';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Tree, TreeCheckboxSelectionKeys } from 'primereact/tree';
import { buildQueryParams, formatString, getRowLimitWithScreenHeight, validateName, validatePhoneNumber, validateSubdomain, validateZipNumber } from '../../../utils/uitl';
import { COMPANIES_MENU, COMPANY_MENU, CompanyModule, CompanyWrite, DashboardModule } from '../../../config/permissions';
import { InputSwitch } from 'primereact/inputswitch';
import DefaultLogo from '../../../components/DefaultLogo';
import RightSidePanel from '../../../components/RightSidePanel';
import { TreeTable } from 'primereact/treetable';
import { TreeNode } from 'primereact/treenode';
import { Dropdown } from 'primereact/dropdown';
import CustomDataTable from '../../../components/CustomDataTable';
import { useNavigate, useParams } from 'react-router-dom';
import AppPage from '../../../layout/AppPage';

const ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view',
    DELETE: 'delete',
    VIEW_PERMISSIONS: 'view_permissions'
};

const defaultForm: EmptyWarehouse = {
    locationId: null,
    companyId: null,
    name: '',
    location: '',
    phone: '',
    zip: '',
    isActive: true
};

const MasterPage = () => {
    const { companyId } = useParams();
    const { user, isLoading, setLoading, setScroll, setAlert, setSelectedSubLocation } = useAppContext();
    const { layoutState } = useContext(LayoutContext);
    const navigate = useNavigate();
    const multiSelectRef = useRef<MultiSelect>(null);
    const [isShowSplit, setIsShowSplit] = useState<boolean>(false);
    const [companies, setCompanies] = useState<MasterCode[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<MasterCode | null>(null);
    const [isDetailLoading, setIsDetailLoading] = useState<boolean>(false);
    const [action, setAction] = useState<any>(null);
    const [form, setForm] = useState<EmptyWarehouse>(defaultForm);
    const [confirmTextValue, setConfirmValue] = useState<any>('');

    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(getRowLimitWithScreenHeight());
    const [totalRecords, setTotalRecords] = useState<number | undefined>(undefined);

    useEffect(() => {
        setScroll(false);
        fetchData();

        return () => {
            setScroll(true);
        };
    }, []);

    const fetchData = async (params?: any) => {
        if (!params) {
            params = { limit: limit, page: page };
        }
        setPage(params.page);
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/master-code-types?${queryString}`); // get all the roles
        setLoading(false);
        if (response.code == 'SUCCESS') {
            setCompanies(response.data);
            if (response.total) {
                setTotalRecords(response?.total);
            }
        } else {
            setCompanies([]);
        }
    };

    const closeIcon = () => {
        setSelectedCompany(null);
        setIsShowSplit(false);
        setForm(defaultForm);
        setAction(null);
    };

    const onSave = () => {};

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(companies);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'MasterCode');
        });
    };
    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const handleRowClick = (company: MasterCode) => {
        setSelectedSubLocation(company.codeType); // Store the selected company in context
        const codeTypeId = company.codeTypeId;
        if (codeTypeId) {
            navigate(`/master-codes/make?codeTypeId=${codeTypeId}`);
        } else {
            console.error('codeTypeId is undefined');
        }
    };

    const onInputChange = (name: string, val: any) => {
        const regex = /^[a-zA-Z]*$/;
        let _form: any = { ...form };
        _form[`${name}`] = val;
        setForm(_form);
    };
    const onValueChange = (e: any) => setConfirmValue(e.target.value);
    const headerTemplate = (options: any) => {
        const className = `${options.className} justify-content-space-between`;
        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <div className="ellipsis-container font-bold" style={{ marginLeft: 10, maxWidth: '22vw' }}>
                        {action == ACTIONS.ADD ? 'Add Master Code' : selectedCompany?.name}
                    </div>
                </div>
            </div>
        );
    };
    const panelFooterTemplate = () => {
        return (
            <div className="flex justify-content-end p-2">
                <div>
                    <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                    {[ACTIONS.EDIT, ACTIONS.ADD, ACTIONS.VIEW_PERMISSIONS].includes(action) && <Button label="Save" disabled={isLoading || isDetailLoading} onClick={onSave} />}
                </div>
            </div>
        );
    };

    return (
        <AppPage>
            <div className="grid">
                <div className="col-12">
                    <div className={`panel-container ${isShowSplit ? (layoutState.isMobile ? 'mobile-split' : 'split') : ''}`}>
                        <div className="left-panel">
                            <CustomDataTable
                                title='Master Codes'
                                page={page}
                                limit={limit}
                                data={companies}
                                totalRecords={totalRecords}
                                isView={true}
                                filter
                                headerButtons={[
                                    {
                                        icon: 'pi pi-file-excel',
                                        onClick: () => exportExcel()
                                    }
                                ]}
                                columns={[
                                    {
                                        header: '#',
                                        field: 'codeTypeId',
                                        filter: true,
                                        sortable: true,
                                        filterPlaceholder: 'Search #',
                                        bodyStyle: { width: 100 }
                                    },
                                    {
                                        header: 'Name',
                                        field: 'codeType',
                                        filter: true,
                                        filterPlaceholder: 'Search name'
                                    },
                                    {
                                        header: 'Description',
                                        field: 'desc'
                                    }
                                ]}
                                onLoad={(params: any) => fetchData(params)}
                                onView={(item: any) => handleRowClick(item)}
                            />
                        </div>
                        <RightSidePanel
                            isVisible={isShowSplit}
                            headerTemplate={headerTemplate}
                            footerTemplate={panelFooterTemplate}
                            closeIcon={closeIcon}
                            content={
                                <>
                                    {isDetailLoading && (
                                        <div className="center-pos">
                                            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                        </div>
                                    )}

                                    {/* Edit Permissions */}
                                    {(action == ACTIONS.ADD || action == ACTIONS.EDIT) && (
                                        <div className="p-fluid">
                                            <div className="field">
                                                <label htmlFor="name">
                                                    Name <span className="red">*</span>
                                                </label>
                                                <InputText id="name" value={get(form, 'name')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('name', e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="location">
                                                    {' '}
                                                    Location <span className="red">*</span>
                                                </label>
                                                <InputText id="location" value={get(form, 'location')} validateOnly pattern="[a-zA-Z]*" onChange={(e) => onInputChange('location', e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="phone">
                                                    {' '}
                                                    phone <span className="red">*</span>
                                                </label>
                                                <InputText id="phone" value={get(form, 'phone')} onChange={(e) => onInputChange('phone', e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <label htmlFor="zip">
                                                    {' '}
                                                    Zip code <span className="red">*</span>
                                                </label>
                                                <InputText id="zip" value={get(form, 'zip')} onChange={(e) => onInputChange('zip', e.target.value)} />
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
            <Dialog
                header="Delete confirmation"
                visible={action == 'delete'}
                style={{ width: layoutState.isMobile ? '90vw' : '50vw' }}
                className="delete-dialog"
                headerStyle={{ backgroundColor: '#ffdddb', color: '#8c1d18' }}
                footer={
                    <div className="flex justify-content-end p-2">
                        <Button label="Cancel" severity="secondary" text onClick={closeIcon} />
                        <Button label="Save" severity="danger" disabled={selectedCompany?.name != confirmTextValue || confirmTextValue == '' || isLoading} onClick={onSave} />
                    </div>
                }
                onHide={closeIcon}
            >
                {isLoading && (
                    <div className="center-pos">
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                    </div>
                )}
                <div className="flex flex-column w-full surface-border p-3">
                    <div className="flex align-items-center">
                        <i className="pi pi-info-circle text-6xl red" style={{ marginRight: 10 }}></i>
                        <span>
                            This will remove <strong>{selectedCompany?.name}</strong>.<br /> Do you still want to remove it? This action cannot be undone.
                        </span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span>
                            Confirm you want to delete this by typing its name: <strong>{selectedCompany?.name}</strong>
                        </span>
                        <br />
                        <InputText placeholder={selectedCompany?.name} style={{ marginTop: 10 }} onChange={onValueChange} />
                    </div>
                </div>
            </Dialog>
        </AppPage>
    );
};
export default MasterPage;