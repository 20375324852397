import React from 'react';
import { PrimeReactProvider } from 'primereact/api';
import { AppWrapper } from './layout/AppWrapper';
import { LayoutProvider } from './layout/context/layoutcontext';
import AppNavigator from './AppNavigator';
import { Provider } from 'react-redux';
import { ReduxStore } from './redux/redux-store';

function App({ children }: any) {
  return (
    <PrimeReactProvider>
      <Provider store={ReduxStore}>
        <AppWrapper>
          <LayoutProvider>
            <AppNavigator />
          </LayoutProvider>
        </AppWrapper>
      </Provider>
    </PrimeReactProvider>
  );
}

export default App;
