import { useCallback, useEffect, useRef, useState } from "react";
import { useAppContext } from "../../layout/AppWrapper";
import { Category, CustomResponse, MasterCode, Product, PurchaseItem, PurchaseOrder, Vendor, Warehouse } from "../../types";
import Sidebar from "../Sidebar";
import { filter, find, get, map, set, sumBy, uniq } from "lodash";
import { isMoment } from "moment-timezone";
import moment from "moment";
import { GetCall, PostCall, PutCall } from "../../api/ApiKit";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { ACTIONS, constant } from "../../utils/constant";
import { MultiSelect } from "primereact/multiselect";
import CustomPanel from "../CustomPanel";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/redux-store";
import { buildQueryParams } from "../../utils/uitl";

interface OptionProps {
    title?: string,
    poId?: any,
    isEdit?: boolean,
    isVisible: boolean,
    items?: PurchaseItem[],
    action?: any,
    onClose: (isLoad: boolean) => void
}

const defaultLineItem: PurchaseItem = {
    poId: null,
    poItemId: null,
    companyId: null,
    categoryId: null,
    productId: null,
    partId: null,
    gradeId: null,
    isCrossDock: false,
    quantity: 0,
    price: null,
    rowId: `${Date.now()}_${Math.random()}`,
    skuId: null,
    internalGrade: null,
    batteryHealthIds: null
};
const defaultPurchaseOrder: PurchaseOrder = {
    poId: null,
    poNumber: '',
    companyId: null,
    locationId: null,
    vendorId: null,
    categoryIds: null,
    poDate: null,
    approxDeliveryDate: null,
    trackingTypeId: null,
    trackingId: null,
    statusId: null,
    note: null,
    quantity: 0,
    totalPrice: 0,
    paid: 0,
    items: []
}


export default function PurchaseOrderEditor({ title = 'Create Purchase Order', poId = null, isVisible = false, isEdit = false, action = '', onClose = (isLoad) => { } }: OptionProps) {
    const { user, isLoading, setLoading, setScroll, setAlert } = useAppContext();
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const containerRef = useRef(null);
    const [isSubmitted, setSubmitted] = useState<boolean>(false);
    const [pOrder, setPurchaseOrder] = useState<PurchaseOrder>({ ...defaultPurchaseOrder });
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const statuses = useSelector((state: ReduxStoreState) => state.poStatus.poStatus);
    const trackings = useSelector((state: ReduxStoreState) => state.carriers.carriers);
    const grades = useSelector((state: ReduxStoreState) => state.grades.grades);
    const warehouses = useSelector((state: ReduxStoreState) => state.locations.locations);

    useEffect(() => {
        if (isVisible) {
            setDialogVisible(true);
        }
        else {
            setDialogVisible(false)
        }
    }, [isVisible]);

    useEffect(() => {
        if (pOrder.locationId) {
            fetchVendors();
        } else {
            setVendors([]);
        }
    }, [pOrder.locationId]);

    useEffect(() => {
        if (pOrder.vendorId) {
            fetchProducts();
        } else {
            setProducts([]);
        }
    }, [pOrder.vendorId]);

    // useEffect(() => {
    //     if (pOrder.categoryIds && pOrder.categoryIds.length > 0) {
    //         fetchProducts();
    //     } else {
    //         setProducts([]);
    //     }
    // }, [JSON.stringify(pOrder.categoryIds)]);

    useEffect(() => {
        if (isVisible && poId) {
            fetchPODetails(poId)
        }
        else {
            setPurchaseOrder({ ...defaultPurchaseOrder })
        }
    }, [poId]);

    const fetchPODetails = async (poId: any) => {
        let params: any = {
            filters: {
                poId: poId
            },
            include: 'location,vendor,items'
        };
        const companyId = get(user, 'company.companyId');
        setLoading(true);
        const queryString = buildQueryParams(params);
        const response: CustomResponse = await GetCall(`/company/${companyId}/purchase-orders?${queryString}`);
        if (response.code == 'SUCCESS') {
            let rData = response.data[0];
            if (rData && rData.categoryIds && typeof rData.categoryIds == 'string') {
                rData.categoryIds = uniq(rData.categoryIds.split(',')).map((item: any) => parseInt(item))
            }
            setPurchaseOrder(rData);
        } else {
            setAlert('error', response.message);
        }
        setLoading(false);
    };

    const fetchVendors = async () => {
        console.log('fetch vendors')
        if (!pOrder.locationId || !user?.company?.companyId) {
            return;
        }
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors?limit=500&filters.locationId=${pOrder.locationId}`);
        if (response.code == 'SUCCESS') {
            setVendors(response.data);
        } else {
            setVendors([]);
        }
        setLoading(false);
    };

    const fetchCategories = async () => {
        if (!pOrder.vendorId || !user?.company?.companyId) return;
        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/vendors/${pOrder.vendorId}?query=vendorCategories`);
        if (response.code === 'SUCCESS') {
            const _categories = response.data.vendorCategories.map((item: any) => item.category);
            setCategories(_categories);
        } else {
            setCategories([]);
        }
        setLoading(false);
    };

    const fetchProducts = async () => {
        if (!user?.company?.companyId) {
            return;
        }

        setLoading(true);
        const response: CustomResponse = await GetCall(`/company/${user?.company?.companyId}/products?vendorId=${pOrder.vendorId}`);
        if (response.code == 'SUCCESS') {
            setProducts(response.data);
        } else {
            setProducts([]);
        }
        setLoading(false);
    };

    const closeIcon = (isLoad = false) => {
        setDialogVisible(false)
        setPurchaseOrder({ ...defaultPurchaseOrder })
        onClose(isLoad);
    }

    const onSave = async () => {
        if (isLoading) {
            return;
        }

        setSubmitted(true);
        if (!pOrder.locationId || !pOrder.vendorId || !pOrder.poDate || !isMoment(moment(pOrder.poDate))) {
            setAlert('error', 'Please provide all mandaotry fields (marked with red asterisk *)');
            return;
        }

        const companyId = get(user, 'company.companyId');
        const data: any = { ...pOrder };
        if (isEdit) {
            setLoading(true);
            data.categoryIds = map(data.items, 'categoryId');
            const response: CustomResponse = await PutCall(`/company/${companyId}/purchase-orders/${poId}`, data);
            if (response.code == 'SUCCESS') {
                setAlert('success', 'Order updated successfully');
                closeIcon(true);
            } else {
                setAlert('error', response.message);
            }
            setSubmitted(false);
            setLoading(false);
        }
        else {
            const defaultStatus = statuses.find((status) => status.code === 'Balance To Pay');
            if (!data.statusId && defaultStatus?.masterCodeId !== undefined) {
                data.statusId = defaultStatus.masterCodeId;
            }

            setLoading(true);
            const response: CustomResponse = await PostCall(`/company/${companyId}/purchase-orders`, data);
            if (response.code == 'SUCCESS') {
                setAlert('success', 'Order placed successfully');
                closeIcon(true)
            } else {
                setAlert('error', response.message)
            }
            setSubmitted(false);
            setLoading(false);
        }

    }

    const updateItem = async (key: string, value: any) => {
        const _pOrder: PurchaseOrder = JSON.parse(JSON.stringify(pOrder));
        set(_pOrder, key, value);
        setPurchaseOrder(_pOrder);
    }

    const removeItem = (productId: number | null) => {
        if (!productId) return;
        let _items = [...pOrder.items];
        _items = _items.filter((item) => item.productId != productId);
        updateItem('items', _items);
    };

    const productOptionTemplate = (option: Product) => {
        return (
            <div className="flex align-items-start flex-column">
                <div className="text-xs text-grey">{option?.type}</div>
                <div className="text-base">{option?.name}</div>
            </div>
        );
    };

    const renderGrades = (item: PurchaseItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <Dropdown
                value={item?.gradeId}
                onChange={(e) => updateItem(`items.${option.rowIndex}.gradeId`, e.value)}
                options={grades}
                optionLabel="code"
                optionValue="masterCodeId"
                placeholder="Select a Grade"
                className="w-full"
            />
        );
    };

    const renderCrossDock = (item: PurchaseItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div className="flex justify-content-center mr-6">
                <Checkbox
                    checked={item.isCrossDock || false}
                    onChange={(e) => updateItem(`items.${option.rowIndex}.isCrossDock`, e.checked)}
                />
            </div>
        );
    };

    const renderQuantity = (item: PurchaseItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div>
                <InputNumber
                    value={item.quantity}
                    onChange={(e) => updateItem(`items.${option.rowIndex}.quantity`, e.value)}
                    inputClassName="text-base"
                    inputStyle={{ width: '80px' }}
                />
            </div>
        );
    };


    const renderRate = (item: PurchaseItem, option: ColumnBodyOptions) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <InputNumber
                value={item.price}
                onChange={(e) => updateItem(`items.${option.rowIndex}.price`, e.value)}
                mode="currency"
                currency="USD"
                locale="en-US"
                placeholder="Price"
                inputClassName="text-base"
                inputStyle={{ width: '150px' }}
            />
        );
    };


    const renderTotal = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $4,000.00)
        }).format((item.price || 0) * (item.quantity || 0));
        return <div className="flex align-items-center">{formattedPrice}</div>;
    };


    const renderCross = (item: PurchaseItem) => {
        if (!item?.productId) {
            return <></>;
        }
        return (
            <div className="flex align-items-center">
                <Button
                    icon="pi pi-times"
                    severity="danger"
                    aria-label="Cancel"
                    size="small"
                    onClick={() => removeItem(item.productId)}
                />
            </div>
        );
    };

    const onProductSelect = (product: Product) => {
        const isPresent = find(pOrder.items, { productId: product.productId })
        if (!isPresent) {
            let _items = [...pOrder.items];
            _items.push({
                poId: null,
                poItemId: null,
                companyId: null,
                productId: product.productId,
                product: product,
                gradeId: null,
                isCrossDock: false,
                quantity: 0,
                price: 0,
                skuId: null,
                internalGrade: null,
                batteryHealthIds: null,
            });
            updateItem('items', _items);
        }
    };

    const selectedProductTemplate = (option: Product, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-start">
                    <div className="text-base">{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const renderProduct = (item: PurchaseItem | null, option: ColumnBodyOptions) => {
        if (item?.productId) {
            return <>
                <small>{get(item, 'product.type')}</small>
                <p>{get(item, 'product.name')}</p>
            </>
        }
        return (
            <Dropdown
                value={item?.productId}
                onChange={(e) => onProductSelect(e.value)}
                valueTemplate={selectedProductTemplate}
                itemTemplate={productOptionTemplate}
                options={products}
                optionLabel="name"
                placeholder="Select a Product"
                className="w-full"
            />
        );
    };

    return <>
        <Sidebar
            isVisible={dialogVisible}
            action={'add'}
            width={'70vw'}
            title={poId ? pOrder?.poNumber : title}
            closeIcon={() => closeIcon()}
            onSave={onSave}
            content={<>
                <div className="grid" ref={containerRef}>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Location<span className='text-red'>*</span>
                        </label>
                        <Dropdown
                            value={pOrder.locationId}
                            filter
                            onChange={(e) => updateItem('locationId', e.value)}
                            options={warehouses}
                            optionLabel="name"
                            optionValue="locationId"
                            placeholder="Select a Location"
                            className={`w-full ${isSubmitted && !pOrder.locationId ? 'p-invalid' : ''}`}
                            required={true}
                        />
                    </div>

                    <div className="col-4">
                        <label className="mb-2 block">
                            Vendor<span className='text-red'>*</span>
                        </label>
                        <Dropdown
                            value={pOrder.vendorId}
                            filter
                            onChange={(e) => updateItem('vendorId', e.value)}
                            options={vendors}
                            optionLabel="name"
                            optionValue="vendorId"
                            placeholder="Select a Vendor"
                            className={`w-full ${isSubmitted && !pOrder.vendorId ? 'p-invalid' : ''}`}
                            required={true}
                        />
                    </div>

                    <div className="col-4">
                        <label className="mb-2 block">
                            PO Date<span className='text-red'>*</span>
                        </label>
                        <Calendar appendTo={'self'} value={pOrder.poDate ? new Date(pOrder.poDate) : null} onChange={(e) => updateItem('poDate', moment(e.value).toDate())} placeholder="PO Date" className="w-full" showIcon required={true} />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Delivery Date
                        </label>
                        <Calendar appendTo={'self'} value={pOrder.approxDeliveryDate ? new Date(pOrder.approxDeliveryDate) : null} onChange={(e) => updateItem('approxDeliveryDate', moment(e.value).toDate())} placeholder="PO Delivery Date" className="w-full" showIcon />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Shipment carrier
                        </label>
                        <Dropdown value={pOrder.trackingTypeId} onChange={(e) => updateItem('trackingTypeId', e.value)} options={trackings} optionLabel="code" optionValue="masterCodeId" placeholder="Shipment carrier" className="w-full" />
                    </div>
                    <div className="col-4">
                        <label className="mb-2 block">
                            Primary Tracking Number
                        </label>
                        <InputText value={pOrder.trackingId} onChange={(e) => updateItem('trackingId', e.target.value)} placeholder="Tracking Number" className="w-full" />
                    </div>
                    {isEdit && (
                        <div className="col-4">
                            <label className="mb-2 block">
                                Status
                            </label>
                            <Dropdown
                                value={pOrder.statusId}
                                options={statuses}
                                onChange={(e) => updateItem('statusId', e.value)}
                                optionLabel="code"
                                optionValue="masterCodeId"
                                placeholder="Status"
                                className={`w-full`}
                            />
                        </div>
                    )}
                </div>
                <div className="mt-4">
                    <h5>Line Items</h5>
                    {(!pOrder.vendorId) && <div className="placeholder h-8rem pointer-none flex align-items-center justify-content-center">Line items will be displayed here</div>}
                    {pOrder.vendorId && <DataTable
                        scrollable
                        value={[...pOrder.items, defaultLineItem]}
                        selectionMode="single"
                        dataKey="productId"
                        scrollHeight="70%"
                        style={{ width: '100%', height: '80%' }}
                    >
                        <Column field="poDate" header="Product & SKU" body={renderProduct} style={{ maxWidth: 100 }} />
                        <Column field="poDate" header="Vendor Grades" body={renderGrades} style={{ maxWidth: 100 }} />
                        <Column field="poDate" header="Cross Dock" body={renderCrossDock} style={{ maxWidth: 70 }} />
                        <Column field="poDate" header="Quantity" body={renderQuantity} style={{ maxWidth: 50 }} />
                        <Column field="poNumber" header="Rate" body={renderRate} style={{ maxWidth: 100 }} />
                        <Column field="poNumber" header="Total" body={renderTotal} style={{ maxWidth: 50 }} />
                        <Column style={{ width: 30 }} body={renderCross} />
                    </DataTable>}
                    <div className="grid mt-3">
                        <div className="col-9"></div>
                        <div className="col-3">
                            <div className="flex justify-content-between">
                                <p className="font-semibold">Total</p>
                                <p className="font-bold"> {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                }).format(sumBy(pOrder.items, (item) => item.quantity * item.price))}
                                </p>
                            </div>
                            <div className="flex justify-content-between">
                                <p className="font-bold">Paid</p>
                                <InputNumber
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    value={pOrder.paid}
                                    onValueChange={(e) => {
                                        if (e.value) {
                                            updateItem('paid', e.value);
                                        } else {
                                            updateItem('paid', 0);
                                        }
                                    }}
                                    inputClassName="text-base font-bold"
                                    inputStyle={{ width: '130px', textAlign: 'end' }}
                                />
                            </div>
                            <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                            <div className="flex justify-content-between">
                                <p className="font-semibold">Balance</p>
                                <p className="font-bold">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, // Optional: set to 2 if you want cents (e.g., $2,000.00)
                                    }).format(sumBy(pOrder.items, (item) => item.quantity * item.price) - pOrder.paid)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid mt-3">
                        <h5>Remarks</h5>
                        <InputTextarea className="w-full" value={pOrder.note || ''} onChange={(e) => updateItem('note', e.target.value)} rows={5} cols={30} style={{ resize: 'none' }} />
                    </div>
                </div>
            </>}
        />
    </>
}